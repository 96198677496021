import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from './ThemeContext';

export default function ThemeWrapper(props) {
  const [theme, setTheme] = useState(false);

  function changeTheme(theme) {
    setTheme(theme);
    localStorage.setItem('isDarkMode', JSON.stringify(theme));
  }

  useEffect(() => {
    const currentTheme = JSON.parse(localStorage.getItem('isDarkMode'));
    if (currentTheme) setTheme(currentTheme);

    switch (theme) {
      case true:
      default:
        document.body.classList.add(themes.dark);
        document.body.classList.remove(themes.light);
        break;
      case false:
        document.body.classList.add(themes.light);
        document.body.classList.remove(themes.dark);
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{ currentTheme: theme, changeTheme: changeTheme }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}

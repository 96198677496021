import axios from 'axios';

//URL
const API_URL = axios.create({
	baseURL: 'https://28u546ort6.execute-api.us-east-1.amazonaws.com/Prod/',
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
});
// URL EVENTS
const AUTH_EVENTS = {
	REGISTER: '/signup',
	REGISTER_CONFIRM: '/confirm',
	LOGIN: '/user/signin',
	PASSWORD_RESET: '/user/forgotpassword',
	PASSWORD_RESET_2: '/user/confirm/forgotpassword',
	REGISTER_CONFIRM_2: '/user/confirm/signup',
	CHANGE_PASSWORD: '/user/changepassword',
	GET_USER: '/getuser'
};

//CHANGE PASSWORD
const changePassword = (data) => {
	return API_URL.post(AUTH_EVENTS.CHANGE_PASSWORD, data);
};

// REGISTER API
const registerUser = (data) => {
	return API_URL.post(AUTH_EVENTS.REGISTER, data);
};
// LOGIN API
const login = (data) => {
	return API_URL.post(AUTH_EVENTS.LOGIN, data);
};
// CONFIRM REGISTRATION API
const confirmRegister = (data) => {
	return API_URL.post(AUTH_EVENTS.REGISTER_CONFIRM, data);
};
// RESEND CONFIRMATION CODE API
const confirmResend = (data) => {
	return API_URL.post(AUTH_EVENTS.REGISTER_CONFIRM_2, data);
};
// RESET PASSWORD
// const resetPassword = (data) => {
// 	return API_URL.post(AUTH_EVENTS.PASSWORD_RESET, data);
// };
// // NEW PASSWORD
// const newPassword = (data) => {
// 	return API_URL.post(AUTH_EVENTS.REGISTER_CONFIRMPASSWORD_RESET_2, data);
// };
//GET USER
const getUserDetails = (data) => {
	return API_URL.post(AUTH_EVENTS.GET_USER, data);
};
const logoutUser = () => {
	return localStorage.removeItem('access_token');
};

const ReferralDetails = async (data) => {
	await axios.post("https://pwvzjo2to9.execute-api.eu-west-1.amazonaws.com/Prod/referalcode", data)
}

export { registerUser, login, confirmRegister, confirmResend, getUserDetails, logoutUser, changePassword, ReferralDetails };

const questions = [
	{
		id: 1,
		questionText: `What's the git command that downloads your repository from GitHub to your computer?`,
		answerOptions: [
			{
				answerText: 'git push',
				isCorrect: false
			},
			{
				answerText: 'git fork',
				isCorrect: false
			},
			{
				answerText: 'git clone',
				isCorrect: true
			},
			{
				answerText: 'git commit',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText: 'How do you create a copy of a lab under your own GitHub account so that you can solve the lab?',
		answerOptions: [
			{
				answerText: 'Forking it via the GitHub interface.',
				isCorrect: true
			},
			{
				answerText: 'git fork',
				isCorrect: false
			},
			{
				answerText: 'git clone',
				isCorrect: false
			},
			{
				answerText: 'git pull-request',
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: `What's the opposite of git clone, instead of downloading your code from GitHub, uploads your changes and code back to GitHub?`,
		answerOptions: [
			{
				answerText: 'git push',
				isCorrect: true
			},
			{
				answerText: 'git add',
				isCorrect: false
			},
			{
				answerText: 'git upload',
				isCorrect: false
			},
			{
				answerText: 'git status',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText: 'How do you check the state of your local git repository since your last commit?',
		answerOptions: [
			{
				answerText: 'git check',
				isCorrect: false
			},
			{
				answerText: 'git status',
				isCorrect: true
			},
			{
				answerText: 'git add',
				isCorrect: false
			},
			{
				answerText: 'git reset',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText: 'How do you stage files for a commit?',
		answerOptions: [
			{
				answerText: 'git stage',
				isCorrect: false
			},
			{
				answerText: 'git commit',
				isCorrect: false
			},
			{
				answerText: 'git add',
				isCorrect: true
			},
			{
				answerText: 'git reset',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText: 'How do you save the current state of your code into the git version control?',
		answerOptions: [
			{
				answerText: 'By committing the staged changes with git commit',
				isCorrect: true
			},
			{
				answerText: 'By adding all changes and staging them with git stage',
				isCorrect: false
			},
			{
				answerText: 'By adding all changes and staging them with git add',
				isCorrect: false
			},
			{
				answerText: 'By creating a new commit with git init',
				isCorrect: false
			}
		]
	},
	{
		id: 7,
		questionText: `What's a shortcut to staging all the changes you have?`,
		answerOptions: [
			{
				answerText: 'git commit add',
				isCorrect: false
			},
			{
				answerText: 'git commit',
				isCorrect: false
			},
			{
				answerText: 'git add .',
				isCorrect: true
			},
			{
				answerText: `git push -am "Message"`,
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText: 'How do you supply a commit message to a commit?',
		answerOptions: [
			{
				answerText: `git message "I'm coding"`,
				isCorrect: false
			},
			{
				answerText: `git add "I'm coding"`,
				isCorrect: false
			},
			{
				answerText: `git commit "I'm coding"`,
				isCorrect: false
			},
			{
				answerText: `git commit -m "I'm coding"`,
				isCorrect: true
			}
		]
	},
	{
		id: 9,
		questionText: 'What is the correct commit syntax for all changes with a message?',
		answerOptions: [
			{
				answerText: `git message -am "I'm coding"`,
				isCorrect: false
			},
			{
				answerText: `git add -a "I'm coding"`,
				isCorrect: false
			},
			{
				answerText: `git commit -a "I'm coding"`,
				isCorrect: false
			},
			{
				answerText: `git commit -am "I'm coding"`,
				isCorrect: true
			}
		]
	},
	{
		id: 10,
		questionText: 'How do you submit a solution to Learn?',
		answerOptions: [
			{
				answerText: 'git submit',
				isCorrect: false
			},
			{
				answerText: 'git pull-request',
				isCorrect: false
			},
			{
				answerText: 'Learn submit',
				isCorrect: true
			},
			{
				answerText: 'git commit -am "Done with Lab"',
				isCorrect: false
			}
		]
	}
];

export default questions;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './CourseDescription.css';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';
import { IoIosArrowDropright } from 'react-icons/io';
import { CourseDescriptionList } from './CourseDescriptionList';
import completedImg from '../../../../assets/images/icons/completed.png';
import unCompletedImg from '../../../../assets/images/icons/uncompleted.png';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const CourseDescription = () => {
  const location = useLocation();
  const [status, setStatus] = useState(null);
  const [playVid, setPlayVid] = useState(false);

  // Find the current course based on location.pathname
  const currentCourse = CourseDescriptionList.find((course) =>
    location.pathname.includes(course.courseLink)
  );

  useEffect(() => {
    const checkStatus = () => {
      let email = localStorage.getItem('userEmail');
      let url =
        'https://r03cnyr1v4.execute-api.eu-west-1.amazonaws.com/testing/';

      let formData = {
        method: 'check_course_status',
        email: email,
        course: location.pathname.replace('-desc', ''),
      };

      axios
        .post(url, formData)
        .then((response) => setStatus(JSON.parse(response.data.body)))
        .catch((error) => console.log(error));
    };

    checkStatus();
  }, [location.pathname]);

  return (
    <div className="course-description">
      <div className="course-description-thumbnail">
        <div className="thumbnail-player">
          {!playVid ? (
            <img
              src={currentCourse?.thumbnail}
              alt="thumbnail"
            />
          ) : (
            <img
              src={currentCourse?.thumbnail}
              alt="thumbnail"
              style={{ display: 'none' }}
            />
          )}
          <div
            className="course-desc-play-icon-body"
            onClick={() => setPlayVid(true)}
          >
            <BsFillPlayFill className="course-desc-play-icon" />
          </div>
          {playVid ? (
            <ReactPlayer
              controls={true}
              className="course-desc-react-player"
              url={currentCourse?.videoLink}
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                    onContextMenu: (e) => e.preventDefault(),
                  },
                },
              }}
            />
          ) : null}
        </div>
        <h1>{currentCourse?.title}</h1>
      </div>

      <div className="course-description-content">
        <div>
          <h2>About Course</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: currentCourse?.courseDescription,
            }}
          ></p>

          {currentCourse?.moduleList.length > 0 && <h3>Content</h3>}
          <ol>
            {currentCourse?.moduleList.map((module, index) => (
              <li key={module + index}>{module}</li>
            ))}
          </ol>

          {currentCourse?.instructors.length > 0 &&
            currentCourse.instructors.map((instructor, index) => (
              <h4 key={instructor + index}>
                Instructor: <span>{instructor}</span>
              </h4>
            ))}
        </div>

        {location.state.isUserEnrolled ? (
          <div className="go-to-course-btn">
            {status === 'Completed' ? (
              <p className="progress-complete">
                Status: Completed
                <img
                  src={completedImg}
                  alt="completed"
                  style={{ marginLeft: '5px' }}
                />
              </p>
            ) : (
              <p className="progress">
                Status: Not completed
                <img
                  src={unCompletedImg}
                  alt="completed"
                  style={{ marginLeft: '5px' }}
                />
              </p>
            )}
            <Link to={currentCourse?.courseLink}>
              <button>
                Go to Course <ArrowCircleRightOutlinedIcon />
              </button>
            </Link>
          </div>
        ) : (
          <div className="go-to-course-btn">
            <Link
              to="/courses"
              state={{
                subscribed: true,
              }}
            >
              <button className="go-to-course-btn">
                Enroll this Course <IoIosArrowDropright />
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseDescription;

import React, { useState, Suspense } from 'react';
import './assets/App.scss';
import {
  BrowserRouter as Router,
  Route,
  Outlet,
  Routes,
  Navigate,
} from 'react-router-dom';
import LoginForm from './screens/auth/login';
import Register from './screens/auth/register';
import Nav from './screens/components/Nav/Nav';
import ConfirmEmail from './screens/auth/confirm-email';
import Footer from './screens/components/Footer/Footer';
import NewPassword from './screens/auth/reset-password';
import ForgotPassword from './screens/auth/forgot-password';

import { RouteLinks } from './Routes';
import { videoList } from './screens/pages/courses/VideoPlayer/videos';
import VideoPlayer from './screens/pages/courses/VideoPlayer/VideoPlayer';
import CourseDescription from './screens/pages/courses/CourseDescriptions/CourseDescription';
import { CourseDescriptionList } from './screens/pages/courses/CourseDescriptions/CourseDescriptionList';

const PricingPage = React.lazy(() => import('./screens/payment/payment'));

function App() {
  const [newPwd, setNewPwd] = useState(false);
  const [isUserAuthenticated, setUserAuthenticated] = useState(false);

  function PrivateRoute({ children }) {
    const currentUser = localStorage.getItem('access_token');
    return currentUser !== null ? children : <Navigate to="/login" />;
  }

  return (
    <Router>
      <Routes>
        {/* public routes */}
        <Route
          path="/"
          element={<Navigate to="/login" />}
        />
        <Route
          path="/signup"
          element={<Register />}
        />
        <Route
          path="/login"
          element={<LoginForm setUser={setUserAuthenticated} />}
        />
        <Route
          path="/confirm"
          element={<ConfirmEmail setUserAuthenticated={setUserAuthenticated} />}
        />
        <Route
          path="/reset"
          element={
            newPwd ? <NewPassword /> : <ForgotPassword setNewPwd={setNewPwd} />
          }
        />

        {/* private routes */}
        <Route
          element={
            <>
              <Nav />
              <Outlet />
            </>
          }
        >
          <Route
            path="/subscription"
            element={
              <PrivateRoute>
                <Suspense fallback={<div>Loading Subscription page...</div>}>
                  <PricingPage isUserAuthenticated={isUserAuthenticated} />
                </Suspense>
              </PrivateRoute>
            }
          />

          {/* route links here */}
          {RouteLinks.map((link) => (
            <Route
              key={link.path}
              path={link.path}
              element={<PrivateRoute>{link.element}</PrivateRoute>}
            />
          ))}

          {/* courses description route */}
          {CourseDescriptionList.map((course) => (
            <Route
              key={course.navLink}
              path={course.navLink}
              element={
                <PrivateRoute>
                  <CourseDescription />
                </PrivateRoute>
              }
            />
          ))}

          {/* video player */}
          {videoList.map((video) => (
            <Route
              key={video.videoLink}
              path={video.videoLink}
              element={
                <PrivateRoute>
                  <VideoPlayer singleVideo={video} />
                </PrivateRoute>
              }
            />
          ))}
        </Route>
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;

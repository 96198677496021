import React, { useEffect, useState } from 'react';
import './login.css';
import Nav from './nav';
import { Spin } from 'antd';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast, ToastContainer } from 'react-toastify';
import { signup } from '../../services/facebookEvent';
import { setVideo } from '../../services/video-service';
import { LoadingOutlined } from '@ant-design/icons';
import loginImage from '../../assets/images/signup_bg_image/signup image.png';
import { ReferralDetails, registerUser } from '../../services/auth-service';

function Register(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
  });
  useEffect(() => {
    signup();
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: 'white' }}
      spin
    />
  );

  const handleOnSubmit = async (data) => {
    setLoading(true);
    const dataForm = {
      name: data.fname + ' ' + data.lname,
      username: data.email,
      password: data.password,
      email: data.email,
    };
    const refData = {
      firstname: data.fname,
      lastname: data.lname,
      username: data.email,
      referalcode: data.refcode,
      email: data.email,
    };

    sessionStorage.setItem('username', data.email);
    await registerUser(dataForm)
      .then(function (response) {
        setLoading(false);
        ReferralDetails(refData);
        setVideo(dataForm.name, data.email);

        toast.success(
          'Registration successful. Please confirm your email address',
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            theme: 'colored',
          }
        );

        setTimeout(() => {
          navigate('/confirm');
        }, 2500);
      })
      .catch(function (error) {
        setLoading(false);
        const TotalerrorMessage = error.response.data.errorMessage.split(':');
        const errorMessage = TotalerrorMessage[2];

        toast.error(errorMessage.slice(0, errorMessage.length - 3), {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });
      });
  };

  window.dataLayer.push({
    event: 'pageview',
    page: {
      title: 'Register',
    },
  });

  return (
    <>
      <ToastContainer />

      <Nav />

      <section className="login-parent signup-parent">
        <div className="login-form">
          <form
            action="POST"
            method="post"
          >
            <h1>Sign up</h1>
            <p>Start your 7-day free trial.</p>

            <label htmlFor="fname">
              First Name
              <input
                type="text"
                id="fname"
                name="fname"
                className={errors.fname && 'invalid'}
                {...register('fname', {
                  required: 'Enter your name',
                  minLength: {
                    value: 1,
                    message: 'Your name must exceed 1 characters',
                  },
                })}
              />
              {errors.fname && (
                <p className="error-message">{errors.fname.message}</p>
              )}
            </label>

            <label htmlFor="lname">
              Last Name
              <input
                id="lname"
                type="text"
                name="lname"
                className={errors.lname && 'invalid'}
                {...register('lname', {
                  required: 'Enter your name',
                  minLength: {
                    value: 1,
                    message: 'Your name must exceed 1 characters',
                  },
                })}
              />
              {errors.lname && (
                <p className="error-message">{errors.lname.message}</p>
              )}
            </label>

            <label htmlFor="email">
              Email
              <input
                type="text"
                id="#email"
                name="email"
                className={errors.email && 'invalid'}
                {...register('email', {
                  required: 'Enter your email address',
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: 'Please enter a valid email address',
                  },
                })}
              />
              {errors.email && (
                <p className="error-message">{errors.email.message}</p>
              )}
            </label>

            <label htmlFor="password">
              Password
              <input
                type="password"
                id="password"
                name="password"
                className={errors.password && 'invalid'}
                {...register('password', {
                  required: 'Enter your password',
                  minLength: {
                    value: 8,
                    message: 'Your password must exceed 8 characters',
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](?=.*?[#?!.,@$%^&*-]).{8,}$/,
                    message:
                      'Your password must include at least one lowercase letter, one uppercase letter, one number and one special character',
                  },
                })}
              />
              {errors.password ? (
                <p className="error-message">{errors.password.message}</p>
              ) : (
                <p className="password-validation">
                  Must be at least 8 characters
                </p>
              )}
            </label>

            <label htmlFor="refcode">
              Referral Code
              <input
                type="text"
                id="refcode"
                name="refcode"
                {...register('refcode')}
              />
            </label>

            <button
              type="submit"
              onClick={handleSubmit(handleOnSubmit)}
              disabled={loading}
            >
              {loading ? (
                <Spin
                  indicator={antIcon}
                  style={{
                    height: '100%',
                  }}
                />
              ) : (
                'Create Account'
              )}
            </button>

            <p className="no_account">
              Already have an account?
              <a href="/login"> Log in</a>
            </p>
          </form>
          <h4>&copy; iLearn Cloud {new Date().getFullYear()}</h4>
        </div>

        <div className="login-image-parent">
          <img
            src={loginImage}
            alt="iLearn Cloud"
          />
        </div>
      </section>
    </>
  );
}

export default Register;

const questions = [
  {
    id: 1,
    questionText: 'What is the role of subscriptions in relation to Azure resources billing?',
    answerOptions: [
      {
        answerText: 'The more subscriptions you have, the cheaper each resource will get',
        isCorrect: false
      },
      {
        answerText: 'The price of a subscription depends on the location of your company or personal address',
        isCorrect: false
      },
      {
        answerText: ' The billing of Azure resources in your account is generated at the subscription scope',
        isCorrect: true
      }
    ]
  },
  {
    id: 2,
    questionText:
      'To limit spending on Azure, what is a recommended best practice?',
    answerOptions: [
      {
        answerText: 'Use Azure Alerts to get notified of when spending exceeds the subscription limits',
        isCorrect: false
      },
      {
        answerText: 'Use the subscription credit limits, which are built into all Azure subscriptions',
        isCorrect: false
      },
      {
        answerText: 'Use Azure spending limits on resources and services',
        isCorrect: true
      },
      {
        answerText: 'Monitor frequently used services and keep track of any excess usage via the Azure Spending Manager',
        isCorrect: false
      }
    ]
  },
  {
    id: 3,
    questionText: 'Monitor frequently used services and keep track of any excess usage via the Azure Spending Manager',
    answerOptions: [
      {
        answerText: "The age of the resource",
        isCorrect: false
      },
      {
        answerText: 'The location of the service or resource',
        isCorrect: true
      },
      {
        answerText: 'Resource size',
        isCorrect: true
      },
      {
        answerText: 'Resource usage (the more you use it, the cheaper it gets)',
        isCorrect: false
      },
      {
        answerText: 'How much bandwidth you will use',
        isCorrect: true
      }
    ]
  },
  {
    id: 4,
    questionText:
      ' Which Azure calculator would you use to figure out monthly costs for Azure services?',
    answerOptions: [
      {
        answerText: 'Azure Pricing Calculator',
        isCorrect: true
      },
      {
        answerText: 'Azure Service Calculator',
        isCorrect: false
      },
      {
        answerText: 'Azure Portal Service Estimation',
        isCorrect: false
      },
      {
        answerText: 'Total Cost of Ownership Calculator',
        isCorrect: false
      },
    ]
  },
  {
    id: 5,
    questionText:
      'Which features are in Azure Cost Management?',
    answerOptions: [
      {
        answerText: 'Visualizing current costs for your Azure account',
        isCorrect: true
      },
      {
        answerText: "Automatic shutdown of services that haven't been used for a set period of time",
        isCorrect: false
      },
      {
        answerText: 'Recommendations to move services between Azure regions to save on cost',
        isCorrect: false
      },
      {
        answerText: 'Visualizing future costs for your Azure account',
        isCorrect: true
      },
      {
        answerText: 'Potential discounts through gamification of using best practices ',
        isCorrect: false
      }
    ]
  },
  {
    id: 6,
    questionText:
      ' How do you sign up for a service-level agreement with Azure?',
    answerOptions: [
      {
        answerText: 'Call the regional support number as provided in the Azure portal to discuss the desired level of the agreement',
        isCorrect: false
      },
      {
        answerText: 'Call the regional support number as provided in the Azure portal to discuss the desired level of the agreement',
        isCorrect: false
      },
      {
        answerText: 'Service-level agreements are included with every Azure paid service automatically',
        isCorrect: true
      },
      {
        answerText: 'In the Azure portal, go to the service in question and click on the Service Level Agreement section',
        isCorrect: false
      },
    ]
  },
  {
    id: 7,
    questionText: 'How do you submit a support ticket?',
    answerOptions: [
      {
        answerText: ' Through the Azure Support Portal, which comes with any paid support plan',
        isCorrect: false
      },
      {
        answerText: 'Through the email address support@azure.com, making sure you add your account ID in the subject line',
        isCorrect: false
      },
      {
        answerText: 'Through the Azure portal',
        isCorrect: true
      },
      {
        answerText: 'Through the phone number for your region and support level, as noted in the Azure portal',
        isCorrect: false
      }
    ]
  },

];

export default questions;

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './TotalQuiz.css';
import axios from 'axios';

const TotalQuiz = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [percentage, setPercentage] = useState(0);
  const { from, course, moduleName } = location.state;
  const [showCert, setShowCert] = useState(false);

  const sendResult = useCallback(() => {
    let email = localStorage.getItem('userEmail');
    let url = 'https://r03cnyr1v4.execute-api.eu-west-1.amazonaws.com/testing/';

    let formData = {
      method: 'update_course_status',
      email: email,
      course: from,
      score: percentage,
    };

    axios
      .post(url, formData)
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  }, [from, percentage]);

  useEffect(() => {
    const answers = JSON.parse(sessionStorage.getItem('answers')) || [];
    const questionsLength =
      JSON.parse(sessionStorage.getItem('questionsLength')) || 1;

    if (answers.length !== 0) {
      const calculatedPercentage = (answers.length / questionsLength) * 100;
      const calculatedResult = Math.floor(calculatedPercentage);
      setPercentage(calculatedResult);
    }

    //send result back to database after user has completed exam
    if (percentage >= 70) {
      sendResult();
    }
  }, [percentage, sendResult]);

  setTimeout(() => {
    setShowCert(true);
  }, 5000);

  return (
    <div className="quiz-body">
      <header className="quiz-header-caption">
        <h1>{course}</h1>
      </header>
      <h3 id="center-header">Total Score</h3>

      <section className="quiz">
        <section className="quiz-score">
          <h3>Total Quiz Score</h3>

          {moduleName && percentage >= 70 ? (
            <section className="quiz-score-chart">
              <h2>You have completed the module: {moduleName}</h2>
              <p>Complete other modules to get your certificate</p>

              <button
                onClick={() => navigate('/microsoft-azure-fundamentals')}
                className="next-module-btn"
              >
                Continue Learning
              </button>
            </section>
          ) : !moduleName && percentage >= 70 ? (
            <section className="quiz-score-chart">
              <div style={{ width: 290, height: 290 }}>
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                  styles={{
                    path: {
                      // Path color
                      stroke: '#6499e9',
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',
                      // Customize transition animation
                      transition: 'stroke-dashoffset 0.5s ease 0s',
                      // Rotate the path
                      transform: 'rotate(0.45turn)',
                      transformOrigin: 'center center',
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      stroke: '#D9D9D9',
                    },
                    // Customize the text
                    text: {
                      fill: '#6499e9',
                      fontSize: '1.6rem',
                      fontWeight: 'bold',
                      fontFamily: 'Inter',
                    },
                  }}
                />
              </div>

              <div className="quiz-pass-fail">
                <h4>You've Passed!</h4>
                <p>Congratulations</p>
                <p>Your certificate will be available in a moment!</p>

                <div>
                  {showCert ? (
                    <button
                      onClick={() =>
                        navigate('/certificate', {
                          state: { course: course },
                        })
                      }
                    >
                      Download Certificate
                    </button>
                  ) : (
                    <button>Getting Certificate...</button>
                  )}
                  <button
                    onClick={() =>
                      navigate('/correctAnswer', { state: { from: from } })
                    }
                  >
                    See Answers
                  </button>
                </div>
              </div>
            </section>
          ) : (
            <section className="quiz-score-chart">
              <div style={{ width: 290, height: 290 }}>
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                  styles={{
                    path: {
                      // Path color
                      stroke: '#F48C06',
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',
                      // Customize transition animation
                      transition: 'stroke-dashoffset 0.5s ease 0s',
                      // Rotate the path
                      transform: 'rotate(0.45turn)',
                      transformOrigin: 'center center',
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      stroke: '#D9D9D9',
                    },
                    // Customize the text
                    text: {
                      fill: '#F48C06',
                      fontSize: '1.6rem',
                      fontWeight: 'bold',
                      fontFamily: 'Inter',
                    },
                  }}
                />
              </div>

              <div className="quiz-fail">
                <h4 style={{ color: '#F48C06' }}>Quiz Failed!</h4>
                <p>You need to score at least 70% to pass</p>

                <div>
                  <button onClick={() => navigate(-1)}>Retake Quiz</button>
                </div>
              </div>
            </section>
          )}
        </section>
      </section>
    </div>
  );
};

export default TotalQuiz;

import React from 'react';
import ReportIcon from '@mui/icons-material/Report';
import './ErrorModal.css';

const ErrorModal = ({ open, onClose }) => {
	const handleClose = (e) => {
		onClose && onClose(e);
	};

	// returns nothing if props.open is false
	if (!open) {
		return null;
	}

	return (
		<div className="modal-parent">
			<div className="modal-body">
				<ReportIcon id="iconFont" />

				<div>
					<h1>Can't Proceed</h1>
					<p>One or more test cases failed!</p>

					<button onClick={handleClose}>Try Again</button>
				</div>
			</div>
		</div>
	);
};

export default ErrorModal;

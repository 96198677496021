const questions = [
	{
		questionText: 'What does the following command do\n "$ cp ../file"',
		answerOptions: [
			{
				answerText: 'Copies the file from current directory to parent directory',
				isCorrect: false
			},
			{
				answerText: 'Copies the file from parent directory to its parent directory',
				isCorrect: false
			},
			{
				answerText: 'Copies the file from parent directory to current directory',
				isCorrect: true
			},
			{
				answerText: 'Error in the command',
				isCorrect: false
			}
		]
	},
	{
		questionText: 'We can kill any background executing process?',
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: false
			},
			{
				answerText: 'False',
				isCorrect: true
			}
		]
	},
	{
		questionText: 'Choose the command to list only the file "error.txt"',
		answerOptions: [
			{
				answerText: 'ls *err*.txt',
				isCorrect: false
			},
			{
				answerText: 'ls e*text',
				isCorrect: false
			},
			{
				answerText: 'ls error.???',
				isCorrect: false
			},
			{
				answerText: 'ls error.text',
				isCorrect: true
			}
		]
	},
	{
		questionText: 'Choose option to list files by modification time',
		answerOptions: [
			{
				answerText: 'ls -mt',
				isCorrect: false
			},
			{
				answerText: 'ls -t',
				isCorrect: true
			},
			{
				answerText: 'ls -a',
				isCorrect: false
			},
			{
				answerText: 'ls -l',
				isCorrect: false
			}
		]
	},
	{
		questionText: 'General purpose UNIX command are found tyically in the following directory',
		answerOptions: [
			{
				answerText: '/dev',
				isCorrect: false
			},
			{
				answerText: '/sbin',
				isCorrect: false
			},
			{
				answerText: '/bin and /usr/bin',
				isCorrect: true
			},
			{
				answerText: '/tmp',
				isCorrect: false
			}
		]
	},
	{
		questionText: 'How you list only hidden files',
		answerOptions: [
			{
				answerText: 'ls -la | grep "^"',
				isCorrect: true
			},
			{
				answerText: 'ls -l | grep "^"',
				isCorrect: false
			},
			{
				answerText: 'ls -a',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		questionText: 'Which command can be used to find resource limits?',
		answerOptions: [
			{
				answerText: 'rlimit',
				isCorrect: false
			},
			{
				answerText: 'ulimit',
				isCorrect: true
			},
			{
				answerText: 'mylimit',
				isCorrect: false
			},
			{
				answerText: 'getlimit',
				isCorrect: false
			}
		]
	},
	{
		questionText: 'Which switch of rm command is used to remove a directory with all its subdirectories forcibly?',
		answerOptions: [
			{
				answerText: 'rm -fi',
				isCorrect: false
			},
			{
				answerText: 'rm -ri',
				isCorrect: false
			},
			{
				answerText: 'rm -rf',
				isCorrect: true
			},
			{
				answerText: 'rm -r',
				isCorrect: false
			}
		]
	},
	{
		questionText: '__ can talk to the kernel',
		answerOptions: [
			{
				answerText: 'Shell',
				isCorrect: false
			},
			{
				answerText: 'Shell script',
				isCorrect: false
			},
			{
				answerText: 'System calls',
				isCorrect: true
			},
			{
				answerText: 'System instruction',
				isCorrect: false
			}
		]
	},
	{
		questionText: 'How to debug a shell script?',
		answerOptions: [
			{
				answerText: 'sh -nv testscript.sh',
				isCorrect: true
			},
			{
				answerText: 'None',
				isCorrect: false
			},
			{
				answerText: 'sh -d testscript.sh',
				isCorrect: false
			},
			{
				answerText: 'sh -dx testscript',
				isCorrect: false
			}
		]
	},
	{
		questionText: 'How to connect to a remote server and execute some commands?',
		answerOptions: [
			{
				answerText: 'Using ssh',
				isCorrect: false
			},
			{
				answerText: 'Using ftp',
				isCorrect: false
			},
			{
				answerText: 'using telnet',
				isCorrect: true
			},
			{
				answerText: 'Using scp',
				isCorrect: false
			}
		]
	},
	{
		questionText: 'What way can we copy files from one machine to other?',
		answerOptions: [
			{
				answerText: 'ftp',
				isCorrect: true
			},
			{
				answerText: 'bluetooth',
				isCorrect: false
			},
			{
				answerText: 'xsync',
				isCorrect: false
			},
			{
				answerText: 'xcopy',
				isCorrect: false
			}
		]
	}
];

export default questions;

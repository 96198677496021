const questions = [
	{
		id: 1,
		questionText: 'Which one of the following is used to build Switching Table?',
		answerOptions: [
			{
				answerText: 'MAC address',
				isCorrect: false
			},
			{
				answerText: 'Destination IP',
				isCorrect: false
			},
			{
				answerText: 'Source IP',
				isCorrect: false
			},
			{
				answerText: 'All of  them',
				isCorrect: false
			},
			{
				answerText: 'Source MAC',
				isCorrect: true
			}
		]
	},
	{
		id: 2,
		questionText: 'How to enable syslog on cisco routers?',
		answerOptions: [
			{
				answerText: 'logging on',
				isCorrect: true
			},
			{
				answerText: 'logging run',
				isCorrect: false
			},
			{
				answerText: 'start logging',
				isCorrect: false
			},
			{
				answerText: 'run logging',
				isCorrect: false
			},
			{
				answerText: 'logging start',
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: 'Which ones are the Wireless security Protocols?',
		answerOptions: [
			{
				answerText: 'WPA3 (Wif-Fi Protected Access 3)',
				isCorrect: false
			},
			{
				answerText: 'WEP (Wired Equivalent Privacy)',
				isCorrect: false
			},
			{
				answerText: 'WPA (Wi-Fi Protected Access)',
				isCorrect: false
			},
			{
				answerText: 'WPA2 (Wi-Fi Protected Access 2)',
				isCorrect: false
			},
			{
				answerText: 'All of them',
				isCorrect: true
			}
		]
	},
	{
		id: 4,
		questionText: '..... is simply the technical term for a network name',
		answerOptions: [
			{
				answerText: 'Router-ID',
				isCorrect: false
			},
			{
				answerText: 'VLAN-ID',
				isCorrect: false
			},
			{
				answerText: 'WLAN-ID',
				isCorrect: false
			},
			{
				answerText: 'SSID',
				isCorrect: true
			},
			{
				answerText: 'WID',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText: 'Whic Cisco command shows OSPF neighbor adjancies?',
		answerOptions: [
			{
				answerText: 'show ip ospf database',
				isCorrect: false
			},
			{
				answerText: 'show ip ospf neighbor',
				isCorrect: true
			},
			{
				answerText: 'debug ip ospf neighbor',
				isCorrect: false
			},
			{
				answerText: 'show protocols',
				isCorrect: false
			},
			{
				answerText: 'show ip ospf',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText: 'Which one is a Denial of Service Attack?',
		answerOptions: [
			{
				answerText: 'Smurfing',
				isCorrect: true
			},
			{
				answerText: 'ARP Spoofing',
				isCorrect: false
			},
			{
				answerText: 'Spooling Attack',
				isCorrect: false
			}
		]
	},
	{
		id: 7,
		questionText: 'What does a PC broadcast to learn an unknown MAC address',
		answerOptions: [
			{
				answerText: 'ARP Reply',
				isCorrect: false
			},
			{
				answerText: 'ICMP Reply',
				isCorrect: false
			},
			{
				answerText: 'None of them',
				isCorrect: false
			},
			{
				answerText: 'ICMP Request',
				isCorrect: false
			},
			{
				answerText: 'ARP Request',
				isCorrect: true
			}
		]
	},
	{
		id: 8,
		questionText: 'A VLAN trunked link belong to which VLAN br Default?',
		answerOptions: [
			{
				answerText: 'VLAN 0',
				isCorrect: false
			},
			{
				answerText: 'VLAN 10',
				isCorrect: false
			},
			{
				answerText: 'None of them',
				isCorrect: false
			},
			{
				answerText: 'VLAN 1',
				isCorrect: false
			},
			{
				answerText: 'All VLANs',
				isCorrect: true
			}
		]
	},
	{
		id: 9,
		questionText: 'Which ones are the main characteristic of the REST?',
		answerOptions: [
			{
				answerText: 'Scalable',
				isCorrect: false
			},
			{
				answerText: 'Lightweight',
				isCorrect: false
			},
			{
				answerText: 'All of them',
				isCorrect: true
			},
			{
				answerText: 'Platform-agnostic',
				isCorrect: false
			},
			{
				answerText: 'Flexible',
				isCorrect: false
			}
		]
	},
	{
		id: 10,
		questionText: 'Neighbour Discovery Protocols work on....',
		answerOptions: [
			{
				answerText: 'Layer 3',
				isCorrect: false
			},
			{
				answerText: 'Layer 2',
				isCorrect: true
			},
			{
				answerText: 'Layer 1',
				isCorrect: false
			},
			{
				answerText: 'Layer 5',
				isCorrect: false
			},
			{
				answerText: 'Layer 4',
				isCorrect: false
			}
		]
	},
	{
		id: 11,
		questionText: 'IP is a connectionless Protocol',
		answerOptions: [
			{
				answerText: 'False',
				isCorrect: false
			},
			{
				answerText: 'True',
				isCorrect: true
			}
		]
	},
	{
		id: 12,
		questionText:
			'IPV6 Link Local Addresses can be configured in two ways. Auto-Address Configuration and Manual Configuration',
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: true
			},
			{
				answerText: 'False',
				isCorrect: false
			}
		]
	},
	{
		id: 13,
		questionText: 'How can we turn to Privileged mode from Interface configuration mode on Cisco routers?',
		answerOptions: [
			{
				answerText: 'Esc',
				isCorrect: false
			},
			{
				answerText: 'Ctrl-Alt-Del',
				isCorrect: false
			},
			{
				answerText: 'Ctrl-Z',
				isCorrect: true
			},
			{
				answerText: 'Ctrl-P',
				isCorrect: false
			},
			{
				answerText: 'Ctrl-A',
				isCorrect: false
			}
		]
	},
	{
		id: 14,
		questionText:
			'.... is the mechanism that is used during a Traffic Exceed. the Exceed Traffic is buffered and with a little delay, it is sent again.',
		answerOptions: [
			{
				answerText: 'traffic Determining',
				isCorrect: false
			},
			{
				answerText: 'Traffic Shaping',
				isCorrect: true
			},
			{
				answerText: 'Traffic Policing',
				isCorrect: false
			},
			{
				answerText: 'Traffic Monitoring',
				isCorrect: false
			},
			{
				answerText: 'Traffic Selecting',
				isCorrect: false
			}
		]
	},
	{
		id: 15,
		questionText: 'How to open debug for nat?',
		answerOptions: [
			{
				answerText: 'debug start nat',
				isCorrect: false
			},
			{
				answerText: 'run nat',
				isCorrect: false
			},
			{
				answerText: 'start nat',
				isCorrect: false
			},
			{
				answerText: 'debug ip nat',
				isCorrect: true
			},
			{
				answerText: 'debug nat stats',
				isCorrect: false
			}
		]
	},
	{
		id: 16,
		questionText: 'How to enable DHCP Snooping on CIsco devices?',
		answerOptions: [
			{
				answerText: 'dhcp snooping start',
				isCorrect: false
			},
			{
				answerText: 'run snooping',
				isCorrect: false
			},
			{
				answerText: 'run dhcp snooping',
				isCorrect: false
			},
			{
				answerText: 'enable snooping',
				isCorrect: false
			},
			{
				answerText: 'ip dhcp snooping',
				isCorrect: true
			}
		]
	},
	{
		id: 17,
		questionText: 'Which one is a Distance Vector Protocol?',
		answerOptions: [
			{
				answerText: 'EIGRP',
				isCorrect: false
			},
			{
				answerText: 'BGP',
				isCorrect: false
			},
			{
				answerText: 'RIP',
				isCorrect: true
			},
			{
				answerText: 'OSPF',
				isCorrect: false
			},
			{
				answerText: 'IS-IS',
				isCorrect: false
			}
		]
	},
	{
		id: 18,
		questionText: '... is the attack type in which routing table is manipulated by attackers',
		answerOptions: [
			{
				answerText: 'Replay Attack',
				isCorrect: false
			},
			{
				answerText: 'Rerouting',
				isCorrect: true
			},
			{
				answerText: 'DNS Spoofing',
				isCorrect: false
			},
			{
				answerText: 'Ping of Death',
				isCorrect: false
			},
			{
				answerText: 'Session Hijacking',
				isCorrect: false
			}
		]
	},
	{
		id: 19,
		questionText:
			'If there are centralized WLAN Controllers in each site and Access Points that are connected to this WLC, this is....',
		answerOptions: [
			{
				answerText: 'Classic Wireless Network Design',
				isCorrect: false
			},
			{
				answerText: 'None of them',
				isCorrect: false
			},
			{
				answerText: 'Flexconnect Wireless Network Design',
				isCorrect: false
			},
			{
				answerText: 'SD-Access Wireless Network Design',
				isCorrect: false
			},
			{
				answerText: 'Centralized Wireless Network Design',
				isCorrect: true
			}
		]
	},
	{
		id: 20,
		questionText: 'Which of the following protocols is used to eliminate Layer 2 loops?',
		answerOptions: [
			{
				answerText: 'ARP',
				isCorrect: false
			},
			{
				answerText: 'IP',
				isCorrect: false
			},
			{
				answerText: 'OSPF',
				isCorrect: false
			},
			{
				answerText: 'STP',
				isCorrect: true
			},
			{
				answerText: 'VIP',
				isCorrect: false
			}
		]
	}
];

export default questions;

import React, { useState } from 'react';
import Nav from './nav';
import './login.css';
import { Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { login } from '../../services/auth-service';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast, ToastContainer } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import loginImage from '../../assets/images/signup_bg_image/login image.png';

function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
  });

  // Loader Icon
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: 'white' }}
      spin
    />
  );

  // Submit Function
  const handleOnSubmit = async (data) => {
    setLoading(true);
    const payload = {
      username: data.email,
      password: data.password,
    };
    await login(payload)
      .then(function (response) {
        setLoading(false);
        sessionStorage.removeItem('username');
        toast.success('Login successful', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });
        const token = response.data.AuthenticationResult.AccessToken;
        localStorage.setItem('email', data.email);
        localStorage.setItem('access_token', token);

        setTimeout(() => {
          navigate('/courses');
        }, 2000);
      })
      .catch(function (error) {
        setLoading(false);
        const TotalerrorMessage = error.response.data.errorMessage.split(':');
        const errorMessage = TotalerrorMessage[2];
        toast.error(errorMessage.slice(0, errorMessage.length - 3), {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });
      });
  };

  return (
    <>
      <Nav />

      <ToastContainer style={{ zIndex: 9999999 }} />

      <section className="login-parent">
        <div className="login-form">
          <form
            action="POST"
            method="post"
          >
            <h1>Log in</h1>
            <p>Welcome back! Please enter your details.</p>

            <label htmlFor="email">
              Email
              <input
                type="text"
                id="#email"
                name="email"
                placeholder="you@email.com"
                autoComplete="on"
                className={errors.email && 'invalid'}
                {...register('email', {
                  required: 'Enter email address',
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: 'Please enter a valid email address',
                  },
                })}
              />
              {errors.email && (
                <p className="error-message">{errors.email.message}</p>
              )}
            </label>

            <label htmlFor="password">
              Password
              <input
                id="password"
                type="password"
                autoComplete="on"
                name="password"
                placeholder="••••••••"
                className={errors.password && 'invalid'}
                {...register('password', {
                  required: 'Enter password',
                  minLength: {
                    value: 8,
                    message: 'Your password must exceed 8 characters',
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](?=.*?[#?!.,@$%^&*-]).{8,}$/,
                    message:
                      'Your password must include at least one lowercase letter, one uppercase letter, one number and one special character',
                  },
                })}
              />
              {errors.password && (
                <p className="error-message">{errors.password.message}</p>
              )}
            </label>

            <label
              htmlFor="forgot_password"
              className="forgot-password"
            >
              <p>
                <input
                  type="checkbox"
                  name="forgot_password"
                  id="forgot_password"
                />
                Remember for 30 days
              </p>

              <a href="/reset">Forgot Password</a>
            </label>

            <button
              type="submit"
              onClick={handleSubmit(handleOnSubmit)}
              disabled={loading}
            >
              {loading ? (
                <Spin
                  indicator={antIcon}
                  style={{
                    height: '100%',
                  }}
                />
              ) : (
                'Sign In'
              )}
            </button>

            <p className="no_account">
              Don't have an account? <a href="/signup"> Sign Up</a>
            </p>
          </form>
          <h4>&copy; iLearn Cloud {new Date().getFullYear()}</h4>
        </div>

        <div className="login-image-parent">
          <img
            src={loginImage}
            alt="iLearn Cloud"
          />
        </div>
      </section>
    </>
  );
}

export default LoginForm;

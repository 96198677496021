const questions = [
	{
		id: 1,
		questionText: 'When can you delete a resource group from Azure?',
		answerOptions: [
			{
				answerText: 'When all the resources in the resource group have stopped',
				isCorrect: false
			},
			{
				answerText: 'only when the resource group is empty',
				isCorrect: false
			},
			{
				answerText: 'When an account is deactivated',
				isCorrect: false
			},
			{
				answerText: 'At any time, as long as your resources or resource group are not locked by a related service',
				isCorrect: true
			},

		]
	},
	{
		id: 2,
		questionText:
			'What is an Azure region?',
		answerOptions: [
			{
				answerText: 'A set of datacenters, deployed within a latency-defined perimeter and connected through a dedicated regional low-latency network',
				isCorrect: true
			},
			{
				answerText: 'A geographical part of the Azure platform ',
				isCorrect: false
			},
			{
				answerText: 'One or more datacenters equipped with independent power, cooling, and networking .',
				isCorrect: false
			},
			{
				answerText: 'A collection of similar services that can be hosted in an Azure data center.',
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: 'Which statement is true of resource groups in Azure?',
		answerOptions: [
			{
				answerText: "Resource groups don't contain any data and are assigned rather than created",
				isCorrect: false
			},
			{
				answerText: 'Resource groups are transferrable between regions.',
				isCorrect: false
			},
			{
				answerText: 'Resource groups are containers that hold related resources for an Azure solution',
				isCorrect: true
			},
			{
				answerText: 'Resource groups can only hold foundational resource types',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText:
			'What is an availability zone?',
		answerOptions: [
			{
				answerText: 'A unique physical location within a region that is made up of one or more datacenters equipped with independent power, cooling, and networking',
				isCorrect: true
			},
			{
				answerText: 'One or more datacenters that are close together to provide backup',
				isCorrect: false
			},
			{
				answerText: 'A collection of software that can enable high scalability at short notice',
				isCorrect: false
			},
			{
				answerText: 'A set of datacenters close together',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText:
			'Select all the true statements regarding Azure Resource Manager (select 3 answers)',
		answerOptions: [
			{
				answerText: 'Azure Resource Manager enables the managing of your infrastructure through scripts rather than declarative templates.',
				isCorrect: false
			},
			{
				answerText: 'Azure Resource Manager is the deployment and management service for Azure.',
				isCorrect: true
			},
			{
				answerText: 'Azure Resource Manager templates (ARM templates) always deploy resources in the same consistent state.',
				isCorrect: true
			},
			{
				answerText: 'If a user sends a request from any Azure tools, APIs, or SDKs, Azure Resource Manager handles the request.',
				isCorrect: true
			}
		]
	},

];

export default questions;

const questions = [
	{
		id: 1,
		questionText: 'Whilch of the following commands will install Ansible on Ubuntu?',
		answerOptions: [
			{
				answerText: 'sudo apt install ansible',
				isCorrect: true
			},
			{
				answerText: 'sudo apt install ansible-controller',
				isCorrect: false
			},
			{
				answerText: 'sudo apt install ansible-master',
				isCorrect: false
			},
			{
				answerText: 'sudo apt install ansible-server',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText: 'Which command will you use to check your Ansible version?',
		answerOptions: [
			{
				answerText: 'ansible-ctl --version',
				isCorrect: false
			},
			{
				answerText: 'ansible --version',
				isCorrect: true
			},
			{
				answerText: 'ansible v',
				isCorrect: false
			},
			{
				answerText: 'ansiblectl v',
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: 'The default location of the ansible.cfg file is:',
		answerOptions: [
			{
				answerText: '/etc/ansible.cfg',
				isCorrect: false
			},
			{
				answerText: '/etc/ansible/ansible.conf',
				isCorrect: false
			},
			{
				answerText: '/etc/ansible/ansible.cfg',
				isCorrect: true
			},
			{
				answerText: '/home/ansible/ansible.cfg',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText: 'The default Ansible inventory file is located at:',
		answerOptions: [
			{
				answerText: '/home/ansible/inventory',
				isCorrect: false
			},
			{
				answerText: '/etc/ansible/inventory',
				isCorrect: false
			},
			{
				answerText: '/home/ansible/hosts',
				isCorrect: false
			},
			{
				answerText: '/etc/ansible/hosts',
				isCorrect: true
			}
		]
	},
	{
		id: 5,
		questionText: 'With Ansible you can only use SSH key as it does not work with SSh password',
		answerOptions: [
			{
				answerText: 'true',
				isCorrect: false
			},
			{
				answerText: 'false',
				isCorrect: true
			}
		]
	},
	{
		id: 6,
		questionText: 'You can also get the Ansible documentation directly via your terminal by using:',
		answerOptions: [
			{
				answerText: 'ansible-doc',
				isCorrect: true
			},
			{
				answerText: 'ansible --doc',
				isCorrect: false
			},
			{
				answerText: 'ansible -d',
				isCorrect: false
			},
			{
				answerText: 'ansible-documentation',
				isCorrect: true
			}
		]
	},
	{
		id: 7,
		questionText: 'What language is an Ansible playbook written in by default?',
		answerOptions: [
			{
				answerText: 'YAML format',
				isCorrect: true
			},
			{
				answerText: 'JSON format',
				isCorrect: false
			},
			{
				answerText: 'XML format',
				isCorrect: false
			},
			{
				answerText: 'HTML format',
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText:
			'Ansible is an open-source agentless automation tool that enables you to easily configure and deploy systems',
		answerOptions: [
			{
				answerText: 'true',
				isCorrect: true
			},
			{
				answerText: 'false',
				isCorrect: false
			}
		]
	}
];

export default questions;

const questions = [
  {
    id: 1,
    questionText: 'What is the Benefit of a Hybrid Cloud Approach?',
    answerOptions: [
      {
        answerText: ' It requires no changes to existing code or applications, allowing companies to scale their infrastructure into the cloud.',
        isCorrect: false
      },
      {
        answerText: '  Using alternative energy sources for powering some services can create tax benefits in some regions.',
        isCorrect: false
      },
      {
        answerText: 'It enables companies to use a mix of private and public cloud components',
        isCorrect: true
      },
      {
        answerText: 'All maintenance is handled by Microsoft Azure, so it reduces support costs.',
        isCorrect: false
      },
    ]
  },
  {
    id: 2,
    questionText:
      ' What does Infrastructure as a Service describe?',
    answerOptions: [
      {
        answerText: ' Any service on Azure that you can rent or buy upfront',
        isCorrect: false
      },
      {
        answerText: ' A type of cloud computing service that offers essential compute, storage, and networking resources on demand, on a pay-as-you-go basis',
        isCorrect: true
      },
      {
        answerText: 'A complete development and deployment environment in the cloud, with resources that enable you to deliver everything from simple cloud-based apps to sophisticated, cloud-enabled enterprise applications',
        isCorrect: false
      },
      {
        answerText: ' A type of cloud computing service that allows users to connect to and use cloud-based apps over the internet',
        isCorrect: false
      }
    ]
  },
  {
    id: 3,
    questionText: 'What is consumption-based pricing on Azure?',
    answerOptions: [
      {
        answerText: " Any service you use on Azure has a consumption component as part of the pricing",
        isCorrect: false
      },
      {
        answerText: 'Some core services on Azure are consumed constantly to keep your applications running. You pay for this consumption',
        isCorrect: false
      },
      {
        answerText: ' Consumption-based pricing is when you are charged for only what you use (pay-as-you-go rate).',
        isCorrect: true
      },
      {
        answerText: 'Consumption-based pricing is the model for paying for any services on a free Azure account',
        isCorrect: false
      },
    ]
  },
  {
    id: 4,
    questionText:
      ' What does fault tolerance describe for cloud computing?',
    answerOptions: [
      {
        answerText: 'A system within Azure that uses cloud computing resources to mitigate faults quickly',
        isCorrect: false
      },
      {
        answerText: 'Ensuring services and applications remain available in the event of a failure',
        isCorrect: true
      },
      {
        answerText: 'A complete plan to recover critical business systems and normal operations, in case of a disaster',
        isCorrect: false
      },
      {
        answerText: 'The ability for multiple regions within Azure to "cover" each other in case of an outage',
        isCorrect: false
      },
    ]
  },
  {
    id: 5,
    questionText:
      'What is high availability in cloud computing?',
    answerOptions: [
      {
        answerText: 'Microsoft guarantees you will always have access to the resources on Azure.',
        isCorrect: false
      },
      {
        answerText: ' If one resource on Azure dies unexpectedly, another resource will almost instantly take over the workload.',
        isCorrect: true
      },
      {
        answerText: 'High availability refers to the availability of the Azure portal. You can always get access to an overview of what your Azure services are doing',
        isCorrect: false
      },
      {
        answerText: ' Azure will provide an infinite number of resources to your application to make sure it always runs optimally.',
        isCorrect: false
      },

    ]
  },
  {
    id: 6,
    questionText:
      ' Why is cloud agility important for businesses?',
    answerOptions: [
      {
        answerText: 'To automatically improve the fidelity of resource usage and utilize the platform better',
        isCorrect: true
      },
      {
        answerText: 'To automatically improve the fidelity of resource usage and utilize the platform better',
        isCorrect: false
      },
      {
        answerText: ' To enable the ability to rapidly develop, test, and launch software applications that drive business growth',
        isCorrect: true
      },
      {
        answerText: 'To increase the return on investment from using cloud elasticity',
        isCorrect: false
      },
    ]
  },
  {
    id: 7,
    questionText: 'What is the difference between OpEx and CapEx?',
    answerOptions: [
      {
        answerText: 'OpEx is costs for acquiring assets. CapEx is an ongoing cost for running a business',
        isCorrect: false
      },
      {
        answerText: "OpEx is a cost on services you don't own, such as cloud computing. CapEx is a cost of ownership",
        isCorrect: false
      },
      {
        answerText: 'OpEx is an ongoing cost for running a business. CapEx is the cost of acquiring and maintaining assets',
        isCorrect: true
      },
      {
        answerText: ' OpEx is better return on investment in the short term. CapEx is better return on investment in the long term',
        isCorrect: false
      }
    ]
  },
  {
    id: 8,
    questionText: "What's the best definition for scalability on Azure?",
    answerOptions: [
      {
        answerText: 'Scalability is the ability to quickly expand or decrease computer processing, memory, and storage resources to meet changing demands without worrying about capacity planning and engineering for peak usage',
        isCorrect: false
      },
      {
        answerText: ' Scalability is the ability of a system to handle increased load. Services covered by Azure Autoscale can scale automatically to match demand to accommodate workload',
        isCorrect: true
      },
      {
        answerText: ' If an account has more than one Azure region active, resources can be copied between these regions',
        isCorrect: false
      },
      {
        answerText: 'Scaling of resources on Azure is currently not possible',
        isCorrect: false
      }
    ]
  },
  {
    id: 9,
    questionText: "Select all the true statements per Microsoft's definitions of cloud types",
    answerOptions: [
      {
        answerText: ' In private clouds, services and infrastructure are always maintained on a private network, and the hardware and software are dedicated solely to your organization',
        isCorrect: true
      },
      {
        answerText: 'Public clouds typically cost more than private clouds, but they are generally less reliable than on-premises infrastructure (private cloud)',
        isCorrect: false
      },
      {
        answerText: 'Private clouds can be hosted at your datacenter or hosted by a third-party service. Private clouds offer advantages of flexibility, control, and scalability',
        isCorrect: true
      },
      {
        answerText: 'A hybrid cloud combines a public cloud (such as Azure) with on-premises infrastructure (private cloud)',
        isCorrect: true
      },

    ]
  },
  {
    id: 10,
    questionText: 'What is "serverless" computing?',
    answerOptions: [
      {
        answerText: 'A theory for making computing completely cloud-based for certain scenarios',
        isCorrect: false
      },
      {
        answerText: ' An application that is running on the cloud platform without the use of servers',
        isCorrect: false
      },
      {
        answerText: ' A way for developers to build applications faster by eliminating the need for them to manage infrastructure',
        isCorrect: true
      },
      {
        answerText: 'A complete development and deployment environment in the cloud',
        isCorrect: false
      },
    ]
  },
];

export default questions;

const questions = [
	{
		id: 1,
		questionText: 'Which of the following commands runs Jenkins from the command line?',
		answerOptions: [
			{
				answerText: 'java -jar jenkins.war',
				isCorrect: true
			},
			{
				answerText: 'java -war jenkins.jar',
				isCorrect: false
			},
			{
				answerText: 'java -jar jenkins.jar',
				isCorrect: false
			},
			{
				answerText: 'java -war jenkins.war',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText: 'Jenkins is a fork of what other CI tool?',
		answerOptions: [
			{
				answerText: 'Hudson',
				isCorrect: true
			},
			{
				answerText: 'Git',
				isCorrect: false
			},
			{
				answerText: 'Concourse',
				isCorrect: false
			},
			{
				answerText: 'Maven',
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: 'Who is credited with the creation of Jenkins?',
		answerOptions: [
			{
				answerText: 'Linus Torvalds',
				isCorrect: false
			},
			{
				answerText: 'Junio Hamano',
				isCorrect: false
			},
			{
				answerText: 'Kohsuke Kawaguchi',
				isCorrect: true
			},
			{
				answerText: 'James Gosling',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText: 'Which source code management plug-in does Jenkins install by default?',
		answerOptions: [
			{
				answerText: 'Git',
				isCorrect: false
			},
			{
				answerText: 'Subversion',
				isCorrect: false
			},
			{
				answerText: 'Concurrent Versions System',
				isCorrect: false
			},
			{
				answerText: 'None',
				isCorrect: true
			}
		]
	},
	{
		id: 5,
		questionText:
			'Which environment variable do you optionally use to specify the folder in which Jenkins configuration and runtime data is contained?',
		answerOptions: [
			{
				answerText: 'JENKINS_ROOT',
				isCorrect: false
			},
			{
				answerText: 'M2_HOME',
				isCorrect: false
			},
			{
				answerText: 'JENKINS_HOME',
				isCorrect: true
			},
			{
				answerText: 'JAVA_HOME',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText:
			'During installation, the wizard recommends a set of Jenkins plug-ins. Which of the following Jenkins plug-ins is not recommended?',
		answerOptions: [
			{
				answerText: 'Maven',
				isCorrect: true
			},
			{
				answerText: 'Git',
				isCorrect: false
			},
			{
				answerText: 'Gradle',
				isCorrect: false
			},
			{
				answerText: 'Ant',
				isCorrect: false
			}
		]
	},
	{
		id: 7,
		questionText: 'What embedded runtime does the stand-alone Jenkins application use?',
		answerOptions: [
			{
				answerText: 'Tomcat',
				isCorrect: false
			},
			{
				answerText: 'WebSphere Liberty',
				isCorrect: false
			},
			{
				answerText: 'WildFly',
				isCorrect: false
			},
			{
				answerText: 'Jetty',
				isCorrect: true
			}
		]
	},
	{
		id: 8,
		questionText: 'What is the profession of the Jenkins mascot?',
		answerOptions: [
			{
				answerText: 'Driver',
				isCorrect: false
			},
			{
				answerText: 'Butler',
				isCorrect: true
			},
			{
				answerText: 'Groundskeeper',
				isCorrect: false
			},
			{
				answerText: 'Chef',
				isCorrect: false
			}
		]
	},
	{
		id: 9,
		questionText:
			'When you install Jenkins without any plug-ins configured, what is the only type of Jenkins build job you can create?',
		answerOptions: [
			{
				answerText: 'Maven project',
				isCorrect: false
			},
			{
				answerText: 'Pipeline project',
				isCorrect: false
			},
			{
				answerText: 'Freestyle project',
				isCorrect: true
			},
			{
				answerText: 'Multiconfiguration project',
				isCorrect: false
			}
		]
	}
];

export default questions;

const questions = [
	{
		caption: 'Write a python program to reverse a number',
		questionText: `
    # Hint:
    # write a function that takes a number n as argument
    # return the reverse of the number

    number = 1234
    def reverse_number(n):
        
  # Output:
  # reverse_number : 4321
  `,
		answerFunction: `
    # Hint:
    # write a function that takes a number n as argument
    # return the reverse of the number

    number = 1234
    def reverse_number(n):
    num = str(n)[::-1]
    return int(num)

    print("reverse_number:", reverse_number(number))
        
    # Output:
    # reverse_number : 4321
    `
	},
	{
		caption: 'Write a python program for palindrome number using iterative method',
		questionText: `     
    number = 121
    def pall(number):
        
    # Output:
    # number is palindrome
        `,
		answerFunction: `
    number = 121
    def pall(number):
        reverse,temp = 0,number
        while temp!=0:
            reverse = reverse*10 + temp%10        
            temp=temp//10
        if reverse==number:
            return "number is palindrome"
        else:
            return "number is not palindrome"
    
    print(pall(number))
        
        # Output:
        # number is palindrome
        `
	},
	{
		caption: 'Count the occurrences of mon in the list',
		questionText: `     
    weekdays = ['sun','mon','tue','wed','thu','fri','sun','mon','mon']
    def countX(lst, x):
    
    # Output:
    # mon occurs 3 times in the list
    `,
		answerFunction: `
    weekdays = ['sun','mon','tue','wed','thu','fri','sun','mon','mon']
    def countX(lst, x):
    count = 0
    for ele in lst:
        if (ele == x):
            count = count + 1
    return f'{x} occurs {count} times in the list'

    print(countX(weekdays, 'mon'))
        
    # Output:
    # mon occurs 3 times in the list
    `
	},
	{
		caption:
			'Write a Python program find a list of integers with exactly two occurrences of nineteen and at least three occurrences of five.',
		questionText: `
    nums = [19,19,15,5,3,5,5,2]
    def test(nums):

    # Output:
    # Check Occurrence: False
        `,
		answerFunction: `
    nums = [19,19,15,5,3,5,5,2]

    def test(nums):
        return nums.count(9) == 2 and nums.count(5) >= 3
    print("Check Occurrence:", test(nums))
        
    # Output:
    # Check Occurrence: False
    `
	},
	{
		caption: 'Write a program to find the sum of the digits of a number',
		questionText: `     
    number = 1928
    def num_sum(number):
        
    #Output:
    #The total sum of digits is: 20
        `,
		answerFunction: `
    number = 1928
    def num_sum(number):
        tot=0
        while(number>0):
            dig=number%10
            tot=tot+dig
            number=number//10
        return f"The total sum of digits is: {tot}"
    
    print(num_sum(number))
        
    # Output:
    # The total sum of digits is: 20
        `
	},
	{
		caption: 'Write a Python Program to Count the Number of Digits in a Number',
		questionText: `     
    number = 14325
    def digit_num(number):
  
    # Output:
    # The number of digits in the number is: 5
    `,
		answerFunction: `
    number = 14325
    def digit_num(number):
    count=0
    while(number>0):
        count=count+1
        number=number//10
    return f"The number of digits in the number is: {count}"

print(digit_num(number))
        
    # Output:
    # The number of digits in the number is: 5
        `
	},
	{
		caption: 'Write a Python Program to Check if a Number is a Prime Number',
		questionText: `     
    number = 7
    def prime_num(number):
        
        # Output:
        # Number is prime        `,
		answerFunction: `
    number = 7
    def prime_num(number):
        k=0
        for i in range(2,number//2+1):
            if(number%i==0):
                k=k+1
            if(k>=0):
                return "Number is prime"
            else:
                return "Number isn't prime"
    
    print(prime_num(number))

        # Output:
        # Number is prime        `
	},
	{
		caption: 'Write a Python Program to Count the Number of Vowels in a String',
		questionText: `     
    string="Hello world"
    def countvowels(string):
        
    # Output:
    # Number of vowels are: 3
    `,
		answerFunction: `
    string="Hello world"

    def countvowels(string):
        num_vowels=0
        for char in string:
            if char in "aeiouAEIOU":
               num_vowels = num_vowels+1
        return num_vowels
    print("Number of vowels are:", countvowels(string))
    

    # Output:
    # Number of vowels are: 3
    `
	},
	{
		caption: 'Write a Python Program to Check Common Letters in Two Input Strings',
		questionText: `     
    string="Hello"
    string2="How are you"
    
    def checkCommonLetters(a, b):
        
    # Output:
    # The common letters are:
    # H
    # o
    # e
                `,
		answerFunction: `
    string="Hello"
    string2="How are you"
    
    def checkCommonLetters(a, b):
        x=list(set(a)&set(b))
        print("The common letters are:")
        for i in x:
            print(i)
    print(checkCommonLetters(string, string2))
    

    # Output:
    # The common letters are:
    # H
    # o
    # e
    `
	},
	{
		caption: 'Write a Python program to calculate the factorial using iterative approach',
		questionText: `     
    num=8
    factorial = 1
    def calFactorial(a, b):
        
    # Output:
    # ('Factorial of', 8, 'is', 40320)
                `,
		answerFunction: `
    num=8
    factorial = 1
    
    def calFactorial(a, b):
        if a < 0:
            return "Factorial can't be calculated for negative number"
        elif a == 0:
           return "Factorial of 0 is 1"
        else:
            for i in range(1,a + 1):
                b = b*i
            return "Factorial of", a, "is", b
    print(calFactorial(num, factorial))
    

    # Output:
    # ('Factorial of', 8, 'is', 40320)
    `
	},
	{
		caption: 'Write a Python program to remove character from string',
		questionText: `
    s1 = "There is a man at the party"
    s2 = "a"

    def remove_char(s1,s2):
        
    # Output:
    # There is  mn t the prty
                `,
		answerFunction: `
    s1 = "There is a man at the party"
    s2 = "a"
    
    def remove_char(s1,s2):
        print(s1.replace(s2, ''))
    remove_char(s1,s2)

    # Output:
    # There is  mn t the prty
    `
	},
	{
		caption: 'Write a Python Program to reverse an array(list) using while loop Python',
		questionText: `     
    arr=[10, 20, 30, 40, 50, 60]
    
    def reverseList(arr):

    # Output:
    # [60, 50, 40, 30, 20, 10]
                `,
		answerFunction: `
    arr=[10, 20, 30, 40, 50, 60]

    def reverseList(arr):
        idx = len(arr) - 1
        newList = []
        
        while (idx >= 0):
            newList.append(arr[idx])
            idx = idx - 1
        return newList
    print(reverseList(arr))    

    # Output:
    # [60, 50, 40, 30, 20, 10]
    `
	},
	{
		caption: 'Write a Python Program to fnd the strings in a list containing a given substring',
		questionText: `     
    strs =  ['cat', 'car', 'fear', 'center']
    substrs = 'ca'
    def test(strs, substr):
        
    # Output:
    # Original strings: ['cat', 'car']
                `,
		answerFunction: `
    strs =  ['cat', 'car', 'fear', 'center']
    substrs = 'ca'
    
    def test(strs, substr):
        return [s for s in strs if substr in s]
    print("Original strings:", test(strs, substrs))    

    # Output:
    # Original strings: ['cat', 'car']
    `
	},
	{
		caption: `Write a Python Program to determine the direction ('increasing' or 'decreasing') of monotonic sequence numbers`,
		questionText: `     
    nums = [1,2,3,4,5,6]
    def test(nums):
        
    # Output:
    # Original list: [1, 2, 3, 4, 5, 6]
    Direction: Increasing
                `,
		answerFunction: `
    nums = [1,2,3,4,5,6]

    def test(nums):
        return "Increasing" if all(nums[i] < nums[i + 1] for i in range(len(nums) - 1)) else \
            "Decreasing" if all(nums[i + 1] < nums[i] for i in range(len(nums) - 1)) else \
            "Not a monotonic sequence!"
    print("Original list:", nums)
    print("Direction:", test(nums))

    # Output:
    # Original list: [1, 2, 3, 4, 5, 6]
    Direction: Increasing
    `
	},
	{
		caption:
			'Write a Python program to Filter for the numbers in numbers in a given list whose sum of digits is > 0, where the first digit can be negative',
		questionText: `     
    nums =  [1, 7, -4, 4, -9, 2]
    def test(nums):
        
    # Output:
    # Original list: [1, 7, -4, 4, -9, 2]
    # [1, 7, 4, 2]
                `,
		answerFunction: `
    nums =  [1, 7, -4, 4, -9, 2]
    def test(nums):
        return [n for n in nums if int(str(n)[:2]) + sum(map(int, str(n)[2:])) > 0]
    print("Original list:", nums)
    print(test(nums))

    # Output:
    # Original list: [1, 7, -4, 4, -9, 2]
    # [1, 7, 4, 2]
    `
	},
	{
		caption:
			' Write a Python program to find the h-index, the largest positive number h such that h occurs in the sequence at least h times. If there is no such positive number return h = -1',
		questionText: `     
    nums =  [1, 2, 2, 3, 3, 4, 4, 4, 4]
    def test(nums):
        
    # Output:
    # Original list of numbers: [1, 2, 2, 3, 3, 4, 4, 4, 4]
    # 4
  `,
		answerFunction: `
    nums =  [1, 2, 2, 3, 3, 4, 4, 4, 4]
    def test(nums):
        return max([-1] + [i for i in nums if i > 0 and nums.count(i) >= i])
    
    print("Original list of numbers:", nums)
    print(test(nums))

    # Output:
    # Original list of numbers: [1, 2, 2, 3, 3, 4, 4, 4, 4]
    # 4
    `
	},
	{
		caption:
			'Write a Python program to find the even-length words from a given list of words and sort them by length',
		questionText: `     
    words = ["Red", "Black", "White", "Green", "Pink", "Orange"]
    
    def test(nums):

    # Output:
    # Original list of words: ['Red', 'Black', 'White', 'Green', 'Pink', 'Orange']
    # ['Pink', 'Orange']
                `,
		answerFunction: `
words = ["Red", "Black", "White", "Green", "Pink", "Orange"]
def test(nums):
     return sorted([w for w in words if len(w) % 2 == 0], key=lambda w: (len(w), w))
 
print("Original list of words:", words)
print(test(words))

    # Output:
    # Original list of words: ['Red', 'Black', 'White', 'Green', 'Pink', 'Orange']
    # ['Pink', 'Orange']
    `
	},
	{
		caption: 'Write a Python program to remove duplicates from a list of integers, preserving order',
		questionText: `     
    nums = [1, 3, 4, 10, 4, 1, 4, 63]
    def test(nums):

    # Output:
    # Original list of numbers: [1, 3, 4, 10, 4, 1, 4, 63]
    # [1, 3, 4, 10, 63]
                `,
		answerFunction: `
    nums = [1, 3, 4, 10, 4, 1, 4, 63]
    def test(nums):
        return list(dict.fromkeys(nums)) 
    print("Original list of numbers:", nums)
    print(test(nums))

    # Output:
    # Original list of numbers: [1, 3, 4, 10, 4, 1, 4, 63]
    # [1, 3, 4, 10, 63]
    `
	},
	{
		caption:
			'Write a Python program to find the numbers that are greater than 10 and have odd first and last digits',
		questionText: `     
    nums = [1, 3, 79, 10, 4, 1, 39]
    def test(nums):

    # Output:
    # Original list of numbers: [1, 3, 79, 10, 4, 1, 39]
    # [79, 39]
                `,
		answerFunction: `
    nums = [1, 3, 79, 10, 4, 1, 39]
    def test(nums):
          return [x for x in nums if x > 10 and x % 10 % 2 and int(str(x)[0]) % 2]
      
    print("Original list of numbers:", nums)
    print(test(nums))

    # Output:
    # Original list of numbers: [1, 3, 79, 10, 4, 1, 39]
    # [79, 39]
    `
	},
	{
		caption:
			'A valid filename should end in .txt, .exe, .jpg, .png, or .dll, and should have at most three digits, no additional periods. Write a Python program to create a list of True/False that determine whether candidate filename is valid or not.',
		questionText: `     
    file_names = ['abc.txt', 'windows.dll', 'tiger.png', 'rose.jpg', 'test.py', 'win32.exe']
    def test(file_names):

    # Output:
    # Original list of files: ['abc.txt', 'windows.dll', 'tiger.png', 'rose.jpg', 'test.py', 'win32.exe']
    # Valid filenames: ['Yes', 'Yes', 'Yes', 'Yes', 'No', 'Yes']
    > 
                `,
		answerFunction: `
    file_names = ['abc.txt', 'windows.dll', 'tiger.png', 'rose.jpg', 'test.py', 'win32.exe']
    def test(file_names):
             return ["Yes" if
                f.split(".")[1:] in [['txt'], ['png'], ['dll'], ['exe'], ['jpg']] and f[0].isalpha() and sum(c.isdigit() for c in f) < 4
                else "No"
                for f in file_names]
     
    print("Original list of files:", file_names)
    print("Valid filenames:", test(file_names))

    # Output:
    # Original list of files: ['abc.txt', 'windows.dll', 'tiger.png', 'rose.jpg', 'test.py', 'win32.exe']
    # Valid filenames: ['Yes', 'Yes', 'Yes', 'Yes', 'No', 'Yes']
    > 
    `
	},
	{
		caption: 'Write a Python program to check whether an integer greater than 4^4 which is 4 mod 34',
		questionText: `     
    n = 922
    def test(n):

    # Output:
    # Original Integer: 922
    # True
     `,
		answerFunction: `
    n = 922
    def test(n):
        return n % 34 == 4 and n > 4 ** 4
    print("Original Integer:", n)
    print(test(n))

    # Output:
    # Original Integer: 922
    # True
        `
	},
	{
		caption: 'Write a Python program to find the number of stones in each pile.',
		questionText: `     
    n = 2
    def test(n):

    # Output:
    # Number of piles: 2
    # Number of stones in each pile: [2, 4]
                `,
		answerFunction: `
    n = 2
    def test(n):    
        return [n + 2 * i for i in range(n)]   
    print("Number of piles:", n)
    print("Number of stones in each pile:", test(n))

    # Output:
    # Number of piles: 2
    # Number of stones in each pile: [2, 4]
    `
	},
	{
		caption: 'Write a Python program to check a given list of integers where the sum of the first i integers is i',
		questionText: `     
    nums = [0,1,2,3,4,5]
    def test(nums):

    # Output:
    # Original list: [0, 1, 2, 3, 4, 5]
    # False
                `,
		answerFunction: `
    nums = [0,1,2,3,4,5]

    def test(nums):
        return all([sum(nums[:i]) == i for i in range(len(nums))])
    print("Original list:", nums)
    print(test(nums))

    # Output:
    # Original list: [0, 1, 2, 3, 4, 5]
    # False
    `
	},
	{
		caption:
			'Write a Python program to split a string of words separated by commas and spaces into two lists, words and separators',
		questionText: `     
    def test(string):
    import re

    # Output:
    # [['W3resource.', 'Python'], [' ']]
                `,
		answerFunction: `
    def test(string):
    import re
    merged = re.split(r"([ ,]+)", string)
    return [merged[::2], merged[1::2]]
    
    string = "W3resource. Python"
    print(test(string))

    # Output:
    # [['W3resource.', 'Python'], [' ']]
    `
	},
	{
		caption: 'Write a Python program to find the indexes of numbers of a given list below a given threshold',
		questionText: `     
    nums=[0, 12, 45, 3, 4923, 322, 105, 29, 15, 39, 55]
    thresh = 100
    def test(nums, n):

    # Output:
    # Original list: [0, 12, 45, 3, 4923, 322, 105, 29, 15, 39, 55]
    # Threshold:  100
  `,
		answerFunction: `
    nums=[0, 12, 45, 3, 4923, 322, 105, 29, 15, 39, 55]
    thresh = 100
    def test(nums, n):
        return [i for i,n in enumerate(nums) if n<thresh]
    
    print("Original list:", nums)
    print("Threshold: ",thresh)

    # Output:
    # Original list: [0, 12, 45, 3, 4923, 322, 105, 29, 15, 39, 55]
    # Threshold:  100
    `
	},
	{
		caption: 'Write a Python program to check whether the given strings are palindromes or not. Return True, False',
		questionText: `     
    arr = ['palindrome', 'madamimadam', '', 'foo', 'eyes']
    def test(arr):

    # Output:
    # Original strings: ['palindrome', 'madamimadam', '', 'foo', 'eyes']
    # [False, True, True, False, False]
  `,
		answerFunction: `
    arr = ['palindrome', 'madamimadam', '', 'foo', 'eyes']
    def test(arr):
        return [s == s[::-1] for s in arr]
        
    print("Original strings:", arr)
    print(test(arr))

    # Output:
    # Original strings: ['palindrome', 'madamimadam', '', 'foo', 'eyes']
    # [False, True, True, False, False]
  `
	},
	{
		caption: 'Write a Python program to find the lengths of a given list of non-empty strings',
		questionText: `     
    strs =  ['cat', 'car', 'fear', 'center']
    def test(strs):

    # Output:
    # Original strings: ['cat', 'car', 'fear', 'center']
    # [3, 3, 4, 6]
                    `,
		answerFunction: `
    strs =  ['cat', 'car', 'fear', 'center']
    def test(strs):
         return [*map(len, strs)]
    print("Original strings:", strs)
    print(test(strs))

    # Output:
    # Original strings: ['cat', 'car', 'fear', 'center']
    # [3, 3, 4, 6]    `
	},
	{
		caption: 'Write a Python program find the longest string of a given list of strings',
		questionText: `     
    strs =  ['cat', 'car', 'fear', 'center']
    def test(words):

    # Output:
    # Original strings: ['cat', 'car', 'fear', 'center']
    # center
    `,
		answerFunction: `
    strs =  ['cat', 'car', 'fear', 'center']
    def test(words):
        return  max(words, key=len) 
    print("Original strings:", strs)
    print(test(strs))

    # Output:
    # Original strings: ['cat', 'car', 'fear', 'center']
    # center    
    `
	},
	{
		caption: 'Write a Python program find the strings in a given list containing a given substring',
		questionText: `     
    strs =  ['cat', 'car', 'fear', 'center']
    substrs = 'ca'
    def test(strs, substr):

    # Output:
    # Original strings: ['cat', 'car', 'fear', 'center']
    # ['cat', 'car']
    `,
		answerFunction: `
    strs =  ['cat', 'car', 'fear', 'center']
    substrs = 'ca'
    def test(strs, substr):
        return [s for s in strs if substr in s]
    
    print("Original strings:", strs)
    print(test(strs, substrs))

    # Output:
    # Original strings: ['cat', 'car', 'fear', 'center']
    # ['cat', 'car']    
    `
	},
	{
		caption: 'Write a Python program to create string consisting of the non-negative integers up to n inclusive',
		questionText: `     
    n = 7
    def test(n):

    # Output:
    # Non-negative integer: 7
    # 0 1 2 3 4 5 6 7
    `,
		answerFunction: `
    n = 7
    def test(n):
    return ' '.join(map(str,range(n+1)))

    print("Non-negative integer:", n)
    print(test(n))

    # Output:
    # Non-negative integer: 7
    # 0 1 2 3 4 5 6 7
    `
	},
	{
		caption: 'Write a Python program to find the indices of all occurrences of target in the uneven matrix',
		questionText: `
    # Hint: An irregular/uneven matrix, or ragged matrix, is a matrix that has a different number of elements in each row. Ragged matrices are not used in linear algebra, since standard matrix transformations cannot be performed on them, but they are useful as arrays in computing

    M = [[1, 3, 2, 32, 19], [19, 2, 48, 19], [], [9, 35, 4], [3, 19]]
    T = 19
    def test(M, T):

    # Output:
    # Matrix: [[1, 3, 2, 32, 19], [19, 2, 48, 19], [], [9, 35, 4], [3, 19]]
    # Target value: 19
    # [[0, 4], [1, 0], [1, 3], [4, 1]]
                    `,
		answerFunction: `
    # Hint: An irregular/uneven matrix, or ragged matrix, is a matrix that has a different number of elements in each row. Ragged matrices are not used in linear algebra, since standard matrix transformations cannot be performed on them, but they are useful as arrays in computing

    M = [[1, 3, 2, 32, 19], [19, 2, 48, 19], [], [9, 35, 4], [3, 19]]
    T = 19
    def test(M, T):
        return [[i, j] for i, row in enumerate(M) for j, n in enumerate(row) if n == T]

    print("Matrix:", M)
    print("Target value:", T)
    print(test(M,T))

    # Output:
    # Matrix: [[1, 3, 2, 32, 19], [19, 2, 48, 19], [], [9, 35, 4], [3, 19]]
    # Target value: 19
    # [[0, 4], [1, 0], [1, 3], [4, 1]]
    `
	},
	{
		caption: 'Write a Python program to find the XOR of two given strings interpreted as binary numbers',
		questionText: `
    # Hint: XOR represents the inequality function, i.e., the output is true if the inputs are not alike otherwise the output is false. A way to remember XOR is "must have one or the other but not both". XOR can also be viewed as addition modulo 2. As a result, XOR gates are used to implement binary addition in computers.
    
    nums =  ["0001", "1011"]
    def test(nums):

    # Output:
    # Original strings: ['0001', '1011']
    # 0b1010      
              `,
		answerFunction: `
    # Hint: XOR represents the inequality function, i.e., the output is true if the inputs are not alike otherwise the output is false. A way to remember XOR is "must have one or the other but not both". XOR can also be viewed as addition modulo 2. As a result, XOR gates are used to implement binary addition in computers.

    nums =  ["0001", "1011"]
    def test(nums):
      return bin(int(nums[0],2) ^ int(nums[1],2))
    print("Original strings:", nums)
    print(test(nums))

    # Output:
    # Original strings: ['0001', '1011']
    # 0b1010
    `
	},
	{
		caption: 'Write a Python program to find the largest number where commas or periods are decimal points',
		questionText: `     
    arr = ["100", "102,1", "101.1"]
    def test(arr):

    # Output:
    # Original list: ['100', '102,1', '101.1']
    # 102.1
    `,
		answerFunction: `
    arr = ["100", "102,1", "101.1"]
    def test(arr):
      return max(float(s.replace(",", ".")) for s in arr)
      print("Original list:" arr)
      print(test(arr))

    # Output:
    # Original list: ['100', '102,1', '101.1']
    # 102.1
    `
	},
	{
		caption:
			'WritWrite a Python program to find the list of strings that has fewer total characters (including repetitions)e a Python program to remove duplicates from a list of integers, preserving order',
		questionText: `     
    strs = [['this', 'list', 'is', 'narrow'], ['I', 'am', 'shorter but wider']]
    def test(strs):

    # Output:
    # Original List: [['this', 'list', 'is', 'narrow'], ['I', 'am', 'shorter but wider']]
    # ['this', 'list', 'is', 'narrow']
                    `,
		answerFunction: `
    strs = [['this', 'list', 'is', 'narrow'], ['I', 'am', 'shorter but wider']]
    def test(strs):
      return min(strs, key=lambda x: sum(len(i) for i in x)) 
    print("Original List:", strs)
    print(test(strs))

    # Output:
    # Original List: [['this', 'list', 'is', 'narrow'], ['I', 'am', 'shorter but wider']]
    # ['this', 'list', 'is', 'narrow']
    `
	},
	{
		caption: 'Write a Python program to sort the numbers of a given list by the sum of their digits',
		questionText: `     
    nums = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    def test(nums):

    # Output:
    # Original list of numbers: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    # [10, 11, 20, 12, 13, 14, 15, 16, 17, 18, 19]
    
    `,
		answerFunction: `
    nums = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    def test(nums):
      return sorted(nums, key=lambda n: sum(int(c) for c in str(n) if c != "-"))

    print("Original list of numbers:",nums)
    print(test(nums))

    # Output:
    # Original list of numbers: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    # [10, 11, 20, 12, 13, 14, 15, 16, 17, 18, 19]
    `
	},
	{
		caption: 'Write a Python program to determine which triples sum to zero from a given list of lists',
		questionText: `     
    nums = [[1343532, -2920635, 332], [-27, 18, 9], [4, 0, -4], [2, 2, 2], [-20, 16, 4]]
    def test(nums):

    # Output:
    # Original list of lists: [[1343532, -2920635, 332], [-27, 18, 9], [4, 0, -4], [2, 2, 2], [-20, 16, 4]]
    # [False, True, True, False, True]
                    `,
		answerFunction: `
    nums = [[1343532, -2920635, 332], [-27, 18, 9], [4, 0, -4], [2, 2, 2], [-20, 16, 4]]
    def test(nums):
    return [sum(t)==0 for t in nums]
    print("Original list of lists:",nums)
    print(test(nums))

    # Output:
    # Original list of lists: [[1343532, -2920635, 332], [-27, 18, 9], [4, 0, -4], [2, 2, 2], [-20, 16, 4]]
    # [False, True, True, False, True]
  `
	},
	{
		caption: 'Write a Python program to sort numbers based on strings',
		questionText: `     
    strs = "six one four one two three"
    def test(strs):

    # Output:
    # Original string: six one four one two three
    # one two three four six
  `,
		answerFunction: `
    strs = "six one four one two three"
    def test(strs):
     return ' '.join([x for x in 'one two three four five six seven eight nine'.split() if x in strs])
    print("Original string:",strs)
    print(test(strs))

    # Output:
    # Original string: six one four one two three
    # one two three four six
  `
	},
	{
		caption: 'Write a Python program to find the set of distinct characters in a given string, ignoring case',
		questionText: `     
    strs = "HELLO"
    def test(strs):

    # Output:
    # Original string: HELLO
    # ['h', 'l', 'o', 'e']
    `,
		answerFunction: `
    strs = "HELLO"
    def test(strs):
        return [*set(strs.lower())]
    print("Original string:",strs)
    print(test(strs))

    # Output:
    # Original string: HELLO
    # ['h', 'l', 'o', 'e']
  `
	},
	{
		caption: 'Write a Python program to find all words in a given string with n consonants',
		questionText: `     
    strs = "this is our time"
    n = 3
    def test(strs, n):

    # Output:
    # Original string: this is our time
    # ['this']
   `,
		answerFunction: `
    strs = "this is our time"
    n = 3
    def test(strs, n):
        return [w for w in strs.split() if sum([c not in "aeiou" for c in w.lower()]) == n]
    print("Original string:",strs)
    print(test(strs, n))

    # Output:
    # Original string: this is our time
    # ['this']
    `
	},
	{
		caption: 'Write a Python program to remove duplicates from a list of integers, preserving order',
		questionText: `     
    n = 50
    def test(n):

    # Output:
    # Even palindromes up to 50
    # [0, 2, 4, 6, 8, 22, 44]
    `,
		answerFunction: `
    n = 50
    def test(n):
        return [i for i in range(0,n,2) if str(i) == str(i)[::-1]] 
    print("Even palindromes up to",n)
    print(test(n))

    # Output:
    # Even palindromes up to 50
    # [0, 2, 4, 6, 8, 22, 44]
    `
	}
];

export default questions;

import React from 'react';
import './login.css';
import Nav from './nav';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import loginImage from '../../assets/images/signup_bg_image/login image.png';

function ForgotPassword(props) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
  });

  //Forgot Password
  const handleOnSubmit = async (data) => {
    var config = {
      method: 'post',
      url: 'https://28u546ort6.execute-api.us-east-1.amazonaws.com/Prod/user/forgotpassword',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        username: data.email,
      },
    };
    sessionStorage.setItem('username', data.email);
    await axios(config)
      .then(function (response) {
        toast.success('Reset Password request is successful', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });

        setTimeout(() => {
          props.setNewPwd(true);
        }, 1000);
      })
      .catch(function (error) {
        const TotalerrorMessage = error.response.data.errorMessage.split(':');
        console.log(TotalerrorMessage[2]);
        const errorMessage = TotalerrorMessage[2];
        toast.error(errorMessage.slice(0, errorMessage.length - 2), {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });
      });
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Nav />

      <section className="login-parent">
        <div className="login-form">
          <form
            action="POST"
            method="post"
          >
            <h1>Forgot Password?</h1>
            <p>
              Enter your email address and we'll send you a link to reset your
              password.
            </p>

            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              autoComplete="off"
              placeholder="you@email.com"
              {...register('email', {
                required: 'Enter email address',
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: 'Please enter a valid email address',
                },
              })}
            />
            {errors.email && (
              <p className="error-message">{errors.email.message}</p>
            )}
            <br />

            <button
              type="submit"
              onClick={handleSubmit(handleOnSubmit)}
            >
              Continue
            </button>

            <p className="no_account">
              Return to <a href="/login">Log in</a>
            </p>
          </form>

          <h4>&copy; iLearn Cloud {new Date().getFullYear()}</h4>
        </div>

        <div className="login-image-parent">
          <img
            src={loginImage}
            alt="iLearn Cloud"
          />
        </div>
      </section>
    </React.Fragment>
  );
}

export default ForgotPassword;

import axios from 'axios';

// Get Video Paths
export const getVideo = () => {
  var data = JSON.stringify({
    method: 'paid_videos',
    email: localStorage.getItem('email'),
    location: localStorage.getItem('currentLocation'),
  });

  var config = {
    method: 'post',
    // url: 'https://6etrkhwqh2.execute-api.eu-west-1.amazonaws.com/Prod',
    //url: 'https://r03cnyr1v4.execute-api.eu-west-1.amazonaws.com/testing/',
    url: 'https://zdya3cz47l.execute-api.eu-west-1.amazonaws.com/production/',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config);
};

// Create User in the Database
export const setVideo = (name, email) => {
  var data = JSON.stringify({
    method: 'new_user',
    email: email,
    //name: name
  });

  var config = {
    method: 'post',
    //url: 'https://1lcbu3h4p7.execute-api.eu-west-1.amazonaws.com/Prod/trainingapp',
    // url: 'https://6etrkhwqh2.execute-api.eu-west-1.amazonaws.com/Prod',
    //url: 'https://r03cnyr1v4.execute-api.eu-west-1.amazonaws.com/testing/',
    url: 'https://zdya3cz47l.execute-api.eu-west-1.amazonaws.com/production/',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config)
    .then(() => {
      console.log('user');
    })
    .catch(() => {
      console.log('error');
    });
};

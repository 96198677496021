const questions = [
	{
		id: 1,
		questionText: 'What is action in Spark RDD?',
		answerOptions: [
			{
				answerText: 'The ways to send results from executors to the driver',
				isCorrect: true
			},
			{
				answerText: 'Takes RDD as input and produces one or more RDD as output',
				isCorrect: false
			},
			{
				answerText: 'Creates one or many new RDDs',
				isCorrect: false
			},
			{
				answerText: 'All of the Above',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText: 'Which of the following is true about narrow transformation?',
		answerOptions: [
			{
				answerText: 'The data required to compute resides on multiple partitions',
				isCorrect: false
			},
			{
				answerText: 'The data required to compute resides on a single partitions',
				isCorrect: true
			},
			{
				answerText: 'Both of the Above',
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: 'When we want to work with the actual dataset, at that point we use Transformation?',
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: false
			},
			{
				answerText: 'False',
				isCorrect: true
			}
		]
	},
	{
		id: 4,
		questionText: 'The shortcomings of Hadoop MapReduce was overcome by Spark RDD by',
		answerOptions: [
			{
				answerText: 'Lazy-evaluation',
				isCorrect: false
			},
			{
				answerText: 'DAG',
				isCorrect: false
			},
			{
				answerText: 'In memory processing',
				isCorrect: false
			},
			{
				answerText: 'All of the Above',
				isCorrect: true
			}
		]
	},
	{
		id: 5,
		questionText: 'What does Spark Engine do?',
		answerOptions: [
			{
				answerText: 'Sheduling',
				isCorrect: false
			},
			{
				answerText: 'Distributing data across a cluster',
				isCorrect: false
			},
			{
				answerText: 'Monitoring data across a cluster',
				isCorrect: false
			},
			{
				answerText: 'All of the Above',
				isCorrect: true
			}
		]
	},
	{
		id: 6,
		questionText: 'Caching is optimizing the technique',
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: true
			},
			{
				answerText: 'False',
				isCorrect: false
			}
		]
	},
	{
		id: 7,
		questionText: 'Which of the following is the entry point of Spark Application',
		answerOptions: [
			{
				answerText: 'SparkSession',
				isCorrect: false
			},
			{
				answerText: 'SparkContext',
				isCorrect: true
			},
			{
				answerText: 'None of the Above',
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText: 'SparkContext guides how to access the Spark cluster',
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: true
			},
			{
				answerText: 'False',
				isCorrect: false
			}
		]
	},
	{
		id: 9,
		questionText: 'Which of the following is the entry point of Spark SQL?',
		answerOptions: [
			{
				answerText: 'SparkSession',
				isCorrect: true
			},
			{
				answerText: 'SparkContext',
				isCorrect: false
			}
		]
	},
	{
		id: 10,
		questionText: 'Which of the following is open-source?',
		answerOptions: [
			{
				answerText: 'Apache Spark',
				isCorrect: false
			},
			{
				answerText: 'Apache Hadoop',
				isCorrect: false
			},
			{
				answerText: 'Apache Flink',
				isCorrect: false
			},
			{
				answerText: 'All of the above',
				isCorrect: true
			}
		]
	},
	{
		id: 11,
		questionText: 'Apache Spark supports',
		answerOptions: [
			{
				answerText: 'Batch processing',
				isCorrect: false
			},
			{
				answerText: 'Stream processing',
				isCorrect: false
			},
			{
				answerText: 'Graph processing',
				isCorrect: false
			},
			{
				answerText: 'All of the Above',
				isCorrect: true
			}
		]
	},
	{
		id: 12,
		questionText: 'Which of the following is not true for map() Operation?',
		answerOptions: [
			{
				answerText: 'Map transforms an RDD of length N into another RDD of legnth N',
				isCorrect: false
			},
			{
				answerText: 'In the Map operation developer can define his own custom business logic',
				isCorrect: false
			},
			{
				answerText: 'It applies to each element of RDD and it returns the result as new RDD',
				isCorrect: false
			},
			{
				answerText: 'Map allows returning 0, 1 or more elements from map function',
				isCorrect: true
			}
		]
	},
	{
		id: 13,
		questionText: `FlatMap transforms an RDD of length N into another RDD of length M. Which of the following is true for M and N.
			a. N > M 
			b. N < M
		    c. N <= M`,
		answerOptions: [
			{
				answerText: 'Either a or b',
				isCorrect: false
			},
			{
				answerText: 'Either b or c',
				isCorrect: true
			},
			{
				answerText: 'Either a or c',
				isCorrect: false
			}
		]
	},
	{
		id: 14,
		questionText: 'Which of the following is a transformation?',
		answerOptions: [
			{
				answerText: 'take(n)',
				isCorrect: false
			},
			{
				answerText: 'top()',
				isCorrect: false
			},
			{
				answerText: 'countByValue()',
				isCorrect: false
			},
			{
				answerText: 'mapPartitionWithIndex()',
				isCorrect: 'true'
			}
		]
	},
	{
		id: 15,
		questionText: 'Which of the following is action?',
		answerOptions: [
			{
				answerText: 'Union(dataset)',
				isCorrect: false
			},
			{
				answerText: 'Intersection(other-dataset)',
				isCorrect: false
			},
			{
				answerText: 'Distinct()',
				isCorrect: false
			},
			{
				answerText: 'CountByValue()',
				isCorrect: true
			}
		]
	},
	{
		id: 16,
		questionText: 'In aggregate function can we get the data type different from as that input data type?',
		answerOptions: [
			{
				answerText: 'Yes',
				isCorrect: true
			},
			{
				answerText: 'No',
				isCorrect: false
			}
		]
	},
	{
		id: 17,
		questionText: 'In which of the following Action the result is not returned to the driver',
		answerOptions: [
			{
				answerText: 'collect()',
				isCorrect: false
			},
			{
				answerText: 'top()',
				isCorrect: false
			},
			{
				answerText: 'countByValue()',
				isCorrect: false
			},
			{
				answerText: 'foreach()',
				isCorrect: true
			}
		]
	},
	{
		id: 18,
		questionText: 'Which of the following is true for stateless transformation?',
		answerOptions: [
			{
				answerText:
					'Uses data or intermediate results from previous batches and computes the results of the current batch',
				isCorrect: false
			},
			{
				answerText: 'Windowed operations and updateStateBykey() are two types of Stateless transformation',
				isCorrect: false
			},
			{
				answerText: 'The processing of each batch has no dependency on the data of previous batches',
				isCorrect: true
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 19,
		questionText: 'Which of the following is true for stateful transformation?',
		answerOptions: [
			{
				answerText: 'The processing of each batch has no dependency on the data of previous batches',
				isCorrect: false
			},
			{
				answerText:
					'Uses data or intermediate results from previous batches and computes the result of the current batch',
				isCorrect: true
			},
			{
				answerText: 'Stateful transformation are simple RDD transformation',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	}
];

export default questions;

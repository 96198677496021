import React, { useState, useEffect } from 'react';
import './Subscription.css';
import axios from 'axios';
import Image from '../../../../assets/images/signup_bg_image/subscription image.png';

export default function Subscription({ username }) {
  const [region, setRegion] = useState([]);

  useEffect(() => {
    const getLocation = () => {
      const url =
        'https://pro.ip-api.com/json/?fields=status,country,currency&key=56mLGC31cRbT5rq';
      axios
        .get(url)
        .then((response) => {
          setRegion(response.data);
        })
        .catch((error) => {
          console.log(error.data);
        });
    };

    getLocation();
  }, []);

  let currency = (region) => {
    switch (region.country) {
      case 'Nigeria':
        return '₦' + 6700;
      case 'Kenya':
        return region.currency + 1800;
      case 'United Kingdom':
        return region.currency + 12.0;
      case 'Canada':
        return region.currency + 19.0;
      case 'South Africa':
        return region.currency + 241;
      case 'Rwanda':
        return region.currency + 12000;
      case 'Uganda':
        return region.currency + 50000;
      case 'Botswana':
        return region.currency + 170;
      case 'Zimbabwe':
        return region.currency + 4000;
      case 'Ghana':
        return region.currency + 119;
      default:
        return '$15';
    }
  };

  return (
    <div className="subscription-container">
      <section className="subscription-image-section">
        <img
          src={Image}
          alt="ilearn cloud"
        />
      </section>

      <section className="subscription-text-section">
        <h1>Welcome {username.split(' ').at(0)}. let's get you started</h1>
        <h3>Payment Method</h3>
        <h4>You will be charged {currency(region)} per month</h4>
        <p>
          Get Trained in Software Development, Cloud Computing, Data Science and
          More.
        </p>
        <a href="/subscription">Proceed to subscribe</a>
      </section>
    </div>
  );
}

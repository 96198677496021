const questions = [
  {
    caption: "Return the percentage of a number",
    questionText: `
        //Hint:
        // Write a function that takes two numbers (a and b) as argument
        // Return b percent of a
        
        function myFunction(a, b) {
          return
        }
        
        // Test Cases:
        myFunction(100, 50)  // 50
        myFunction(10, 2)  // 0.2
        `,
    answerFunction: `
        //Hint:
        // Write a function that takes two numbers (a and b) as argument
        // Return b percent of a
        
        function myFunction(a, b) {
          return (b / 100) * a
        }
        
        // Test Cases:
         myFunction(100, 50)  // 50
        myFunction(10, 2)  // 0.2
        `,
  },
  {
    caption: "Sum of two numbers",
    questionText: `
        // Hint:
        // Write a function that takes two numbers (a and b) as argument
        // and return the result

        function myFunction ( a, b ) {
            return
        }

        // Test Cases:
        myFunction(1, 2)  // 3
        myFunction(10, 3)  // 13
        
        `,
    answerFunction: `
        // Hint:
        // Write a function that takes two numbers (a and b) as argument
        // and return the result

        function myFunction ( a, b ) {
            return a + b
        }

        // Test Cases:
        myFunction(1, 2)  // 3
        myFunction(10, 3)  // 13
        `,
  },
  {
    caption: "Compare two numbers",
    questionText: `
        // Write a function that takes two values, say a and b, as arguments
        // Return true if the two values are equal and of the same type

        function myFunction ( a, b ) {
            return 
        }

        //Test Cases:
        myFunction(2, 3) // false
        myFunction(4, 4) // true
        `,
    answerFunction: `
        // Write a function that takes two values, say a and b, as arguments
        // Return true if the two values are equal and of the same type

        function myFunction ( a, b ) {
            return a === b
        }

        //Test Cases:
        myFunction(2, 3) // false
        myFunction(4, 4) // true
        `,
  },
  {
    caption: "Write a function that returns the Length of an Array",
    questionText: `
        // Write a function that takes an array (a) as argument
        // Return the number of elements in a

        function myFunction ( a ) {
            return 
        }

        //Test Cases:
        myFunction([1,2,2,4]) // 4
        myFunction([9, 4]) // 2
        `,
    answerFunction: `
        // Write a function that takes an array (a) as argument
        // Return the number of elements in a

        function myFunction ( a ) {
            return a.length
        }

        //Test Cases:
        myFunction([1,2,2,4]) // 4
        myFunction([9, 4]) // 2
        `,
  },
  {
    caption: "JavaScript Object: Get the property value",
    questionText: `
        // Write a function that takes an object with two properties as argument
        // It should return the value of the property with key country

        function myFunction ( obj ) {
            return 
        }

        //Test Cases:
        myFunction({  continent: 'Asia',  country: 'Japan'}) // Japan
        myFunction({  country: 'Sweden',  continent: 'Europe'}) // Sweden
        `,
    answerFunction: `
        // Write a function that takes an object with two properties as argument
        // It should return the value of the property with key country

        function myFunction ( obj ) {
            return obj.length
        }

        //Test Cases:
        myFunction({  continent: 'Asia',  country: 'Japan'}) // Japan
        myFunction({  country: 'Sweden',  continent: 'Europe'}) // Sweden`,
  },
  {
    caption: "JavaScript Array: Get first element",
    questionText: `
        // Create a function that takes an array containing only numbers and return the first element.

        function myFunction ( arr ) {
            return 
        }

        //Test Cases:
        myFunction([1, 2, 3]) // 1
        myFunction([80, 5, 100]) // 80
        `,
    answerFunction: `
        // Create a function that takes an array containing only numbers and return the first element.

        function myFunction ( arr ) {
            return  arr[0]
        }

        //Test Cases:
        myFunction([1, 2, 3]) // 1
        myFunction([80, 5, 100]) // 80
        `,
  },
  {
    caption: "JavaScript Array: Get first 3 element",
    questionText: `
        // Write a function that takes a string (a) as argument
        // Get the first 3 characters of a
        // Return the result

        function myFunction ( a ) {
            return 
        }

        //Test Cases:
        myFunction('abcdefg) // 'abc'
        myFunction('function) // 'fun'
        `,
    answerFunction: `
        // Write a function that takes a string (a) as argument
        // Get the first 3 characters of a
        // Return the result

        function myFunction ( a ) {
            return  a.slice(0, 3)
        }

        //Test Cases:
        myFunction('abcdefg) // 'abc'
        myFunction('function) // 'fun'
        `,
  },
  {
    caption: "JavaScript Type: Get type of element",
    questionText: `
        // Write a function that takes a value as argument
        // Return the type of the value

        function myFunction ( a ) {
            return 
        }

        //Test Cases:
        myFunction(2) // 'number'
        myFunction(true) // boolean
        `,
    answerFunction: `
        // Write a function that takes a value as argument
        // Return the type of the value

        function myFunction ( a ) {
            return typeof(a)
        }

        //Test Cases:
        myFunction(2) // 'number'
        myFunction(true) // boolean
        `,
  },
  {
    caption: "JavaScript Array: Get first n elements of an array",
    questionText: `
        // Write a function that takes an array (a) as argument
        // Extract the first 3 elements of a
        // Return the resulting array

        function myFunction ( a ) {
            return 
        }

        //Test Cases:
        myFunction([2, 3, 4, 5, 7]) // [2, 3, 4]
        myFunction([4, 4, 5, 9]) // [4, 4, 5]
        `,
    answerFunction: `
        // Write a function that takes an array (a) as argument
        // Extract the first 3 elements of a
        // Return the resulting array

        function myFunction ( a ) {
            return a.splice(0, 3)
        }

        //Test Cases:
        myFunction([2, 3, 4, 5, 7]) // [2, 3, 4]
        myFunction([4, 4, 5, 9]) // [4, 4, 5]
        `,
  },
  {
    caption: "Get nth character of string",
    questionText: `
        // Write a function that takes a string (a) and a number (n) as argument
        // Return the nth character of 'a'

        function myFunction ( a, n ) {
            return 
        }

        //Test Cases:
        myFunction('abcd',1) // 'a'
        myFunction('gfedcba',3) // 'e'
        `,
    answerFunction: `
        // Write a function that takes a string (a) and a number (n) as argument
        // Return the nth character of 'a'

        function myFunction ( a, n ) {
            return a[n - 1]
        }

        //Test Cases:
        myFunction('abcd',1) // 'a'
        myFunction('gfedcba',3) // 'e'
        `,
  },
  {
    caption: "JavaScript String: Reverse a String",
    questionText: `
        // Write a function that takes a string and a separator as argument
        // Return the reverse of the string with the joined separator

        function reverseBySeparator(string, separator) {
            return
          }

        //Test Cases:
        reverseBySeparator('abcd', '' ) // 'dcba'
        reverseBySeparator('gfedcba', '' ) // 'abcdefg'
        `,
    answerFunction: `
        // Write a function that takes a string and a separator as argument
        // Return the reverse of the string with the joined separator

        function reverseBySeparator(string, separator) {
            return string.split(separator).reverse().join(separator)
          }

        //Test Cases:
        reverseBySeparator('abcd', '' ) // 'dcba'
        reverseBySeparator('gfedcba', '' ) // 'abcdefg'
        `,
  },
  {
    caption: "JavaScript Array: Duplicate an Array",
    questionText: `
        // Write a function that takes an array as argument
        // Return a new array that is a duplicate of the array

        function duplicate(arr) {
            return
          }

        //Test Cases:
        duplicate([1, 2, 3]); // [1,2,3,1,2,3]
        duplicate([1, 2, 3, 4, 5]); // [1,2,3,4,5,1,2,3,4,5]
        `,
    answerFunction: `
        // Write a function that takes an array as argument
        // Return a new array that is a duplicate of the array

        function duplicate(arr) {
            return arr.concat(arr)
          }

        //Test Cases:
        duplicate([1, 2, 3]); // [1,2,3,1,2,3]
        duplicate([1, 2, 3, 4, 5]); // [1,2,3,4,5,1,2,3,4,5]        `,
  },
  {
    caption: "JavaScript Anagram",
    questionText: `
        // Write a function that takes two strings as argument
        // Return true if they are an anagrams of each other
        //Hint: convert to lowercase and then sort before comparing

        function isAnagram(first, second) {
            
            return
          }

        //Test Cases:
        isAnagram(best, test); // false
        isAnagram("Mary", "Army"); // true
        `,
    answerFunction: `
        // Write a function that takes two strings as argument
        // Return true if they are an anagrams of each other

        function isAnagram(first, second) {
            let a = first.toLowerCase();
            let b = second.toLowerCase();
          
            a = a.split("").sort().join("");
            b = b.split("").sort().join("");
          
            return a === b;
          }

        //Test Cases:
        isAnagram("best", "test"); // false
        isAnagram("Mary", "Army"); // true
      `,
  },
  {
    caption: "Merge the two Arrays and remove duplicates",
    questionText: `
        const firstArray = [5, 7, 9];
        const secondArray = [9, 8, 5, 10];
        function myFinalArray(a, b) {
          return
        }

        //Test Cases:
        // myFinalArray(firstArray, secondArray) // [ 5, 7, 9, 8, 10 ]
        `,
    answerFunction: `
        const firstArray = [5, 7, 9];
        const secondArray = [9, 8, 5, 10];
        function myFinalArray(a, b) {
          return [...new Set([...a ,...b])]
        }

        //Test Cases:
        // myFinalArray(firstArray, secondArray) // [ 5, 7, 9, 8, 10 ]
      `,
  },
  {
    caption: " Extract the first half of a string of even length",
    questionText: `
        // Write a function that takes a strings as argument
        // Extract the first half of the string
        //Return the result

        function myFunction (str) {
            return
          }

        //Test Cases:
        myFunction("Python"); // Pyt
        myFunction("Mary"); // Ma
        `,
    answerFunction: `
        // Write a function that takes a strings as argument
        // Extract the first half of the string
        //Return the result

        function myFunction (str) {
            if (str.length % 2 == 0) {
              return str.slice(0, str.length / 2);
            }
            return str
          }

        //Test Cases:
        myFunction("Python"); // Pyt
        myFunction("Mary"); // Ma
      `,
  },
  {
    caption: "Sorting strings alphabetically",
    questionText: `
        // Write a function that takes a string as argument
        // Sort the string characters alphabetically
        // Return the result

        function myFunction(str) {
            return 
          }

        //Test Cases:
        myFunction("cabbage") // aabbceg
        myFunction("party"); // aprty
        `,
    answerFunction: `
        // Write a function that takes a string as argument
        // Sort the string characters alphabetically
        // Return the result

        function myFunction(str) {
            return [...str].sort((a, b) => a.localeCompare(b)).join('')
          }

        //Test Cases:
        myFunction("cabbage") // aabbceg
        myFunction("party"); // aprty
      `,
  },
  {
    caption: "Sort an array of strings alphabetically",
    questionText: `
        // Write a function that takes an array of strings as argument
        // Sort the array elements alphabetically
        // Return the result

        function myFunction(arr) {
            return
          }
          
        //Test Cases:
        myFunction( ['č','é','A','b','Đ'] ) // ["A","b","é","č","Đ"]
        myFunction( ['z', 'c', 'd', 'a', 'y', 'a', 'w'] )  // ["a","a","c","d","w","y","z"]
        `,
    answerFunction: `
        // Write a function that takes an array of strings as argument
        // Sort the array elements alphabetically
        // Return the result

        function myFunction(arr) {
            return Array.from(arr).sort()
          }
          
        //Test Cases:
        myFunction( ['č','é','A','b','Đ'] ) // ["A","b","é","č","Đ"]
        myFunction( ['z', 'c', 'd', 'a', 'y', 'a', 'w'] )  // ["a","a","c","d","w","y","z"]
      `,
  },
  {
    caption: "Check if two dates are equal",
    questionText: `
      // Write a function that takes two date instances as arguments
      // It should return true if the dates are equal
      // It should return false otherwise

function myFunction(firstDate, secondDate) {
  return
}

        //Test Cases:
        myFunction(new Date(2015, 12, 15), new Date(2015, 12, 15)) // true
        myFunction(new Date(2015, 12, 15), new Date(2015, 12, 16)) // false
                `,
    answerFunction: `
    // Write a function that takes two date instances as arguments
    // It should return true if the dates are equal
    // It should return false otherwise

function myFunction(firstDate, secondDate) {
return firstDate.toISOString() === secondDate.toISOString();
}

      //Test Cases:
      myFunction(new Date(2015, 12, 15), new Date(2015, 12, 15)) // true
      myFunction(new Date(2015, 12, 15), new Date(2015, 12, 16)) // false
      `,
  },
  {
    caption: "Accessing object properties three",
    questionText: `
    // Write a function that takes an object with two properties and a string as arguments
    // It should return the value of the property with key equal to the value of the string
    
    function myFunction (obj, key) {
        return
    }
  
     //Test Cases:
     myFunction({  continent: 'Asia',  country: 'Japan'}, 'continent') //  'Asia'
     myFunction({  country: 'Sweden',  continent: 'Europe'}, 'country') // 'Sweden' 
      `,
    answerFunction: `
    // Write a function that takes an object with two properties and a string as arguments
    // It should return the value of the property with key equal to the value of the string
    
    function myFunction (obj, key) {
        return obj[key]
    }
  
     //Test Cases:
     myFunction({  continent: 'Asia',  country: 'Japan'}, 'continent') //  'Asia'
     myFunction({  country: 'Sweden',  continent: 'Europe'}, 'country') // 'Sweden' 

      `,
  },
  {
    caption: "Get nth element of array",
    questionText: `
    // Write a function that takes an array (arr) and a value (n) as argument
    // Return the nth element of 'n'

        function myFunction(arr, n) {
          return 
        }

        //Test Cases:
       myFunction(['a', 'b', 'c'], 1) // b
       myFunction(['a', 'b', 'b'], -3) // a
        `,
    answerFunction: `
    // Write a function that takes an array (arr) and a value (n) as argument
    // Return the nth element of 'n'

        function myFunction(arr, n) {
          return (n > 0 ? arr.slice(n, n + 1) : arr.slice(n))[0]
        }

        //Test Cases:
        myFunction(['a', 'b', 'c'], 1) // b
        myFunction(['a', 'b', 'b'], -3) // a
       `,
  },
  {
    caption: "Get the last N characters of a String",
    questionText: `
        // Write a function that takes a string as argument
        // Return the last 3 letters

        function myFunction(str) {
          return
       }

        //Test Cases:
        myFunction("hello world"); // rld
        myFunction("Mary"); // ary
        `,
    answerFunction: `
    // Write a function that takes a string as argument
    // Return the last 3 letters

        function myFunction(str) {
          return str.slice(-3)
       }

        //Test Cases:
        myFunction("hello world"); // rld
        myFunction("Mary"); // ary
      `,
  },
  {
    caption: "Convert a Set to Array",
    questionText: `
    // Write a function that takes a Set as argument
    // Convert the Set to an Array
    // Return the Array

        function myFunction(set) {
          return
        }

        //Test Cases:
        myFunction(new Set([1, 2, 3])) // [1, 2, 3]
        myFunction(new Set('123')) // ['1', '2', '3']
        `,
    answerFunction: `
// Write a function that takes a Set as argument
// Convert the Set to an Array
// Return the Array

        function myFunction(set) {
          return [...set]
        }

        //Test Cases:
        myFunction(new Set([1, 2, 3])) // [1, 2, 3]
        myFunction(new Set('123')) // ['1', '2', '3']
      `,
  },
  {
    caption: "Split a number into its digits",
    questionText: `
    // Write a function that takes a number (a) as argument
    // Split a into its individual digits and return them in an array
    // Tipp: you might want to change the type of the number for the splitting

        function myFunction( a ) {
          const string = 

          return
        }

        //Test Cases:
        myFunction(931) // [ 9, 3, 1 ]
        myFunction(24) // [ 2, 4 ]
        `,
    answerFunction: `
    // Write a function that takes a number (a) as argument
    // Split a into its individual digits and return them in an array
    // Tipp: you might want to change the type of the number for the splitting

        function myFunction( a ) {
          const string = a + '';
          const strings = string.split('');
          return strings.map(digit => Number(digit))
        }

        //Test Cases:
        myFunction(931) // [ 9, 3, 1 ]
        myFunction(24) // [ 2, 4 ]
      `,
  },
  {
    caption: "How many times does a character occur?",
    questionText: `
    // Write a function that takes two strings (a and b) as arguments
    // Return the number of times a occurs in b

    function myFunction(a, b) {
      return 
    }

    //Test Cases:
    myFunction('l', 'hello world') // 3
    myFunction('h', 'how many times does the character occur in this sentence?') // 4 
        `,
    answerFunction: `
    // Write a function that takes two strings (a and b) as arguments
    // Return the number of times a occurs in b

function myFunction(a, b) {
  return b.split(a).length - 1
}

        //Test Cases:
        myFunction('l', 'hello world') // 3
        myFunction('h', 'how many times does the character occur in this sentence?') // 4 
    `,
  },
  {
    caption: "Multiplication, division, and comparison operators",
    questionText: `
    // Write a function that takes two numbers (a and b) as arguments
    // If a is smaller than b, divide a by b
    // Otherwise, multiply both numbers
    // Return the resulting value
    
    function myFunction (a , b) {
        return
    }

        //Test Cases:
        myFunction(8, 20) // 0.4
        myFunction(90, 2) // 180
                `,
    answerFunction: `
    // Write a function that takes two numbers (a and b) as arguments
    // If a is smaller than b, divide a by b
    // Otherwise, multiply both numbers
    // Return the resulting value
    
    function myFunction (a , b) {
        return a < b ? a / b : a * b
    }

        //Test Cases:
        myFunction(8, 20) // 0.4
        myFunction(90, 2) // 180
      `,
  },
  {
    caption: "Concatenate two strings except their first character",
    questionText: `
        // Write a function that takes two strings as argument
        // concatenate two strings except their first character
        // return the concatenated result

        function myFunction(a, b) {

          return
        }

        //Test Cases:
        myFunction("PHP","JS") // HPS
        myFunction("BAT","HE") // ATE
        `,
    answerFunction: `
    // Write a function that takes two strings as argument
    // concatenate two strings except their first character
    // return the concatenated result

    function myFunction(a, b) {
      a = a.substring(1, a.length)
      b = b.substring(1, b.length)
      return a + b
    }

        //Test Cases:
        myFunction("PHP","JS") // HPS
        myFunction("BAT","HE") // ATE
      `,
  },
  {
    caption:
      "Compute the sum of three elements of a given array of integers of length 3",
    questionText: `
        // Write a function that takes an array as an argument
        // sum the first three elements in the array
        // Return the computed result

        function myFunction(arr) {
          return
        }
        

        //Test Cases:
        myFunction([5, 7, 9]) // 21
        myFunction([10, 32, 20]) // 62
        `,
    answerFunction: `
    // Write a function that takes an array as an argument
    // sum the first three elements in the array
    // Return the computed result

        function myFunction(arr) {
          return arr[0] + arr[1] + arr[2]
        }  

        //Test Cases:
        myFunction([5, 7, 9]) // 21
        myFunction([10, 32, 20, 39]) // 62
      `,
  },
  {
    caption:
      " Check whether 1 appears in first or last position of a given array of integers",
    questionText: `
    // Write a function that takes an array as argument
    // Check if either the first and last element is a 1
    // Return true if it is or otherwise

    function myFunction(arr) {
      return
    }  

    //Test Cases:
    myFunction([5, 7, 9]) // false
    myFunction([1, 32, 20, 39]) // true
        `,
    answerFunction: `
        // Write a function that takes an array as argument
        // Check if either the first and last element is a 1
        // Return true if it is or otherwise

        function myFunction(arr) {
          return nums[0] == 1 || nums[nums.length -1] == 1
        }  

        //Test Cases:
        myFunction([5, 7, 9]) // false
        myFunction([1, 32, 20, 39]) // true
      `,
  },
  {
    caption:
      "Compute the sum of absolute differences of consecutive numbers of a given array of integers",
    questionText: `
    // Write a function that takes an array as argument
    // Compute the sum of absolute differences of consecutive numbers of the array 
    // Return result

    function myFunction(arr) {
      return
    }
    

    //Test Cases:
    myFunction([1, 2, 3, 2, -5]) // 10
    myFunction([1, 2, 3]) // 2
        `,
    answerFunction: `
    // Write a function that takes an array as argument
    // Compute the sum of absolute differences of consecutive numbers of the array 
    // Return result

        function myFunction(arr) {
          let result = 0;
          for (let i = 1; i < arr.length; i++) {
            result += Math.abs(arr[i] - arr[i - 1]);
          }
          return result;
        }
        

        //Test Cases:
        myFunction([1, 2, 3, 2, -5]) // 10
        myFunction([1, 2, 3]) // 2
          `,
  },
  {
    caption:
      " Switch case of the minimum possible number of letters to make a given string written in the upper case or in the lower case",
    questionText: `
    // Write a function that takes a string as argument
    // Check the lowercase is more than uppercase letters
    // Return a lowercase else return all string as uppercase

    function myFunction(str) {
      let x = 0;
      let y = 0;

      return
    }

    //Test Cases:
    myFunction("Write") // write
    myFunction("PHp) // PHP
        `,
    answerFunction: `
    // Write a function that takes a string as argument
    // Check the lowercase is more than uppercase letters
    // Return a lowercase else return all string as uppercase

    function myFunction(str) {
          let x = 0;
          let y = 0;
        
          for (let i = 0; i < str.length; i++) {
            if (/[A-Z]/.test(str[i])) {
              x++;
            } else y++;
          }
        
          if (y > x) return str.toLowerCase();
          return str.toUpperCase();
        }

        //Test Cases:
        myFunction("Write") // write
        myFunction("PHp) // PHP
          `,
  },
  {
    caption:
      " Check whether there is at least one element which occurs in two given sorted arrays of integers",
    questionText: `
    // Write a function that takes two arrays as argument
    // Check if atleast one element occurs in both arrays
    // Return true if there is else return false

    function myFunction(arr1, arr2) {

      return
    }

    //Test Cases:
    myFunction([1,2,3], [3,4,5]) // true
    myFunction([1,2,3], [5,6,7])  // false
    `,
    answerFunction: `
    // Write a function that takes two arrays as argument
    // Check if atleast one element occurs in both arrays
    // Return true if there is else return false

        function myFunction(arr1, arr2) {
          for (var i = 0; i < arr1.length; i++)
          {
            if (arr2.indexOf(arr1[i]) != -1) 
              return true;
          }
          return false;
        }

        //Test Cases:
        myFunction([1,2,3], [3,4,5]) // true
        myFunction([1,2,3], [5,6,7])  // false
      `,
  },
  {
    caption: "Check if two dates fall on the exact same day",
    questionText: `
    // Write a function that takes two date instances as argument
    // It should return true if they fall on the exact same day
    // It should return false otherwise

    function myFunction(a, b) {
      return
    }  

    //Test Cases:
    myFunction(new Date('2000/11/01'), new Date('2000/01/01')) // false
    myFunction(new Date('2000/01/01'), new Date('2000/01/01')) // true
    `,
    answerFunction: `
    // Write a function that takes two date instances as argument
    // It should return true if they fall on the exact same day
    // It should return false otherwise

        function myFunction(a, b) {
          return a.getFullYear() === b.getFullYear() &&
                 a.getMonth() === b.getMonth() &&
                 a.getDate()=== b.getDate()
        }

        //Test Cases:
        myFunction(new Date('2000/11/01'), new Date('2000/01/01')) // false
        myFunction(new Date('2000/01/01'), new Date('2000/01/01')) // true
      `,
  },
  {
    caption: " Add specified months to a date",
    questionText: `
    // Write a function that takes as argument a date instance (a) and a number (b)
    // It should add b months to a and return the date

    function myFunction(a, b) {
      return
    }  

    //Test Cases:
    myFunction(new Date(2014, 10, 3), 10).toString() // Thu Sep 03 2015 00:00:00 GMT+0100 (West Africa Standard Time)
    myFunction(new Date(Date.UTC(2000,01,01)), 10).toString() // Fri Dec 01 2000 01:00:00 GMT+0100 (West Africa Standard Time)
        `,
    answerFunction: `
    // Write a function that takes as argument a date instance (a) and a number (b)
    // It should add b months to a and return the date

        function myFunction(a, b){
          return new Date(a.setMonth(a.getMonth() + b));      
        }

        //Test Cases:
        myFunction(new Date(2014, 10, 3), 10).toString() // Thu Sep 03 2015 00:00:00 GMT+0100 (West Africa Standard Time)
        myFunction(new Date(Date.UTC(2000,01,01)), 10).toString() // Fri Dec 01 2000 01:00:00 GMT+0100 (West Africa Standard Time)      `,
  },
  {
    caption: "Find the difference of two arrays",
    questionText: `
    // Write a function that takes two arrays as argument
    // Split each arrays and map through to get each element
    // With a for loop, check each elements against the elements of the other array andf push the difference into the new array
    // Return the new sorted array

    function myFunction(arr1, arr2) {
          let temp = [];

          return
    }  

    //Test Cases:
    myFunction([1, 2, 3], [100, 2, 1, 10]) // [3,10,100]
    myFunction([1, 2, 3, 4, 5], [1, [2], [3, [[4]]],[5,6]]) // [6]
    `,
    answerFunction: `
        // Write a function that takes two arrays as argument
        // Split each arrays and map through to get each element
        // With a for loop, check each elements against the elements of the other array andf push the difference into the new array
        // Return the new sorted array

        function myFunction(arr1, arr2) {
          let temp = [];
          array1 = array1.toString().split(',').map(Number)
          array2 = array2.toString().split(',').map(Number)
          
          for (var i in array1) {
            if(array2.indexOf(array1[i]) === -1) temp.push(array1[i])
          }
          for(i in array2) {
            if(array1.indexOf(array2[i]) === -1) temp.push(array2[i])
          }
          return temp.sort((a,b) => a-b)
          }

        //Test Cases:
        myFunction([1, 2, 3], [100, 2, 1, 10]) // [3,10,100]
        myFunction([1, 2, 3, 4, 5], [1, [2], [3, [[4]]],[5,6]]) // [6]
      `,
  },
  {
    caption: " Compute the union of two arrays",
    questionText: `
    // Write a function that takes two arrays as argument
    // Return the new array of the union in ascending order

    function myFunction(arr1, arr2) {
      return
    }  

    //Test Cases:
    myFunction([1, 2, 3], [100, 2, 1, 10]) // [1,2,3,10,100]
    myFunction([1, 2, 79], [10]) // [1, 2, 10, 79]
        `,
    answerFunction: `
        // Write a function that takes two arrays as argument
        // Return the new array of the union in ascending order

        function myFunction(arr1, arr2) {
          return [...new Set([...arr1, ...arr2].sort((a, b) => a - b))]
        }

        //Test Cases:
        myFunction([1, 2, 3], [100, 2, 1, 10]) // [1,2,3,10,100]
        myFunction([1, 2, 79], [10]) // [1, 2, 10, 79]
          `,
  },
  {
    caption: "Flatten a nested array",
    questionText: `
    // Write a function that takes two arguments; an array and a boolean
    // If you pass shallow which is a boolean(true), the array will only be flattened a single level.
    // Return the flattened array

    function myFunction(a, shallow, r) {
      return
    }

    //Test Cases:
    myFunction([1, [2], [3, [[4]]],[5,6]]) // [1,2,3,4,5,6]
    myFunction([1, [2], [3, [[4]]],[5,6]], true) // [1,2,3,[[4]],5,6]
        `,
    answerFunction: `
    // Write a function that takes two arguments; an array and a boolean
    // If you pass shallow which is a boolean(true), the array will only be flattened a single level.
    // Return the flattened array

        function myFunction(a, shallow, r) {
          return a.flat(shallow ? 1 : Infinity)
        }

        //Test Cases:
        myFunction([1, [2], [3, [[4]]],[5,6]]) // [1,2,3,4,5,6]
        myFunction([1, [2], [3, [[4]]],[5,6]], true) // [1,2,3,[[4]],5,6]
      `,
  },
  {
    caption: "Find duplicate values in an array",
    questionText: `
    // Write a function that takes an array as argument
    // Check if an element occurs more than once
    // Return the duplicated elements

    function myFunction(arr) {
      return
    }  

    //Test Cases:
    myFunction([1, 2, -2, 4, 5, 4, 7, 8, 7, 7, 71, 3, 6]) // [4, 7]
    myFunction([1, 6, 5, 5]) // [5]
        `,
    answerFunction: `
    // Write a function that takes an array as argument
    // Check if an element occurs more than once
    // Return the duplicated elements

      function myFunction(arr) {
        return [...new Set(arr.filter(v => arr.indexOf(v) !== arr.lastIndexOf(v)))]
     }

     //Test Cases:
     myFunction([1, 2, -2, 4, 5, 4, 7, 8, 7, 7, 71, 3, 6]) // [4, 7]
     myFunction([1, 6, 5, 5]) // [5]
    `,
  },
  {
    caption:
      " Check whether 1 appears in first or last position of a given array of integers",
    questionText: `
    // Write a function that takes an array as argument
    // Check if either the first and last element is a 1
    // Return true if it is or otherwise

    function myFunction(arr) {
      return
    }  

    //Test Cases:
    myFunction([5, 7, 9]) // false
    myFunction([1, 32, 20, 39]) // true
        `,
    answerFunction: `
        // Write a function that takes an array as argument
        // Check if either the first and last element is a 1
        // Return true if it is or otherwise

        function myFunction(arr) {
          return nums[0] == 1 || nums[nums.length -1] == 1
        }  

        //Test Cases:
        myFunction([5, 7, 9]) // false
        myFunction([1, 32, 20, 39]) // true
      `,
  },
  {
    caption: "Merge two objects with matching keys",
    questionText: `
    // Write a function that takes two objects as arguments
    // merge the two objects with their corresponding keys
    // Return the new object

    function myFunction(a, b) {
      return
    }  

    //Test Cases:
    // myFunction({ a: 1, b: 2 }, { c: 3, b: 4, e: 5 } // {"a":1,"b":4,"c":3,"e":5}
    // myFunction({ a: 1, b: 2 }, { c: 5 }) // {"a":1,"b":2,"c":5}
        `,
    answerFunction: `
    // Write a function that takes two objects as arguments
    // merge the two objects with their corresponding keys
    // Return the new object

        function myFunction(a, b) {
          return {...a, ...b}
        }  
    
        //Test Cases:
        // myFunction({ a: 1, b: 2 }, { c: 3, b: 4, e: 5 }) // {"a":1,"b":4,"c":3,"e":5}
        // myFunction({ a: 1, b: 2 }, { c: 5 }) // {"a":1,"b":2,"c":5}
        `,
  },
  {
    caption:
      " Converts the first letter of each word of a string in upper case",
    questionText: `
    // Write a function that takes a string as argument
    // converts the first letter of each word of the string in upper case
    // Return the result

    function myFunction(str){
      return
    }  

    //Test Cases:
    // myFunction("the quick brown fox") // The Quick Brown Fox
    // myFunction("javascript challenge") // Javascript Challenge
    `,
    answerFunction: `
    // Write a function that takes a string as argument
    // converts the first letter of each word of the string in upper case
    // Return the result

        function myFunction(str){
          return str.match(/+/gi).map(x => x[0].toLocaleUpperCase() + x.slice(1)).join(' ')
        }
    
        //Test Cases:
        // myFunction("the quick brown fox") // The Quick Brown Fox
        // myFunction("javascript challenge") // Javascript Challenge
     `,
  },
];

export default questions;

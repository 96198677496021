import React, { useState, useEffect } from 'react';
import './videoplayer.css';
import './AvailableCourses.css';
import { FiSearch } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Courses } from '../Courses';
import { getVideo } from '../../../../services/video-service';

const AvailableCourses = () => {
  const location = useLocation();
  const [videoIndex, setVideoIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [isUserEnrolled, setIsUserEnrolled] = useState(false);

  useEffect(() => {
    // check if user has subscribed to courses
    const checkIfUserSubscribed = async () => {
      try {
        const videoResponse = await getVideo();
        let videoDetails = JSON.parse(videoResponse.data.body).result;
        if (videoDetails === null) {
          setIsUserEnrolled(false);
        } else {
          setIsUserEnrolled(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkIfUserSubscribed();

    return () => {
      checkIfUserSubscribed();
    };
  }, []);

  //play video on click of image
  const handlePlayImgVideo = (e) => {
    setVideoIndex(e);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  return (
    <React.Fragment>
      {loading ? (
        <div className="loader-overlay">
          <PropagateLoader
            color="#516BEB"
            size={30}
          />
        </div>
      ) : (
        <div className="user-profile">
          <div className="profile">
            <section className="profile-header">
              <h1>Available Courses</h1>

              <div>
                <input
                  type="text"
                  id="searchText"
                  name="searchText"
                  value={searchText}
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <FiSearch id="font" />
              </div>
            </section>

            <div className="completed-courses">
              {searchText !== ''
                ? Courses.filter((course) =>
                    course.courseTitle
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ).map((course) => (
                    <div
                      className="course"
                      key={course.id}
                    >
                      <div className="course-thumbnail">
                        {course.id === videoIndex ? (
                          <img
                            src={course.image}
                            alt="course-img"
                            style={{ display: 'none' }}
                          />
                        ) : (
                          <React.Fragment>
                            <img
                              src={course.image}
                              alt="course-img"
                            />
                            <div
                              className="play-icon-body"
                              onClick={() => handlePlayImgVideo(course.id)}
                            >
                              <BsFillPlayFill className="play-icon" />
                            </div>
                          </React.Fragment>
                        )}

                        {course.id === videoIndex ? (
                          <ReactPlayer
                            controls={true}
                            className="react-player"
                            url={course.vid}
                            width="100%"
                            height="100%"
                            config={{
                              file: {
                                attributes: {
                                  controlsList: 'nodownload',
                                  onContextMenu: (e) => e.preventDefault(),
                                },
                              },
                            }}
                          />
                        ) : null}
                      </div>

                      <Link
                        to={course.link}
                        state={{
                          from: location.pathname,
                          isUserEnrolled,
                        }}
                      >
                        <p>{course.courseTitle}</p>
                      </Link>
                    </div>
                  ))
                : Courses.map((course) => (
                    <div
                      className="course"
                      key={course.id}
                    >
                      <div className="course-thumbnail">
                        {course.id === videoIndex ? (
                          <img
                            src={course.image}
                            alt="course-img"
                            style={{ display: 'none' }}
                          />
                        ) : (
                          <React.Fragment>
                            <img
                              src={course.image}
                              alt="course-img"
                            />
                            <div
                              className="play-icon-body"
                              onClick={() => handlePlayImgVideo(course.id)}
                            >
                              <BsFillPlayFill className="play-icon" />
                            </div>
                          </React.Fragment>
                        )}

                        {course.id === videoIndex ? (
                          <ReactPlayer
                            controls={true}
                            className="react-player"
                            url={course.vid}
                            width="100%"
                            height="100%"
                            config={{
                              file: {
                                attributes: {
                                  controlsList: 'nodownload',
                                  onContextMenu: (e) => e.preventDefault(),
                                },
                              },
                            }}
                          />
                        ) : null}
                      </div>
                      <Link
                        to={course.link}
                        state={{
                          from: location.pathname,
                          isUserEnrolled,
                        }}
                      >
                        <p>{course.courseTitle}</p>
                      </Link>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AvailableCourses;

import React from 'react';
import './LogOutModal.css';

const LogOutModal = (props) => {
  const { logOut, setLogOutModal } = props;

  return (
    <div className="modal-background">
      <div className="modal-container">
        <h2>Log Out?</h2>

        <div className="modal-buttons">
          <button onClick={() => logOut()}>Proceed</button>
          <button onClick={() => setLogOutModal(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default LogOutModal;

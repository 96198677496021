const questions = [
	{
		id: 1,
		questionText: 'If you have multiple applications in Azure Active Directory that you want users to access,which is the best way to handle user access?',
		answerOptions: [
			{
				answerText: 'Using multi-factor authentication',
				isCorrect: false
			},
			{
				answerText: ' Providing each user with a password for each app, as this is most secure',
				isCorrect: false
			},
			{
				answerText: ' Using single sign-on',
				isCorrect: true
			},
			{
				answerText: 'Using the built-in premium authentication service',
				isCorrect: true
			},
		]
	},
	{
		id: 2,
		questionText:
			'What is a definition of authentication?',
		answerOptions: [
			{
				answerText: ' Being able to log in to the Azure portal from anywhere',
				isCorrect: false
			},
			{
				answerText: 'Determination of access to a system based on authorization',
				isCorrect: false
			},
			{
				answerText: 'The ability of a system to determine your location when accessing it	',
				isCorrect: false
			},
			{
				answerText: 'Confirmation of your identity for a system',
				isCorrect: true
			}
		]
	},
	{
		id: 3,
		questionText: ' What is a definition of authorization?',
		answerOptions: [
			{
				answerText: "Being able to log in to the Azure portal from anywhere.",
				isCorrect: false
			},
			{
				answerText: ' The ability of a system to determine your location when accessing it',
				isCorrect: false
			},
			{
				answerText: 'Determination of access to a system based on assigned roles/permissions',
				isCorrect: true
			},
			{
				answerText: ' Confirmation of your identity for a system.',
				isCorrect: false
			},
		]
	},
	{
		id: 4,
		questionText:
			'Which are authentication methods used to verify a user with multi-factor authentication? (choose 3)',
		answerOptions: [
			{
				answerText: 'Something you feel',
				isCorrect: false
			},
			{
				answerText: 'Something you have',
				isCorrect: true
			},
			{
				answerText: 'Something you say',
				isCorrect: false
			},
			{
				answerText: 'Something you can recall quickly',
				isCorrect: false
			},
			{
				answerText: 'Something you know',
				isCorrect: true
			},
			{
				answerText: 'Something you are',
				isCorrect: true
			}
		]
	},
	{
		id: 5,
		questionText:
			'What is a security policy in Security Center?			',
		answerOptions: [
			{
				answerText: 'The individual user settings for using the Security Center				',
				isCorrect: false
			},
			{
				answerText: ' A more detailed version of the settings for an Azure service, which can also be automated				',
				isCorrect: false
			},
			{
				answerText: ' An outline of the necessary security measures for a compliant service in Azure				',
				isCorrect: false
			},
			{
				answerText: ' A set of rules that Azure can use to evaluate if your configuration of a service is valid				',
				isCorrect: true
			},

		]
	},
	{
		id: 6,
		questionText:
			'Which of the following are types of locks in Azure?',
		answerOptions: [
			{
				answerText: 'Delete',
				isCorrect: true
			},
			{
				answerText: 'Closed',
				isCorrect: false
			},
			{
				answerText: 'Create-only',
				isCorrect: false
			},
			{
				answerText: 'Open',
				isCorrect: false
			},
			{
				answerText: 'Read only',
				isCorrect: true
			},

		]
	},
	{
		id: 7,
		questionText: 'Which companies must comply with General Data Protection Regulation (GDPR) requirements?',
		answerOptions: [
			{
				answerText: 'Only companies that are headquartered within the European Union must adhere to GDPR.',
				isCorrect: false
			},
			{
				answerText: ' Only companies in Europe must adhere to GDPR.',
				isCorrect: false
			},
			{
				answerText: ' Companies of any country must adhere to GDPR if their users and customers are located inthe European Union.',
				isCorrect: true
			},
			{
				answerText: ' Any company in the world that has an online presence must adhere to GDPR.',
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText: 'What are the Microsoft services that can tell you more about trust in the Azure platform?',
		answerOptions: [
			{
				answerText: 'Trust Center',
				isCorrect: true
			},
			{
				answerText: 'Service Trust Portal',
				isCorrect: true
			},
			{
				answerText: "There isn't a single service, as trust is part of every service on Azure",
				isCorrect: false
			},
			{
				answerText: ' Azure privacy portal',
				isCorrect: false
			},
			{
				answerText: 'Azure User Safety Portal',
				isCorrect: false
			}
		]
	},
	{
		id: 9,
		questionText: 'Which of the following are valid use cases to use Azure Service Health in your architecture?		',
		answerOptions: [
			{
				answerText: ' To set up a testing environment to make sure you have a valid architecture',
				isCorrect: false
			},
			{
				answerText: ' To set up custom alerts to notify you of any outages, planned or otherwise',
				isCorrect: true
			},
			{
				answerText: " To find performance improvements in your App Service apps",
				isCorrect: false
			},
			{
				answerText: ' To track incidents with your services in real time and get a report afterwards',
				isCorrect: true
			},
			{
				answerText: 'To receive data from third-party applications running on other cloud platforms',
				isCorrect: false
			}
		]
	},
	{
		id: 10,
		questionText: 'What is special about the China region in Azure?',
		answerOptions: [
			{
				answerText: ' You have to be a Chinese citizen to use the China region',
				isCorrect: false
			},
			{
				answerText: 'You are guaranteed to be compliant with all Chinese data and IT regulations',
				isCorrect: true
			},
			{
				answerText: ' All customer data is guaranteed to be geographically within China',
				isCorrect: true
			},
			{
				answerText: 'It is priced purely in Chinese yuan and not American dollars',
				isCorrect: false
			},
			{
				answerText: 'All Azure services available in the China region are physically located inside China',
				isCorrect: true
			}
		]
	},
	{
		id: 11,
		questionText: ' Which of the following are features of role-based access control?',
		answerOptions: [
			{
				answerText: 'Multi-factor authentication',
				isCorrect: false
			},
			{
				answerText: 'Using any of the five predefined roles to make user management simple and straightforward',
				isCorrect: false
			},
			{
				answerText: 'Defining which actions users can take on a resource',
				isCorrect: true
			},
			{
				answerText: 'Defining which users have access to specific Azure resources',
				isCorrect: true
			},
			{
				answerText: 'Tiered privacy protection for users within your Azure tenant',
				isCorrect: false
			}
		]
	},
	{
		id: 12,
		questionText: ' What is the service for managing privacy on Azure called?',
		answerOptions: [
			{
				answerText: 'It depends on your subscription level.',
				isCorrect: false
			},
			{
				answerText: "There isn't a single service, as privacy is part of every service on Azure.",
				isCorrect: true
			},
			{
				answerText: 'Azure Privacy Center',
				isCorrect: false
			},
			{
				answerText: 'Azure Privacy Portal',
				isCorrect: false
			},
			{
				answerText: 'Azure User Safety Portal',
				isCorrect: false
			}
		]
	}
];

export default questions;

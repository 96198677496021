import React, { useState } from 'react';
import Select from 'react-select';
import './Landing.css';
import { languageOptions } from '../constants/languageOptions';
import { customStyles } from '../constants/customStyles';
import { Link } from 'react-router-dom';

const LanguagesDropdown = () => {
  const [language, setLanguage] = useState();

  const onSelectChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem('editorLanguage', JSON.stringify(lang.name));
  };

  return (
    <div
      className="language-dropdown"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        flexDirection: 'column',
        backgroundColor: '#404756',
      }}
    >
      <h1
        style={{
          color: '#DDF2FF',
          fontFamily: 'Inter',
          fontSize: '33px',
          fontWeight: '700',
          marginBottom: '30px',
          textAlign: 'center'
        }}
      >
        Welcome to the coding challenge!
      </h1>
      <p
        style={{
          color: '#DDF2FF',
          fontFamily: 'Inter',
          fontSize: '24px',
          fontWeight: '400',
          textAlign: 'center',
          marginBottom: '30px',
          width: '70%',
        }}
      >
        In order to begin, please select a programming language from the list
        provided. This will determine the syntax and libraries that you have
        access to during the challenge. Good luck!
      </p>

      <Select
        placeholder={`Select Language`}
        options={languageOptions}
        styles={customStyles}
        defaultValue={language}
        onChange={(selectedOption) => onSelectChange(selectedOption)}
      />

      <Link to="/coding">
        <button
          style={
            language
              ? {
                  background: '#6499e9',
                  border: 'none',
                  borderRadius: '8px',
                  color: '#fff',
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  fontSize: '16px',
                  marginTop: 20,
                  padding: '10px 20px',
                  width: '18rem',
                }
              : {
                  color: '#fff',
                  background: '#7d90eeab',
                  border: 'none',
                  borderRadius: '8px',
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  fontSize: '16px',
                  marginTop: 20,
                  padding: '10px 20px',
                  width: '18rem',
                }
          }
          disabled={language ? false : true}
        >
          Proceed to Coding Challenge
        </button>
      </Link>
    </div>
  );
};

export default LanguagesDropdown;

const questions = [
	{
		id: 1,
		questionText: 'Pandas is an open-source _______ Library?',
		answerOptions: [
			{
				answerText: 'Ruby',
				isCorrect: false
			},
			{
				answerText: 'JavaScript',
				isCorrect: false
			},
			{
				answerText: 'Java',
				isCorrect: false
			},
			{
				answerText: 'Python',
				isCorrect: true
			}
		]
	},
	{
		id: 2,
		questionText: 'Pandas key data structure is called?',
		answerOptions: [
			{
				answerText: 'Keyframe',
				isCorrect: false
			},
			{
				answerText: 'DataFrame',
				isCorrect: true
			},
			{
				answerText: 'Statistics',
				isCorrect: false
			},
			{
				answerText: 'Econometrics',
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: 'In pandas, Index values must be?',
		answerOptions: [
			{
				answerText: 'unique',
				isCorrect: false
			},
			{
				answerText: 'hashable',
				isCorrect: false
			},
			{
				answerText: 'Both A and B',
				isCorrect: true
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText: 'Which of the following is correct Features of DataFrame?',
		answerOptions: [
			{
				answerText: 'Potentially columns are of different types',
				isCorrect: false
			},
			{
				answerText: 'Can Perform Arithmetic operations on rows and columns',
				isCorrect: false
			},
			{
				answerText: 'Labeled axes (rows and columns)',
				isCorrect: false
			},
			{
				answerText: 'All of the above',
				isCorrect: true
			}
		]
	},
	{
		id: 5,
		questionText: '_________ is the most important language for Data Science.',
		answerOptions: [
			{
				answerText: 'Java',
				isCorrect: false
			},
			{
				answerText: 'Python',
				isCorrect: false
			},
			{
				answerText: 'R',
				isCorrect: true
			},
			{
				answerText: 'Ruby',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText: ' _________ is one of the key data science skills',
		answerOptions: [
			{
				answerText: 'Machine Learning',
				isCorrect: false
			},
			{
				answerText: 'Statistics',
				isCorrect: false
			},
			{
				answerText: 'Data Visualization',
				isCorrect: false
			},
			{
				answerText: 'All of the above',
				isCorrect: true
			}
		]
	},
	{
		id: 7,
		questionText: 'Raw data should be processed only one time.',
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: false
			},
			{
				answerText: 'False',
				isCorrect: true
			}
		]
	},
	{
		id: 8,
		questionText:
			'A data scientist is a job title for an employee or business intelligence (BI) consultant who excels at analyzing data, particularly large amounts of data.',
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: true
			},
			{
				answerText: 'False',
				isCorrect: false
			}
		]
	},
	{
		id: 9,
		questionText: 'Which among the following is the top most important thing in data science?',
		answerOptions: [
			{
				answerText: 'Answer',
				isCorrect: false
			},
			{
				answerText: 'Question',
				isCorrect: true
			},
			{
				answerText: 'Data',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 10,
		questionText: '_________ is one of the key data science skills.',
		answerOptions: [
			{
				answerText: 'Machine Learning',
				isCorrect: false
			},
			{
				answerText: 'Statistics',
				isCorrect: false
			},
			{
				answerText: 'Data Visualization',
				isCorrect: false
			},
			{
				answerText: 'All of the above',
				isCorrect: true
			}
		]
	},
	{
		id: 11,
		questionText: 'Some of the important libraries of Python that are used in Data Science are _________.',
		answerOptions: [
			{
				answerText: 'Pandas',
				isCorrect: false
			},
			{
				answerText: 'Numpy',
				isCorrect: false
			},
			{
				answerText: 'Both A and B',
				isCorrect: true
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 12,
		questionText: 'Normal Distribution is also known as_______.',
		answerOptions: [
			{
				answerText: ' Gaussian Distribution',
				isCorrect: true
			},
			{
				answerText: 'Class Distribution',
				isCorrect: false
			},
			{
				answerText: 'Permanent Distribution',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 13,
		questionText: `What will be output for the following code?
        import numpy as np 
        
        a = np.array([1,2,3]) 
        
        print a
        `,
		answerOptions: [
			{
				answerText: ' [[1, 2, 3]]',
				isCorrect: false
			},
			{
				answerText: '[1]',
				isCorrect: false
			},
			{
				answerText: '[1, 2, 3]',
				isCorrect: true
			},
			{
				answerText: 'Error',
				isCorrect: false
			}
		]
	},
	{
		id: 14,
		questionText: 'Which of the following statement is false?.',
		answerOptions: [
			{
				answerText: 'ndarray is also known as the axis array',
				isCorrect: true
			},
			{
				answerText: 'ndarray.dataitemSize is the buffer containing the actual elements of the array',
				isCorrect: false
			},
			{
				answerText: 'NumPy main object is the homogeneous multidimensional array',
				isCorrect: false
			},
			{
				answerText: 'In Numpy, dimensions are called axes',
				isCorrect: false
			}
		]
	},
	{
		id: 15,
		questionText: 'NumPy is often used along with packages like?',
		answerOptions: [
			{
				answerText: 'Node.js',
				isCorrect: false
			},
			{
				answerText: 'Matplotlib',
				isCorrect: false
			},
			{
				answerText: 'SciPy',
				isCorrect: false
			},
			{
				answerText: 'Both B and C',
				isCorrect: true
			}
		]
	}
];

export default questions;

const questions = [
	{
		id: 1,
		questionText: 'Which AWS services can a company use to gather information about activity in their AWS account?',
		answerOptions: [
			{
				answerText: 'AWS CloudTrail',
				isCorrect: true
			},
			{
				answerText: 'AWS Trusted Advisor',
				isCorrect: false
			},
			{
				answerText: 'Amazon CloudFront',
				isCorrect: false
			},
			{
				answerText: 'Amazon Connect',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText:
			'A company is planning to move a number of legacy applications to the AWS Cloud. The solution must be cost-effective. Which approach should the company take?',
		answerOptions: [
			{
				answerText: 'Use AWS Lambda to host the legacy applications in the cloud',
				isCorrect: false
			},
			{
				answerText: 'Migrate the applications to dedicated hosts on Amazon EC2',
				isCorrect: false
			},
			{
				answerText: 'Use an Amazon S3 static website to host the legacy application code.',
				isCorrect: false
			},
			{
				answerText: 'Rehost the application on Amazon EC2 instances that are right-sized.',
				isCorrect: true
			}
		]
	},
	{
		id: 3,
		questionText: 'Customers using AWS services must patch operating systems on which of the following services?',
		answerOptions: [
			{
				answerText: 'AWS Lambda',
				isCorrect: false
			},
			{
				answerText: 'AWS Fargate',
				isCorrect: false
			},
			{
				answerText: 'Amazon  EC2',
				isCorrect: true
			},
			{
				answerText: 'Amazon DynamoDB',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText:
			'What advantage does a database administrator obtain by using the Amazon Relational Database Service (RDS)?',
		answerOptions: [
			{
				answerText: 'RDS simplifies relational database administration tasks',
				isCorrect: true
			},
			{
				answerText: 'RDS database automatically scale based on load',
				isCorrect: false
			},
			{
				answerText: 'RDS provides 99.9999999999% reliability and durability',
				isCorrect: false
			},
			{
				answerText: 'RDS enables users to dynamically adjust CPU and RAM resources',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText:
			'Which of the following will help a user determine if they need to request an Amazon EC2 service limit increase?',
		answerOptions: [
			{
				answerText: 'AWS Trusted Advisor',
				isCorrect: true
			},
			{
				answerText: 'AWS Health Dashboard',
				isCorrect: false
			},
			{
				answerText: 'AWS Cost Explorer',
				isCorrect: false
			},
			{
				answerText: 'Amazon RDS',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText:
			'A company is deploying a new web application in a single AWS Region that will be used by users globally.\n Which AWS services will assist with lowering latency and improving transfer speeds for the global users?',
		answerOptions: [
			{
				answerText: 'AWS Direct Connect',
				isCorrect: false
			},
			{
				answerText: 'AWS Transit Gateway',
				isCorrect: false
			},
			{
				answerText: 'AWS CloudFront',
				isCorrect: true
			},
			{
				answerText: 'Amazon Snowcone',
				isCorrect: false
			}
		]
	},
	{
		id: 7,
		questionText: `A company is planning to deploy an application with a relational database on AWS. The application layer requires access to the database instance's operating system in order to run scripts. \n The company prefer to keep management overhead to a minimum. Which deployment should be used for the database?`,
		answerOptions: [
			{
				answerText: 'Amazon S3',
				isCorrect: false
			},
			{
				answerText: 'Amazon EC2',
				isCorrect: true
			},
			{
				answerText: 'Amazon RDS',
				isCorrect: false
			},
			{
				answerText: 'Amazon DynamoDB',
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText: 'According to the AWS responsibility model, which of the following is a responsibility of AWS?',
		answerOptions: [
			{
				answerText: 'Updating security group rules to enable connectivity',
				isCorrect: false
			},
			{
				answerText: 'Patching software running on Amazon EC2 instances',
				isCorrect: false
			},
			{
				answerText: 'Updating the firmware on the underlying EC2 hosts',
				isCorrect: true
			},
			{
				answerText: 'Confiiguring network ACLs to block malicious attacks',
				isCorrect: false
			}
		]
	},
	{
		id: 9,
		questionText:
			'An individual IAM user must be granted access to an Amazon S3 bucket using a bucket policy. Which element in the S3 bucket policy should be updated to define the user account for which access will be granted?',
		answerOptions: [
			{
				answerText: 'Resource',
				isCorrect: false
			},
			{
				answerText: 'Action',
				isCorrect: false
			},
			{
				answerText: 'Condition',
				isCorrect: false
			},
			{
				answerText: 'Principal',
				isCorrect: true
			}
		]
	},
	{
		id: 10,
		questionText: 'Which of the following are valid benefits of using the AWS Cloud?',
		answerOptions: [
			{
				answerText: 'Outsource all application development to AWS',
				isCorrect: false
			},
			{
				answerText: 'Ability to go global quickly',
				isCorrect: true
			},
			{
				answerText: 'Outsource all operational risk',
				isCorrect: false
			},
			{
				answerText: 'Total control over data center infrastructure',
				isCorrect: false
			}
		]
	},
	{
		id: 11,
		questionText:
			'A user needs to identify underutilized Amazon EC2 instances to reduce costs. Which AWS service or feature will meet this requirement?',
		answerOptions: [
			{
				answerText: 'AWS Trusted Advisor',
				isCorrect: true
			},
			{
				answerText: 'AWS CodeBuild',
				isCorrect: false
			},
			{
				answerText: 'AWS Cost Explorer',
				isCorrect: false
			},
			{
				answerText: 'AWS Health Dashboard',
				isCorrect: false
			}
		]
	},
	{
		id: 12,
		questionText: 'Which tasks require the use of the AWS account root user?',
		answerOptions: [
			{
				answerText: 'Enabling encryption for S3',
				isCorrect: false
			},
			{
				answerText: 'Changing AWS Support plans',
				isCorrect: true
			},
			{
				answerText: 'Viewing AWS CloudTrial logs',
				isCorrect: false
			},
			{
				answerText: 'Changing payment currency',
				isCorrect: false
			}
		]
	},
	{
		id: 13,
		questionText:
			'A Cloud Practitioner anticipates an increase in application traffic at a future date and time when a sales event will take place. How can the Cloud Practitioner configure Amazon EC2 Auto Scaling to ensure the right number of Amazon EC2 instances are available ahead of the event?',
		answerOptions: [
			{
				answerText: 'Configure predictive scaling',
				isCorrect: false
			},
			{
				answerText: 'Configure a step scaling policy',
				isCorrect: false
			},
			{
				answerText: 'Configure a scheduled scaling policy',
				isCorrect: true
			},
			{
				answerText: 'Configure a target tracking scaling policy',
				isCorrect: false
			}
		]
	},
	{
		id: 14,
		questionText:
			'A Cloud practitioner needs to monitor a new Amazon EC2 instances CPU and network utilization. Which AWS service should be used?',
		answerOptions: [
			{
				answerText: 'Amazon CloudWatch',
				isCorrect: true
			},
			{
				answerText: 'AWS CloudTrail',
				isCorrect: false
			},
			{
				answerText: 'Amazon Inspector',
				isCorrect: false
			},
			{
				answerText: 'AWS System Manager',
				isCorrect: false
			}
		]
	},
	{
		id: 15,
		questionText:
			'A large company is interested in avoiding long-term contracts and moving from fixed costs to variable costs. What is the value proposition of AWS for this company?',
		answerOptions: [
			{
				answerText: 'Pay-as-you-go pricing',
				isCorrect: true
			},
			{
				answerText: 'Automated cost optimization',
				isCorrect: false
			},
			{
				answerText: 'Economies of scale',
				isCorrect: false
			},
			{
				answerText: 'Volume pricing discounts',
				isCorrect: false
			}
		]
	},
	{
		id: 16,
		questionText:
			'When running applications in the AWS Cloud, which commom tasks can AWS manage on behalf of their customers?',
		answerOptions: [
			{
				answerText: 'Application source code auditing',
				isCorrect: false
			},
			{
				answerText: 'Patching database software',
				isCorrect: true
			},
			{
				answerText: 'Creating a database scheme',
				isCorrect: false
			},
			{
				answerText: 'Application security testing',
				isCorrect: false
			}
		]
	},
	{
		id: 17,
		questionText:
			'A company is designing a new service that must align with the operational excellence pillar of the AWS Well-Architected Framework. Which design principles should the company follow?',
		answerOptions: [
			{
				answerText: 'Create static operational procedures',
				isCorrect: false
			},
			{
				answerText: 'Make large-scale changes',
				isCorrect: false
			},
			{
				answerText: 'Perform manual operations',
				isCorrect: false
			},
			{
				answerText: 'Perform operations as code',
				isCorrect: true
			}
		]
	},
	{
		id: 18,
		questionText: 'Which design principles are enabled by the AWS Cloud to improve the operation of workloads?',
		answerOptions: [
			{
				answerText: 'Minimum viable product',
				isCorrect: false
			},
			{
				answerText: 'Minimum platform design',
				isCorrect: false
			},
			{
				answerText: 'Customized hardware',
				isCorrect: false
			},
			{
				answerText: 'Remove single points of failure',
				isCorrect: true
			}
		]
	},
	{
		id: 19,
		questionText: 'Which of the following can an AWS customer use to launch a new ElastiCache cluster?',
		answerOptions: [
			{
				answerText: 'AWS CloudFormation',
				isCorrect: true
			},
			{
				answerText: 'AWS Concierge',
				isCorrect: false
			},
			{
				answerText: 'AWS System Manager',
				isCorrect: false
			},
			{
				answerText: 'AWS Data Pipeline',
				isCorrect: false
			}
		]
	},
	{
		id: 20,
		questionText:
			'A company has many underutilized compute rersources on-premises. Which AWS Cloud feature will help resolve this issue?',
		answerOptions: [
			{
				answerText: 'Global deployment',
				isCorrect: false
			},
			{
				answerText: 'Elasticity',
				isCorrect: true
			},
			{
				answerText: 'High availability',
				isCorrect: false
			},
			{
				answerText: 'Fault tolerance',
				isCorrect: false
			}
		]
	},
	{
		id: 21,
		questionText: `A company plans to use reserved instances to get discounted pricing for Amazon EC2 instances. The company may need to change the EC2 instance type during the one year period.
			\n
			Which instance purchasing option is the MOST cost-effecdtive for this use case?`,
		answerOptions: [
			{
				answerText: 'Convertible Reserved Instances',
				isCorrect: true
			},
			{
				answerText: 'Regional Reserved Instances',
				isCorrect: false
			},
			{
				answerText: 'Zonal Reserves Instances',
				isCorrect: false
			},
			{
				answerText: 'Standard Reserved Instances',
				isCorrect: false
			}
		]
	},
	{
		id: 22,
		questionText:
			'Which AWS service can a company use to discover and protect sensitive data that is stored in Amazon S3 buckets',
		answerOptions: [
			{
				answerText: 'Amazon Macie',
				isCorrect: true
			},
			{
				answerText: 'Amazon GuardDuty',
				isCorrect: false
			},
			{
				answerText: 'Amazon Detective',
				isCorrect: false
			},
			{
				answerText: 'Amazon Policy Generator',
				isCorrect: false
			}
		]
	},
	{
		id: 23,
		questionText:
			'A company plans to deploy a relational database on AWS. The IT department will perform ddatabase administration. Which service should the company use?',
		answerOptions: [
			{
				answerText: 'Amazon EC2',
				isCorrect: true
			},
			{
				answerText: 'Amazon RedShift',
				isCorrect: false
			},
			{
				answerText: 'Amazon DynamoDB',
				isCorrect: false
			},
			{
				answerText: 'Amazon ElastiCache',
				isCorrect: false
			}
		]
	},
	{
		id: 24,
		questionText: 'How does the AWS cloud increase the speed and agility of execution for customers?',
		answerOptions: [
			{
				answerText: 'Scalable compute capacity',
				isCorrect: true
			},
			{
				answerText: 'Secured data centers',
				isCorrect: false
			},
			{
				answerText: 'Lower cost of deployment',
				isCorrect: false
			},
			{
				answerText: 'Private connections to data centers',
				isCorrect: false
			}
		]
	},
	{
		id: 25,
		questionText:
			'Which of the following deploymenrs involves the reliabiity pillar of the AWS Well-Architected Framework?',
		answerOptions: [
			{
				answerText: 'Use CloudFormation to dploy infrastructure',
				isCorrect: false
			},
			{
				answerText: 'Amazon EBS provisioned IOPS volume',
				isCorrect: false
			},
			{
				answerText: 'Attach a WebACL to a CloudFront distribution',
				isCorrect: false
			},
			{
				answerText: 'Amazon RDS Multi-AZ deployment',
				isCorrect: true
			}
		]
	},
	{
		id: 26,
		questionText:
			'A website has a global customer base and users have reported poor performance when connecting to the site. \n Which AWS sevice will improve the customer experience by reducing latency?',
		answerOptions: [
			{
				answerText: 'AWS Direct Connect',
				isCorrect: false
			},
			{
				answerText: 'Amazon ElastiCache',
				isCorrect: false
			},
			{
				answerText: 'Amazon EC2 Auto Scaling',
				isCorrect: false
			},
			{
				answerText: 'Amazon CloudFront',
				isCorrect: true
			}
		]
	},
	{
		id: 27,
		questionText:
			'Which AWS service or feature can assist with protecting a website that is hosted outside of AWS?',
		answerOptions: [
			{
				answerText: 'Amazon EC2 security groups',
				isCorrect: false
			},
			{
				answerText: 'Amazon VPC route tables',
				isCorrect: false
			},
			{
				answerText: 'Amazon VPC network ACLs',
				isCorrect: false
			},
			{
				answerText: 'Amazon Web Application Firewall (WAF)',
				isCorrect: true
			}
		]
	},
	{
		id: 28,
		questionText:
			'Which AWS feature can be used to launch a pre-configured Amazon Elastic Compute Cloud (EC2) instance?',
		answerOptions: [
			{
				answerText: 'Amazon Elastic Block Store (EBS)',
				isCorrect: false
			},
			{
				answerText: 'Amazon EC2 Systems Manager',
				isCorrect: false
			},
			{
				answerText: 'Amazon Machine Image (AMI)',
				isCorrect: true
			},
			{
				answerText: 'Amazon AppStream 2.0',
				isCorrect: false
			}
		]
	},
	{
		id: 29,
		questionText: 'AWS are able to continually reeduce their pricing due to:',
		answerOptions: [
			{
				answerText: 'Pay-as-you go pricing',
				isCorrect: false
			},
			{
				answerText: 'Elastic compute services',
				isCorrect: false
			},
			{
				answerText: 'Compute savings plan',
				isCorrect: false
			},
			{
				answerText: 'Economics of scale',
				isCorrect: true
			}
		]
	},
	{
		id: 30,
		questionText: 'An Amazon Virtual Private Cloud (VPC) can include multiple:',
		answerOptions: [
			{
				answerText: 'Availability Zones',
				isCorrect: true
			},
			{
				answerText: 'Edge locations',
				isCorrect: false
			},
			{
				answerText: 'AWS Regions',
				isCorrect: false
			},
			{
				answerText: 'Internet gateways',
				isCorrect: false
			}
		]
	}
];

export default questions;

import Settings from './screens/pages/Settings/Settings';
import Dashboard from './screens/pages/Dashboard/Dashboard';
import Coding from './screens/pages/Coding/components/Landing';
import Certificate from './screens/pages/Quiz/Certificate/Certificate';
import TotalQuiz from './screens/pages/Quiz/CourseContent/TotalQuiz';
import CourseContent from './screens/pages/Quiz/CourseContent/CourseContent';
import InnovationChallenge from './screens/pages/Innovation/InnovationChallenge';
import CorrectAnswers from './screens/pages/Quiz/CourseContent/CorrectAnswers';
import CodingChallenge from './screens/pages/Coding/components/LanguagesDropdown';
import LeaderBoard from './screens/pages/Coding/components/Leaderboard/Leaderboard';
import AvailableCourses from './screens/pages/courses/AvailableCourses/AvailableCourses';

export const RouteLinks = [
  {
    element: <Dashboard />,
    path: '/courses',
  },
  {
    element: <AvailableCourses />,
    path: '/dashboard',
  },
  {
    element: <Coding />,
    path: '/coding',
  },
  {
    element: <CodingChallenge />,
    path: '/codingChallenge',
  },
  {
    element: <LeaderBoard />,
    path: '/leaderboard',
  },
  {
    element: <InnovationChallenge />,
    path: '/innovationChallenge',
  },
  {
    element: <Settings />,
    path: '/settings',
  },
  {
    element: <CourseContent />,
    path: '/exam',
  },
  {
    element: <TotalQuiz />,
    path: '/examResult',
  },
  {
    element: <CorrectAnswers />,
    path: '/correctAnswer',
  },
  {
    element: <Certificate />,
    path: '/certificate',
  },
];

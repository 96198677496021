import ewere from '../../../../assets/images/course_instructors/Ewere.png';
import simi from '../../../../assets/images/course_instructors/Simisola.png';
import ben from '../../../../assets/images/course_instructors/Benjamin.png';
import kehinde from '../../../../assets/images/course_instructors/Kehinde.png';
import mayowa from '../../../../assets/images/course_instructors/Mayowa.png';
import People from '../../../../assets/images/course_instructors/placeholderimage.jpg';

export const videoList = [
  {
    videoLink: '/automated-software-testing',
    videoCategory: 'Automated-Software-Testing-Using-Selenium',
    videoName: 'Automated Software Testing Using Selenium',
    videoDescription: `  Selenium is a web testing tool which uses simple scripts to run
                tests directly within a browser. In simple terms, “it automates
                browsers”.. Do you want to master the automated process of testing
                using Selenium?
                <br /> <br />
                We are here to guide you on the journey to mastery.`,
    videoLists: [
      'Module 1. Introduction of the course',
      'Module 2. Core Java Essentials for Selenium',
      'Module 3. Selenium IDE',
      'Module 4. Selenium RC Remote Control 1_0 API Deprecated',
      'Module 5. Xpath VS CSS Locators',
      'Module 6. Selenium Webdriver 2_0 Basics',
      'Module 7. Selenium Webdriver 2_0 Advance',
    ],
    instructor: [
      {
        image: People,
        name: 'Rahman',
        occupation: 'Lead Automation Engineer',
      },
    ],
    doesVideoHaveSub: true,
  },
  {
    videoLink: '/ai-powered-natural-language-processing-with-aws-comprehend',
    videoCategory: 'AI-Powered-Natural-Language-Processing-with-AWS-Comprehend',
    videoName: ' AI-Powered Natural Language Processing with AWS Comprehend',
    videoDescription: ` Amazon Comprehend is a natural-language processing (NLP) service
                that uses machine learning to uncover valuable insights and
                connections in text. Are you ready to take the next step of your
                career?
                <br /> <br />
                We are here to help you start your journey to AI-Powered Natural
                Language Processing with AWS Comprehend. We focus on helping you
                understand the cloud. Once you understand cloud well, you will be
                able to use your skills on AWS or any cloud platform.`,
    videoLists: [],
    instructor: [],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/intro-to-version-control',
    videoCategory: 'Introduction-to-version-control-Git-Github',
    videoName: 'Introduction To Version Control With Git/GitHub',
    videoDescription: `    Git is an essential tool for work in any code-related field, from
              data science to game development to machine learning. This course
              covers everything you need to know to start using Git and Github
              in the real-world today! We start off with Git Essentials. The
              goal of this unit is to give you all the essential Git tools you
              need for daily use. We start off talking about version control
              software, what it is, why it matters, and the history of Git.`,
    videoLists: [
      'Introduction to Version control system',
      'Installing Git on Windows',
      'Git Commands',
      'Creating Account on GitHub and Pushing code to the remote repository',
    ],
    instructor: [],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/edge-computing',
    videoCategory: 'Edge-Computing',
    videoName:
      'Edge Computing Driving: The Next Evolution of Internet Technology',
    videoDescription: `  This conference or lecture will help you overcome critical
            challenges - reducing latency, building quality infrastructure, and
            decreasing costs - with real-time data processing. Learn from key
            players in the Cloud ecosystem and build partnerships that will
            drive edge computing to the next level.`,
    videoLists: [
      'Introduction to Cloud Computing and Edge Computing(Xpress Payments Limited)',
      'Business Problems that can be solved using Edge Computing (Co-Founder and CTO Datacrest)',
      'Edge and its use cases (AWS Community Hero And Head of Infrastructure and Data Protection Indicina)',
    ],
    instructor: [
      {
        image: ewere,
        name: 'Ewere Diagboya',
        occupation: 'Cloud Engineer',
      },
      {
        image: simi,
        name: 'Simisola Saheyi',
        occupation: 'IT Infrastructure Lead',
      },
      {
        image: kehinde,
        name: 'Kehinde Olashore',
        occupation: 'CTO (Datacrest Technologies)',
      },
    ],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/python-for-data-science',
    videoCategory: 'Python-for-Data-Science',
    videoName: 'Python For Data Science',
    videoDescription: `  Are you ready to start your path to becoming a Data Scientist! This
            comprehensive course will be your guide to learning how to use the
            power of Python to analyze data. Data Scientist has been ranked the
            number one job on Glassdoor and the average salary of a data
            scientist is over $120,000 in the United States according to Indeed!
            Data Science is a rewarding career that allows you to solve some of
            the world's most interesting problems! This course is designed for
            beginners with no or some programming experience.`,
    videoLists: [
      'Introduction to Python in Data Science',
      'Arithmetic Functions',
      'Defining Storing Variables and Datatypes',
      'Working with Data Types',
      'PRACTICE',
      'Introduction to Lists',
      'SLICING',
      'Accessing List Values',
      'Sub setting Lists',
      'Advanced List Operations',
      'Built in Functions',
      'Built in Functions part 2',
      'Function Arguments',
      'Function Arguments Part 2',
      'Introduction to String Methods',
      'Introduction to String Methods Part 2',
      'Importing Python Packages',
      'Introduction to String Methods Part 3',
      'Subsetting and Comparing Arrays',
      'Introduction to NumPy Arrays',
      'Subsetting and Comparing Arrays',
    ],
    instructor: [
      {
        image: People,
        name: 'Saheb Singh Chadha',
        occupation: 'Data Scientist & Big Data Expert',
      },
    ],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/networking-ccna',
    videoCategory: 'Networking-CCNA',
    videoName: 'Networking CCNA',
    videoDescription: `  This course would introduce you to the world of networking. The
            Course would prepare you to achieve the Cisco CCNA certification.
            The course would start with the very basic of networking and
            thereafter go further deep dive into core networking. The course is
            good for Cisco CCENT and CCNA certification candidates or anyone
            interested in learning networking. In addition, the course is also
            useful to anyone interested in entering a career in network
            technologies.`,
    videoLists: [
      'Introduction',
      'TCP IP addressing',
      'Subnetting FLSM',
      'Subnetting VLSM',
      'Subnetting Questions',
      'Introduction to Cisco-Routers',
      'Basic Commands',
      'Dynamic Routing RIPv2',
      'Dynamic Routing EIGRP',
      'Dynamic Routing OSPF',
      'Basic Switching',
      'VLAN And Trunking',
      'VLAN Trunking protocol',
      'Spanning Tree Protocol',
      'STP optimization',
      'Access control List',
      'Network Address Translation',
      'IPv6 addressing',
      'OSI Model and TCPIP protocols',
      'TCP IP addressing',
      'Troubleshooting Switching',
      'Troubleshooting Routing',
      'Advance Security',
      'IP services',
    ],
    instructor: [
      {
        image: People,
        name: 'Sikandar Shaik',
        occupation: 'Cisco Trainer/Network Consultant',
      },
    ],
    doesVideoHaveSub: true,
  },
  {
    videoLink: '/machine-learning',
    videoCategory: 'Machine-learning',
    videoName: 'Machine Learning',
    videoDescription: `  Learn Machine and Data Science, then get hired. This comprehensive
            and project based course will introduce you to all of the modern
            skills of a Data Scientist and along the way, we will build many
            real world projects to add to your portfolio.
            <br />
            <br />
            Data Scientist has been ranked the number one job on Glassdoor and
            the average salary of a data scientist is over $120,000 in the
            United States according to Indeed! Data Science is a rewarding
            career that allows you to solve some of the world's most interesting
            problems.`,
    videoLists: [
      'Introduction to AI',
      'Introduction to ML - Supervised and Unsupervised Learning',
      'ML KNN - Lp Norms',
      'ML KNN - Euclidean and Manhattan Distance',
      'ML KNN - Minkowski, Hamming and Cosine Distance',
      'ML Over and Under Fitting - Cross Validation and K-Fold CV',
      'Project 1 Creating the First Model using KNN and finding the Accuracy',
      'ML Linear Regression ',
      'Project 2 based on SIMPLE LINEAR REGRESSION',
      'Project 3 based on MULTIPLE LINEAR REGRESSION',
      'ML HYPOTHESIS TESTING Statistics Fundamentals',
      'ML Decision Tree with Gini Index',
      'ML Decision Tree with Information Gain',
      'Project 4 CASE STUDY based on DECISION TREE',
    ],
    instructor: [
      {
        image: People,
        name: 'Saheb Singh Chaddha',
        occupation: 'Big Data Expert & Data Scientist',
      },
    ],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/no-sql-and-mongoDB',
    videoCategory: 'No-SQL-and-MongoDB',
    videoName: 'Non Relational Databases - NoSQL And MongoDB',
    videoDescription: `  This course will help you to master one of the most popular NoSQL
            databases MongoDB. In this course, we will start by learning basic
            concepts non-relational databases, core concepts of MongoDB, MongoDB
            installation process on different operating systems, creating
            databases, Collections, CRUD opertaions and Indexing. This course is
            designed to provide knowledge and hands on skills to become a
            successful MongoDB expert.`,
    videoLists: [
      'Introduction to NoSQL Database',
      'Getting started installation configuration',
      'Basics of MongoDB',
      'MongoDB CRUD Operations',
      'Performance tuning with indexes in MongoDB',
      'ObjectIds in MongoDB',
      'Aggregation framework in mongoDB',
      'Data modelling in MongoDB',
      'Relationships in MongoDB',
      'User role management in MongoDB',
      'Clarification Section Course discussions',
      'Reguler expressions in MongoDB',
      'MapReduce in MongoDB',
      'MongoDB samples',
    ],
    instructor: [],
    doesVideoHaveSub: true,
  },
  {
    videoLink: '/data-science-and-ml-with-r',
    videoCategory: 'Complete-Data-Science-and-Machine-learning-with-R',
    videoName: 'Complete Data Science And Machine Learning With R',
    videoDescription: ` This course has been designed by professional Data Scientists so
            that we can share their knowledge and help you learn complex theory,
            algorithms, and coding libraries in a simple way. The course would
            walk you step-by-step into the World of Machine Learning. With every
            tutorial, you will develop new skills and improve your understanding
            of this challenging yet lucrative sub-field of Data Science. This
            course is fun and exciting, but at the same time, we dive deep into
            Machine Learning. Data Scientist has been ranked the number one job
            on Glassdoor and the average salary of a data scientist is over
            $120,000 in the United States according to Indeed! Data Science is a
            rewarding career that allows you to solve some of the world's most
            interesting problems!.`,
    videoLists: [
      'Introduction',
      'Hands on R Programming',
      'Machine learning Fundamentals',
      'Data visualisation with R',
      'Applied statistics for ML',
      'ML fundamentals',
      'ANOVA With R',
      'Logistic Regression with R',
      'Dimension reduction technique with principal component analysis',
      'Clustering With K Means',
      'Tree based models CART technique and Random-Forest',
      'KNN K Nearest Model',
      'Naive Bayes',
      'Neural Networks With R',
    ],
    instructor: [
      {
        image: People,
        name: 'Akhilendra Singh',
        occupation: 'Business Consultant and Analyst',
      },
    ],
    doesVideoHaveSub: true,
  },
  {
    videoLink: '/databases-and-postgreSQL',
    videoCategory: 'Databases-and-PostgreSQL',
    videoName: 'Relational Databases And PostgreSQL',
    videoDescription: ` Every app in the world needs to store information in a database.
            Although there are many different databases you can use, PostgreSQL
            has been a number-one pick for decades, offering scalable
            performance, rock-solid uptime, and flexible design systems. This
            course will teach you everything you need to know about PostgreSQL
            to use it on your next big project!.`,
    videoLists: [
      'Introduction',
      'Retrieve data using select statement',
      'Data filter in select',
      'Data exploration using select',
      'Joins',
      ' Set Operators',
      'Aggregate function',
      'Group Data',
      'Build compress quieries',
      'String and number builtin functions',
      'Date and time built in functions',
      'Datatype conversion builtin functions',
      'Sub-query',
      'DML Operations',
    ],
    instructor: [
      {
        image: People,
        name: 'Prathap Ramakrishna Reddy',
        occupation: '',
      },
    ],
    doesVideoHaveSub: true,
  },
  {
    videoLink: '/devops-with-ansible',
    videoCategory: 'Devops',
    videoName: 'Devops With Ansible',
    videoDescription: `Are you just started working on your DevOps career? Or looking to
            learn and transition to devops? Then this course is for you. Ansible
            for DevOps course would help you to understand how Ansible works and
            automate your day to day activities. The course would discuss
            various topics like ad-hoc commands, playbooks, variables,
            inventory, roles with examples.`,
    videoLists: [
      'Introduction to Ansible',
      'How to work with Ansible and AWS',
      'Dynamic Inventories, playbooks, variables',
      'Managing and securing information',
      'Speed up your Ansible run times',
    ],
    instructor: [
      {
        image: People,
        name: 'Probin',
        occupation: 'Devops Engineer & Enthusiast',
      },
    ],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/leveraging-cloud-solutions',
    videoCategory: 'Leveraging-cloud',
    videoName: 'Leveraging Cloud Solutions For Business Growth',
    videoDescription: `  This course is a webinar delivered by FinTech companies leveraging
            cloud for business growth. Learn how Cloud Computing is leveraged to
            boost innovation, agility and application development, as well as
            reducing infrastructure costs by 20-30% from CEOs and CTOs
            discussing actual industry use-cases. Leveraging cloud security and
            resilience aspects can also reduce costs of downtime and breaches.`,
    videoLists: [
      'Cloud Adoption and Real-Time Cases( CTO - Patricia Technologies)',
      'Cloud Adoption and Real-Time Cases(Head of Engineering - RenMoney)',
      'Cloud Adoption and Cloud Financial Management (AWS Community Hero And Head of Infrastructure and Data Protection Indicina)',
    ],
    instructor: [
      {
        image: ewere,
        name: 'Ewere Diagboya',
        occupation: 'Cloud Engineer',
      },
      {
        image: ben,
        name: 'Benjamin Oyemonlan',
        occupation: 'CTO, Patricia Technologies',
      },
      {
        image: mayowa,
        name: 'Mayowa Anibaba',
        occupation: 'Head of Engineering, Renmoney',
      },
    ],
    doesVideoHaveSub: false,
  },
  {
    videoLink: 'kubernetes',
    videoCategory: 'Kubernetes',
    videoName: 'Kubernetes',
    videoDescription: `  Kubernetes is a portable, extensible, open-source platform for
            managing containerized workloads and services, that facilitates both
            declarative configuration and automation. It has a large, rapidly
            growing ecosystem. This course will teach you everything you need to
            know about Kubernetes to use it on your next big project!.`,
    videoLists: [
      'Introduction',
      'Kubernetes Deployment',
      'Kubernetes Networking',
      'Autoscaling in Kubernetes',
      'Usecases in Kubernetes',
      'Kubernetes Commands Walkover',
      'Networking Services',
      'Rolling over updates and new features',
      'Kubernetes best practices and istio best practices',
    ],
    instructor: [],
    doesVideoHaveSub: true,
  },
  {
    videoLink: '/unix-shell-scripting',
    videoCategory: 'Unix-shell-scripting',
    videoName: 'Unix Shell Scripting',
    videoDescription: `  Unix Shell Scripting is often a must have skill for any IT
            Professional. In most job profile, you will find it mandatory to
            have skills to Work in Unix or Linux Environment. In today's
            competitive environment, companies need professionals who are having
            good understand of Unix/Linux command and shell scripting. In
            Telecom, Big Data processing and Cloud Computing Domain, Unix
            commands and shell scripting are basic prerequisite to start
            working. - Here we will cover Unix commands which we can use in
            daily activities and also we have covered many interview question
            which are asked in interviews.`,
    videoLists: [
      'Unix Basics',
      'Basic commands',
      'Check Log Files',
      'Basic files and folder operation',
      'File Search',
      'Content Search',
      'Memory related commands',
      'CPU Processing',
      'Merging of command outputs',
      'Practice questions',
    ],
    instructor: [
      {
        image: People,
        name: 'Kumar',
        occupation: 'Oracle DBA - Architect & Consultant',
      },
    ],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/core-java-essentials',
    videoCategory: 'Core-java-essentials',
    videoName: 'Java Programming',
    videoDescription: `  Java is one of the most popular programming languages. Java offers
            both object-oriented and functional programming features. We take a
            hands-on approach. This course assumes no previous programming or
            Java experience. If you never programmed a computer before, or if
            you already have experience with another programming language and
            want to learn Java, this is a perfect course for you. The course for
            individuals with ZERO programming experience and want to learn Java
            Programming or a Beginner at Java Programming and want to Learn to
            write Great Java Programs.`,
    videoLists: [
      'Introduction to java and installing Java Windows OS',
      'MAC-OS-X Installing Java and configuring enviornment variables',
      'Classes and Objects',
      'Basic Java programming and features Part 1',
      'Basic Java programming and features Part 2',
      'Basic Java programming and features Part 3',
      'Java Methods Part 1',
      'Java Methods Part 2',
      'Java Methods Part 3',
      'Java Methods Part 4',
      'Java Methods Part 5 Predefined Methods',
      'Java Methods Part 6 Static and Non Static Mathods',
      'IF and Else Statements',
      'Loops While Loop',
      'Loops Do While Loop',
      'Loops For Loop',
      'Loops Nested Loops',
      'Loops Break and Continue statements',
      'If Else statements and Switch Case',
      'Arrays and Methods',
      'OOPS Static and Non Static',
      'OOPS Part 2',
      'OOPS Object oriented programming Part 3',
      'Exception Handling Part 1',
      'Exception Handling Part 2',
      'Packages and Access Modifiers',
      'Reading Properties files',
      'Java Streams Writing in TXT and CSV files',
      'Java Stream Writing HTML Files and Reading files',
      'Java Stream Apache POI Creating and Writing Excel Files',
      'Classes and Objects',
      'Java Stream Apache POI Reading Excel Files Extended Loops',
      'Reading Notepad file',
      'Reading Excel Files and Generating logs through Log4J API',
      'Java Collections',
      'Reflection API',
      'Difference between equal to operator and Singleton design pattern',
      'Apache Log4J API Console and File Appenders',
      'Apache Log4J API SMTPAppender',
      'Apache Log4J API HTMLAppender',
    ],
    instructor: [],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/intro-to-continous-integration-ci-jenkins',
    videoCategory: 'Introduction-to-Continous-Integration-CI-Jenkins',
    videoName: 'Introduction To Continous Integration CI Jenkins',
    videoDescription: `Jenkins is a powerful and flexible automation tool; It is used to
            automate almost anything. Learn how to build strong automated
            processes like Pipelines, CI/CD Workflows and more! In the DevOps
            World, Jenkins is one of the best and most popular tools. This
            course is for you if you interested in DevOps and Jenkins.`,
    videoLists: [
      'Module 1. Introduction to Continuous Integration and configuring Jenkins',
      'Module 2. Creating a free style Jenkins project',
      'Module 3. Jenkins execution with Batch file',
      'Module 4. Executing Appium Test through Jenkins',
      'Module 5. Configuring Emails and Test Reports with Jenkins',
    ],
    instructor: [],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/big-data-with-spark',
    videoCategory: 'Bigdata-with-Spark',
    videoName: 'Big Data With Spark',
    videoDescription: ` Spark and Scala work together to analyze big data. Spark, however is
            unique in providing batch as well as streaming capabilities, thus
            making it a preferred choice for lightening fast Big Data Analysis
            platforms. Scala and Spark are two of the most in demand skills
            right now, and with this course you can learn them quickly and
            easily! This course comes packed with content.`,
    videoLists: [
      'Module 1. Introduction to Scala',
      'Module 2. Spark Practicals',
      'Module 3. Spark SQL',
      'Module 4. Spark SQL Practicals',
      'Module 5. Kafka',
      'Module 6. Kafka Practicals',
      'Module 7. Spark Streaming',
      'Module 8. Spark Streaming Practicals',
    ],
    instructor: [
      {
        image: People,
        name: 'Gaurav',
        occupation: '',
      },
    ],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/big-data-beginner-hadoop-ecosystem',
    videoCategory: 'Bigdata-Beginner-Hadoop-eco-system',
    videoName: 'Big Data Beginner Hadoop Ecosystem',
    videoDescription: `Do you find Big Data and Hadoop confusing? Aspire to become a Big
            Data or Hadoop professional? But not sure which course to enroll?
            Then this course is for you ! In this course you will learn Big Data
            using the Hadoop Ecosystem.  <br/><br/> Why Hadoop? It is one of the most sought
            after skills in the IT industry. The average salary in the US is
            $112,000 per year, up to an average of $160,000 in San Fransisco
            (source: Indeed). You will learn how to use the most popular
            software in the Big Data industry at moment, using batch processing
            as well as realtime processing. This course will give you enough
            background to be able to talk about real problems and solutions with
            experts in the industry.`,
    videoLists: [
      'Module 1. Hadoop introduction',
      'Module 2. Hadoop introduction Part 2',
      'Module 3. Python Practicals',
      'Module 4. Linux Practicals',
      'Module 5. HDFS',
      'Module 6. HDFS Practicals',
      'Module 7. MapReduce',
      'Module 8. MapReduce Practicals',
      'Module 9. Pig',
      'Module 10. Pig Practical',
      'Module 11. Hive',
      'Module 12. Hive Practicals',
    ],
    instructor: [],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/big-data-advanced-hadoop-ecosystem',
    videoCategory: 'Bigdata-Advanced-Hadoop-eco-system',
    videoName: 'Big Data Advanced Hadoop Ecosystem',
    videoDescription: `Aspiring to become a Big Data or Hadoop professional? But not sure
            which course to enroll? Then this course is for you ! This Course is
            a conitnuation of the Big Data Beginner Hadoop Ecosystem. In this
            course you will learn Big Data using the Hadoop Ecosystem. <br/><br/>Why
            Hadoop? It is one of the most sought after skills in the IT
            industry. The average salary in the US is $112,000 per year, up to
            an average of $160,000 in San Fransisco (source: Indeed). You will
            learn how to use the most popular software in the Big Data industry
            at moment, using batch processing as well as realtime processing.
            This course will give you enough background to be able to talk about
            real problems and solutions with experts in the industry.`,
    videoLists: [
      'Module 1. BigData and NoSQL',
      'Module 2. BigData and NoSQL Part 2',
      'Module 3. Hbase',
      'Module 4. Hbase 2',
      'Module 5. Hbase Practical',
      'Module 6. Sqoop',
      'Module 7. Sqoop Practicals',
      'Module 7. Flume',
      'Module 7. Flume Practical',
      'Module 7. Oozie',
      'Module 7. Oozie Practicals',
    ],
    instructor: [],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/aws-certified-solutions-architect-associate',
    videoCategory: 'AWS-Certified-Solutions-Architect-Associate',
    videoName: 'AWS Certified Solutions Architect Associate',
    videoDescription: `AWS Certified Solutions Architect - Associate showcases knowledge
            and skills in AWS technology, across a wide range of AWS services.
            The focus of this certification is on the design of cost and
            performance optimized solutions, demonstrating a strong
            understanding of the AWS Well-Architected Framework.
            <br><br> Do you want to become an AWS Certified Cloud Practitioner? Are you
            ready to take the next step of your career? We are here to help you
            start your journey to AWS Certified Solutions Architect - Associate.`,
    videoLists: [],
    instructor: [],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/microsoft-sharepoint',
    videoCategory: 'Microsoft_sharepoint',
    videoName: 'Microsoft Sharepoint',
    videoDescription: ` This course introduces you to the basics of cloud computing and
            Azure <br><br>
            We are here to guide you on the journey to mastery.`,
    videoLists: [
      'Module 1. Introduction of the course',
      'Module 2. Azure Core Services',
      'Module 3. Cloud Concepts',
      'Module 4. Azure Core Solutions',
      'Module 5. Security in Azure',
      'Module 6. Identity governance privacy and compliance',
      'Module 7. Pricing Cost SLAs',
    ],
    instructor: [
      {
        image: People,
        name: 'Adekunle Sanyaolu',
        occupation: 'Cloud Engineer(Devops) at PowerApps, Microsoft Teamwork',
      },
    ],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/aws-certified-cloud-practitioner',
    videoCategory: 'aws-certified-cloud-practitioner',
    videoName: 'AWS Certified Cloud Practitioner',
    videoDescription: `AWS Certified Cloud Practitioner is an easy certification to get
            your journey started with AWS. Do you want to become an AWS
            Certified Cloud Practitioner? Are you ready to take the next step of
            your career? <br /> <br />
            We are here to help you start your journey to AWS Certified Cloud
            Practitioner. Cloud Computing is the future. We focus on helping you
            understand the cloud. Once you understand cloud well, you will be
            able to use your skills on AWS or any cloud platform.`,
    videoLists: [
      'Module 1. Introduction To Cloud Computing',
      'Module 2. Amazon Web Services Overview',
      'Module 3. AWS Global Infrastucture',
      'Module 4. Compute Services',
      'Module 5. Storage Services',
      'Module 6. Networking Services',
      'Module 7. Database Services',
      'Module 8. Security and Compliance Services',
      'Module 9. Cost Management Services',
      'Module 10. Management and Governance Service',
      'Module 11. Cloud Deployment Services',
    ],
    instructor: [
      {
        image: People,
        name: 'Samuel Joseph',
        occupation: 'Cloud Engineer & AWS Solutions Architect',
      },
    ],
    doesVideoHaveSub: false,
  },
  {
    videoLink: '/microsoft-azure-fundamentals',
    videoCategory: 'Microsoft-Azure-Fundamentals',
    videoName: 'Microsoft Azure Fundamentals',
    videoDescription: `This course introduces you to the basics of cloud computing and
            Azure <br><br>
            We are here to guide you on the journey to mastery.`,
    videoLists: [
      'Module 1. Introduction of the course',
      'Module 2. Azure Core Services',
      'Module 3. Cloud Concepts',
      'Module 4. Azure Core Solutions',
      'Module 5. Security in Azure',
      'Module 6. Identity governance privacy and compliance',
      'Module 7. Pricing Cost SLAs',
    ],
    instructor: [
      {
        image: People,
        name: 'Raphael Gab-Momoh',
        occupation: 'Cloud Engineer',
      },
    ],
    doesVideoHaveSub: false,
  },
];

const questions = [
	{
		id: 1,
		questionText: `Personnel assigned to command level knowledge management centers supports all bases and tenant organizations within their command.`,
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: true
			},
			{
				answerText: 'False',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText: 'Who is responsible for awareness of organizational site best practices and standards?',
		answerOptions: [
			{
				answerText: 'Command Knowledge Management Center.',
				isCorrect: true
			},
			{
				answerText: 'Base Communications Squadron Commander',
				isCorrect: false
			},
			{
				answerText: 'Office of Primary Responsibility',
				isCorrect: false
			},
			{
				answerText: 'Chief of Information Dominance and Information Officer',
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: `The purpose of a _________________ is to provide information management capabilities for specific purposes and scenarios`,
		answerOptions: [
			{
				answerText: 'Sharepoint library',
				isCorrect: false
			},
			{
				answerText: 'Sharepoint task',
				isCorrect: false
			},
			{
				answerText: 'Sharepoint site',
				isCorrect: true
			},
			{
				answerText: 'Sharepoint list',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText: 'Which backup is performed weekly and the data retained for 7 days?',
		answerOptions: [
			{
				answerText: 'Differential backup',
				isCorrect: false
			},
			{
				answerText: 'Full backup',
				isCorrect: true
			},
			{
				answerText: 'Daily backup',
				isCorrect: false
			},
			{
				answerText: 'Partial backup',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText:
			'Where will a list of file extensions with potential vulnerabilities or security risks be published?',
		answerOptions: [
			{
				answerText: 'SharePoint handbook',
				isCorrect: false
			},
			{
				answerText: 'Site administrator handbook',
				isCorrect: false
			},
			{
				answerText: 'Base publications and technical orders',
				isCorrect: false
			},
			{
				answerText: 'MAJCOM instructions or technical orders',
				isCorrect: true
			}
		]
	},
	{
		id: 6,
		questionText: 'The site navigation reflects the relationship among the sites in the site collection',
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: true
			},
			{
				answerText: 'False',
				isCorrect: false
			}
		]
	},
	{
		id: 7,
		questionText: `What determines the types of fields displayed on a SharePoint site?`,
		answerOptions: [
			{
				answerText: 'Collaborative tools',
				isCorrect: false
			},
			{
				answerText: 'Content type',
				isCorrect: true
			},
			{
				answerText: 'Page primary purpose',
				isCorrect: false
			},
			{
				answerText: `Site navigation`,
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText: 'Which of the following will a site owner use to create a unique page for a site?',
		answerOptions: [
			{
				answerText: `Site theme`,
				isCorrect: false
			},
			{
				answerText: `Site navigation`,
				isCorrect: false
			},
			{
				answerText: `Menu heading`,
				isCorrect: false
			},
			{
				answerText: `Web part`,
				isCorrect: true
			}
		]
	},
	{
		id: 9,
		questionText: 'Which of the following terms is defined as a series of tasks that produce an outcome?',
		answerOptions: [
			{
				answerText: `Feedback Collection`,
				isCorrect: false
			},
			{
				answerText: `Workflow`,
				isCorrect: true
			},
			{
				answerText: `Content managament`,
				isCorrect: false
			},
			{
				answerText: `Active Report`,
				isCorrect: false
			}
		]
	},
	{
		id: 10,
		questionText: 'What allows items to be restored that has been deleted from a site?',
		answerOptions: [
			{
				answerText: 'Calendar',
				isCorrect: false
			},
			{
				answerText: 'Workflow',
				isCorrect: false
			},
			{
				answerText: 'Recycle Bin',
				isCorrect: true
			},
			{
				answerText: 'Library',
				isCorrect: false
			}
		]
	},
	{
		id: 11,
		questionText:
			'Who oversees the implementation of SharePoint as an Enterprise Information Management (EIM) program?',
		answerOptions: [
			{
				answerText: 'Communication Squadron Commander',
				isCorrect: false
			},
			{
				answerText: 'AF Departmental Publishing Officers',
				isCorrect: false
			},
			{
				answerText: 'Director of Communications at MAJCOMs',
				isCorrect: true
			},
			{
				answerText: 'Unit Commanders',
				isCorrect: false
			}
		]
	},
	{
		id: 12,
		questionText: 'Who appoints a primary and an alternate base site owner for the EIM SharePoint program?',
		answerOptions: [
			{
				answerText: 'Wing Communications Squadron Commander',
				isCorrect: false
			},
			{
				answerText: 'Squadron Commander',
				isCorrect: false
			},
			{
				answerText: 'Group Commander',
				isCorrect: false
			},
			{
				answerText: 'Base Communications Squadron Commander ',
				isCorrect: true
			}
		]
	},
	{
		id: 13,
		questionText:
			'SharePoint sites are for official use only and in accordance with AFMAN 33-363, Management of Records, and are not to be used as',
		answerOptions: [
			{
				answerText: 'document workspaces',
				isCorrect: false
			},
			{
				answerText: 'document libraries',
				isCorrect: false
			},
			{
				answerText: 'Workflow',
				isCorrect: false
			},
			{
				answerText: 'Record repositories',
				isCorrect: true
			}
		]
	},
	{
		id: 14,
		questionText: 'SharePoint sites are structured as site',
		answerOptions: [
			{
				answerText: 'cells',
				isCorrect: false
			},
			{
				answerText: 'collections',
				isCorrect: true
			},
			{
				answerText: 'dusters',
				isCorrect: false
			},
			{
				answerText: 'groups',
				isCorrect: false
			}
		]
	},
	{
		id: 15,
		questionText: 'What is the part of graphic design that arranges visual elements on a site?',
		answerOptions: [
			{
				answerText: 'Site/page layout',
				isCorrect: true
			},
			{
				answerText: 'Page primary purpose',
				isCorrect: false
			},
			{
				answerText: 'Collaborative tools',
				isCorrect: false
			},
			{
				answerText: 'Site navigation',
				isCorrect: false
			}
		]
	},
	{
		id: 16,
		questionText:
			'What are the pre-designed elements such as fonts, color schemes, layout, and background pictures within SharePoint?',
		answerOptions: [
			{
				answerText: 'Templates',
				isCorrect: false
			},
			{
				answerText: 'Web parts',
				isCorrect: false
			},
			{
				answerText: 'Fonts',
				isCorrect: false
			},
			{
				answerText: 'Themes',
				isCorrect: true
			}
		]
	},
	{
		id: 17,
		questionText:
			'Site owners can remove inheritance permissions for any lower level site or object and grant users permission to perform specific acitons',
		answerOptions: [
			{
				answerText: 'True',
				isCorrect: true
			},
			{
				answerText: 'False',
				isCorrect: false
			}
		]
	},
	{
		id: 18,
		questionText: 'Which of the following streamline cost and time required to coordinate common processes?',
		answerOptions: [
			{
				answerText: 'Content Management',
				isCorrect: false
			},
			{
				answerText: 'Active Report',
				isCorrect: false
			},
			{
				answerText: 'Workflow',
				isCorrect: true
			},
			{
				answerText: 'Feedback Collection',
				isCorrect: false
			}
		]
	},
	{
		id: 19,
		questionText: 'Reset to Site definition removes what from a single page or all pages in a site?',
		answerOptions: [
			{
				answerText: 'Web Parts',
				isCorrect: false
			},
			{
				answerText: 'All customizations',
				isCorrect: true
			},
			{
				answerText: 'Links',
				isCorrect: false
			},
			{
				answerText: 'An image',
				isCorrect: false
			}
		]
	},
	{
		id: 20,
		questionText: 'Items will automatically be emptied after how many days?',
		answerOptions: [
			{
				answerText: '20 Days',
				isCorrect: false
			},
			{
				answerText: '25 Days',
				isCorrect: false
			},
			{
				answerText: '30 Days',
				isCorrect: true
			},
			{
				answerText: '35 Days',
				isCorrect: false
			}
		]
	}
];

export default questions;

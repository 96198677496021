const questions = [
  {
    id: 1,
    questionText: 'What are some of the limitations with a free Azure account?',
    answerOptions: [
      {
        answerText: 'Azure free accounts are only valid in certain promotional periods, such as when new services are launched',
        isCorrect: false
      },
      {
        answerText: ' You can only create a free Azure account with a US address',
        isCorrect: false
      },
      {
        answerText: 'A free account only allows access to all services for 30 days, after which only free services are available',
        isCorrect: false
      },
      {
        answerText: 'Included Azure credits will expire after 30 days, and included free services expire after 12 months',
        isCorrect: true
      },
      {
        answerText: 'An Azure free account is meant to evaluate Azure services and is not meant for production',
        isCorrect: false
      }
    ]
  },
  {
    id: 2,
    questionText:
      'What are three uses for the Azure mobile app? choose 3',
    answerOptions: [
      {
        answerText: 'Respond to outages and emergencies from anywhere you have an internet connection',
        isCorrect: true
      },
      {
        answerText: 'It is an alternative to using the Azure Portal for everyday tasks such as creating resources and analyzing Azure Monitor logs',
        isCorrect: true
      },
      {
        answerText: 'Accessing a small portion of the Azure Portal on the go	',
        isCorrect: false
      },
      {
        answerText: 'Maintain an insight on the go into the current status and health of your Azure environment',
        isCorrect: true
      }
    ]
  },
  {
    id: 3,
    questionText: 'Which Azure products and services are available through the Azure Portal? Choose the correct option',
    answerOptions: [
      {
        answerText: "All products and services that are generally available and in private or public preview",
        isCorrect: true
      },
      {
        answerText: 'Only products that are not in private or public preview',
        isCorrect: false
      },
      {
        answerText: "Only products and services that aren't free",
        isCorrect: false
      },
      {
        answerText: 'Only products that are globally available.',
        isCorrect: false
      },
    ]
  },
  {
    id: 4,
    questionText:
      'What are some of the key advantages of using ARM templates for creating cloud infrastructure? choose 3',
    answerOptions: [
      {
        answerText: 'Source Control',
        isCorrect: true
      },
      {
        answerText: 'Idempotency',
        isCorrect: true
      },
      {
        answerText: 'Service integrations',
        isCorrect: false
      },
      {
        answerText: 'Faster processing in the Azure Resource Manager',
        isCorrect: false
      },
      {
        answerText: 'Declarative',
        isCorrect: true
      },
    ]
  },
  {
    id: 5,
    questionText:
      'Why would you use the Azure Cloud Shell?',
    answerOptions: [
      {
        answerText: 'Cloud Shell enables access to a browser-based command-line experience built with Azure management tasks in mind',
        isCorrect: true
      },
      {
        answerText: 'The Cloud Shell is free for 12 months',
        isCorrect: false
      },
      {
        answerText: 'You can update the Cloud Shell independently of Azure CLI and Azure PowerShell',
        isCorrect: false
      },
      {
        answerText: 'The Cloud Shell gets new features first',
        isCorrect: false
      },

    ]
  },
  {
    id: 6,
    questionText:
      'What does fault tolerance describe for cloud computing?',
    answerOptions: [
      {
        answerText: 'A system within Azure that uses cloud computing resources to mitigate faults quickly',
        isCorrect: false
      },
      {
        answerText: 'The ability for multiple regions within Azure to "cover" each other in case of an outage',
        isCorrect: false
      },
      {
        answerText: 'A complete plan to recover critical business systems and normal operations, in case of a disaster',
        isCorrect: false
      },
      {
        answerText: 'Ensure services and applications remain available in the event of a failure',
        isCorrect: true
      },


    ]
  },
  {
    id: 7,
    questionText: 'What does Infrastructure-as-a-Service describe?',
    answerOptions: [
      {
        answerText: 'A type of cloud computing service that allows users to connect to and use cloud-based apps over the Internet',
        isCorrect: false
      },
      {
        answerText: 'Any service on Azure that you can rent or buy upfront',
        isCorrect: false
      },
      {
        answerText: 'A type of cloud computing service that offers essential compute, storage and networking resources on demand, on a pay-as-you-go basis',
        isCorrect: true
      },
      {
        answerText: 'A complete development and deployment environment in the cloud, with resources that enable you to deliver everything from simple cloud-based apps to sophisticated, cloud-enabled enterprise applications',
        isCorrect: false
      }
    ]
  },
  {
    id: 8,
    questionText: "Select all the true statements per Microsoft's definitions of cloud types. choose 3",
    answerOptions: [
      {
        answerText: 'Private Clouds can be hosted at your datacenter or hosted by a third-party service. Private Clouds offer advantages of flexibility, control, and scalability. In a Private Cloud, infrastructure and services exist on hardware and software dedicated to your organization',
        isCorrect: true
      },
      {
        answerText: 'Public Clouds generally cost more than private clouds but are generally less reliable than on-premises infrastructure (private cloud)',
        isCorrect: false
      },
      {
        answerText: "A hybrid cloud combines a public cloud (such as Azure) with on-premises infrastructure (private cloud)",
        isCorrect: true
      },
      {
        answerText: 'In Private Clouds, your cloud infrastructure is physically isolated from other organizations',
        isCorrect: true
      },

    ]
  },
  {
    id: 9,
    questionText: 'What is "serverless" computing?',
    answerOptions: [
      {
        answerText: 'An application that is running on the cloud platform without the use of servers',
        isCorrect: false
      },
      {
        answerText: 'A theory for making computing completely cloud-based for certain scenarios',
        isCorrect: false
      },
      {
        answerText: "A simpler version of Platform-as-a-Service",
        isCorrect: false
      },
      {
        answerText: 'It enables developers to build applications faster by eliminating the need for them to manage infrastructure',
        isCorrect: true
      }
    ]
  },
  {
    id: 10,
    questionText: 'Why is cloud agility important for businesses?',
    answerOptions: [
      {
        answerText: 'To be able to quickly scale resources when needed at short notice',
        isCorrect: false
      },
      {
        answerText: 'To enable the ability to rapidly develop, test and launch software applications that drive business growth',
        isCorrect: true
      },
      {
        answerText: 'To automatically improve the fidelity of resource usage and utilize the platform better',
        isCorrect: false
      },
      {
        answerText: 'To increase the return on investment from using cloud elasticity',
        isCorrect: false
      }
    ]
  },
  {
    id: 11,
    questionText: 'How does scalability on Azure work?',
    answerOptions: [
      {
        answerText: 'If an account has more than one Azure region active, resources can be copied between these regions',
        isCorrect: false
      },
      {
        answerText: "In Microsoft's definition, scalability is the ability of a system to handle increased load. Services covered by Azure Autoscale can match demand to the needed workload",
        isCorrect: true
      },
      {
        answerText: 'Any Virtual Machine can quickly expand or decrease compute resources to meet changing demands without worrying about capacity planning and engineering for peak usage',
        isCorrect: false
      },
      {
        answerText: 'Scaling of resources on Azure is currently not possible',
        isCorrect: false
      },
    ]
  },
  {
    id: 12,
    questionText: 'What is high availability in cloud computing?',
    answerOptions: [
      {
        answerText: 'If one resource on Azure dies unexpectedly, another resource will almost instantly take over the workload',
        isCorrect: true
      },
      {
        answerText: "Azure will provide an infinite number of resources to your application to make sure it always runs optimally",
        isCorrect: false
      },
      {
        answerText: 'High availability refers to the availability of the Azure Portal. You can always get access to an overview of what your Azure services are doing',
        isCorrect: false
      },
      {
        answerText: 'Microsoft guarantees you will always have access to the resources on Azure',
        isCorrect: false
      },
    ]
  },
  {
    id: 13,
    questionText: 'What is consumption-based pricing on Azure?',
    answerOptions: [
      {
        answerText: 'Any service you use on Azure has a consumption component as part of the pricing',
        isCorrect: false
      },
      {
        answerText: "Consumption-based pricing is when you are charged for only what you use (Pay-As-You-Go rate)",
        isCorrect: true
      },
      {
        answerText: 'Some core services on Azure are consumed constantly to keep your applications running. You pay for this consumption',
        isCorrect: false
      },
      {
        answerText: 'Consumption based pricing is the model for paying for any services on a free Azure account',
        isCorrect: false
      },
    ]
  },
  {
    id: 14,
    questionText: 'Which cloud ability does elasticity describe?',
    answerOptions: [
      {
        answerText: 'The ability to create identical resources in multiple locations globally',
        isCorrect: false
      },
      {
        answerText: "The ability to turn resources on and off quickly across regions",
        isCorrect: false
      },
      {
        answerText: 'The ability to quickly expand or decrease computer processing, memory and storage resources',
        isCorrect: true
      },
      {
        answerText: 'Elasticity is the same as scalability and describes an increase in resources',
        isCorrect: false
      },
    ]
  },
  {
    id: 15,
    questionText: 'What are the two types of scaling on Azure?',
    answerOptions: [
      {
        answerText: 'Scaling up/down and scaling out',
        isCorrect: true
      },
      {
        answerText: "Scale Sets and High Availability",
        isCorrect: false
      },
      {
        answerText: 'There is only one type of scaling: scaling up/down',
        isCorrect: false
      },
      {
        answerText: 'Scaling out and scaling across',
        isCorrect: false
      },
    ]
  },
  {
    id: 16,
    questionText: 'What is a benefit of a hybrid cloud approach?',
    answerOptions: [
      {
        answerText: 'It requires no changes to existing code or applications, allowing companies to scale their infrastructure into the cloud',
        isCorrect: false
      },
      {
        answerText: "Using alternative energy sources for powering some services can create tax benefits in some regions",
        isCorrect: false
      },
      {
        answerText: 'All maintenance is handled by Microsoft Azure, so it reduces support costs',
        isCorrect: false
      },
      {
        answerText: 'It enables companies to use a mix of private and public cloud components',
        isCorrect: true
      },
    ]
  },
  {
    id: 17,
    questionText: 'What is the difference between OPEX and CAPEX?',
    answerOptions: [
      {
        answerText: 'OPEX is an ongoing cost for running a business. CAPEX is the cost of acquiring and maintaining assets',
        isCorrect: true
      },
      {
        answerText: "OPEX is a cost on services you don't own, such as cloud computing. CAPEX is a cost of ownership",
        isCorrect: false
      },
      {
        answerText: 'OPEX is better return on investment in the short term. CAPEX is better return on investment in the long term',
        isCorrect: false
      },
      {
        answerText: 'OPEX is costs for acquiring assets. CAPEX is an ongoing cost for running a business',
        isCorrect: false
      },
    ]
  },
  {
    id: 18,
    questionText: ' Which statement is true of resource groups in Azure?',
    answerOptions: [
      {
        answerText: 'It is a container that holds related resources for an Azure solution',
        isCorrect: true
      },
      {
        answerText: "Resource groups don't contain any data and are assigned rather than created",
        isCorrect: false
      },
      {
        answerText: 'Resource groups can only hold foundational resource types',
        isCorrect: false
      },
      {
        answerText: 'Resource groups are transferrable between regions',
        isCorrect: false
      },
    ]
  },
  {
    id: 19,
    questionText: 'What is an availability zone?',
    answerOptions: [
      {
        answerText: 'Unique physical locations within a region. Each zone is made up of one or more datacenters equipped with independent power, cooling, and networking',
        isCorrect: true
      },
      {
        answerText: "A collection of software that can enable high scalability at short notice",
        isCorrect: false
      },
      {
        answerText: 'A set of data centers close together',
        isCorrect: false
      },
      {
        answerText: 'One or more data centers that are close together to provide backup',
        isCorrect: false
      },
    ]
  },
  {
    id: 20,
    questionText: 'Select all the true statements regarding Azure Resource Manager (Choose 3)',
    answerOptions: [
      {
        answerText: 'If a user sends a request from any Azure tools, APIs, or SDKs, Azure Resource Manager handles the request',
        isCorrect: true
      },
      {
        answerText: "Azure Resource Manager enables the managing of your infrastructure through scripts rather than declarative templates",
        isCorrect: false
      },
      {
        answerText: 'Azure Resource Manager always deploy resources in the same consistent state',
        isCorrect: true
      },
      {
        answerText: 'Azure Resource Manager is the deployment and management service for Azure',
        isCorrect: true
      },
    ]
  },
  {
    id: 12,
    questionText: 'What is high availability in cloud computing?',
    answerOptions: [
      {
        answerText: 'If one resource on Azure dies unexpectedly, another resource will almost instantly take over the workload',
        isCorrect: true
      },
      {
        answerText: "Azure will provide an infinite number of resources to your application to make sure it always runs optimally",
        isCorrect: false
      },
      {
        answerText: 'High availability refers to the availability of the Azure Portal. You can always get access to an overview of what your Azure services are doing',
        isCorrect: false
      },
      {
        answerText: 'Microsoft guarantees you will always have access to the resources on Azure',
        isCorrect: false
      },
    ]
  },
  {
    id: 21,
    questionText:
      'What is an Azure region?',
    answerOptions: [
      {
        answerText: 'A geographical part of the Azure platform ',
        isCorrect: false
      },
      {
        answerText: 'A set of datacenters, deployed within a latency-defined perimeter and connected through a dedicated regional low-latency network',
        isCorrect: true
      },

      {
        answerText: 'One or more datacenters equipped with independent power, cooling, and networking .',
        isCorrect: false
      },
      {
        answerText: 'A collection of similar services that can be hosted in an Azure data center.',
        isCorrect: false
      }
    ]
  },
  {
    id: 23,
    questionText: 'What are the three kinds of App Service? choose 3',
    answerOptions: [
      {
        answerText: 'Azure Standard Apps',
        isCorrect: false
      },
      {
        answerText: 'API Apps',
        isCorrect: true
      },
      {
        answerText: 'Web Apps for Linux',
        isCorrect: false
      },
      {
        answerText: 'Event Grid for App Services',
        isCorrect: false
      },
      {
        answerText: 'Web Apps for Containers',
        isCorrect: true
      },
      {
        answerText: 'Web Apps',
        isCorrect: true
      }
    ]
  },
  {
    id: 24,
    questionText: 'Which definition best describes compute on Microsoft Azure?',
    answerOptions: [
      {
        answerText: 'An optional component to improve the efficiency of Azure',
        isCorrect: false
      },
      {
        answerText: 'A Virtual Machine',
        isCorrect: false
      },
      {
        answerText: 'Any serverless service, such as Azure Functions',
        isCorrect: false
      },
      {
        answerText: 'Any service that performs or enables a computation',
        isCorrect: true
      },
    ]
  },
  {
    id: 25,
    questionText: 'Which cloud service model do Virtual Machines belong to?',
    answerOptions: [
      {
        answerText: 'Serverless',
        isCorrect: false
      },
      {
        answerText: 'Platform-as-a-Service',
        isCorrect: false
      },
      {
        answerText: 'Infrastructure-as-a-Service',
        isCorrect: true
      },
      {
        answerText: 'Software-as-a-Service',
        isCorrect: false
      },
    ]
  },
  {
    id: 26,
    questionText: 'What is a scale set?',
    answerOptions: [
      {
        answerText: 'Azure virtual machine scale sets let you create and manage a group of load balanced VMs',
        isCorrect: true
      },
      {
        answerText: 'A set of Virtual Machines running in the same datacenter',
        isCorrect: false
      },
      {
        answerText: 'A range of sizes of Virtual Machines ready to take over a workload',
        isCorrect: false
      },
      {
        answerText: 'A set of similar services that all work together for a service or application',
        isCorrect: false
      },
    ]
  },
  {
    id: 27,
    questionText: 'What is an Azure Function? choose 2',
    answerOptions: [
      {
        answerText: 'A foundational component of any Azure infrastructure',
        isCorrect: false
      },
      {
        answerText: 'A serverless solution that allows you to write less code, maintain less infrastructure, and save on costs',
        isCorrect: true
      },
      {
        answerText: 'The smallest compute service on Azure that represents a single function of compute',
        isCorrect: true
      },
      {
        answerText: 'A function to update any resources on Azure',
        isCorrect: false
      },
      {
        answerText: 'An add-on to any paid Azure subscription that allows using Azure services as functions in your applications',
        isCorrect: false
      }
    ]
  },
  {
    id: 28,
    questionText: 'What are some benefits of using a Virtual Machine on Azure?',
    answerOptions: [
      {
        answerText: 'Much cheaper than running your own servers',
        isCorrect: false
      },
      {
        answerText: 'Much higher performance of your applications',
        isCorrect: false
      },
      {
        answerText: 'Owning the hardware but Azure maintains it',
        isCorrect: false
      },
      {
        answerText: 'No maintenance of hardware and only paying for what you use',
        isCorrect: true
      },
    ]
  },
  {
    id: 29,
    questionText: 'What is a fully managed platform/Service on Azure?',
    answerOptions: [
      {
        answerText: "Every part of your Azure services are looked after by Microsoft. This means you don't have to worry about your application development",
        isCorrect: false
      },
      {
        answerText: 'The fully managed platform on Azure is a specific subscription that provides extra support for your Azure services',
        isCorrect: false
      },
      {
        answerText: 'Servers, network, storage and more is all managed by Azure. You focus on your business value and logic',
        isCorrect: true
      },
      {
        answerText: 'You can pay a monthly fee to have Microsoft look after the maintenance of your applications and services on Azure',
        isCorrect: false
      },
    ]
  },
  {
    id: 30,
    questionText: 'Which of the following is a function of an Azure VPN Gateway?',
    answerOptions: [
      {
        answerText: 'To make sure the connection from a Virtual Network to the Internet is secure.',
        isCorrect: false
      },
      {
        answerText: 'To manage the IP addresses for an Azure Subscription and to ensure only secure traffic is allowed',
        isCorrect: false
      },
      {
        answerText: 'To handle any suspicious activity trying to access your Azure subscription',
        isCorrect: false
      },
      {
        answerText: 'To send encrypted traffic between an Azure Virtual Network and an on-premises location over the public Internet',
        isCorrect: true
      },
      {
        answerText: 'To balance data coming into your Azure services from an external private network',
        isCorrect: false
      }
    ]
  },
  {
    id: 31,
    questionText: 'Which benefits does adding a load balancer provide? choose 3',
    answerOptions: [
      {
        answerText: 'When there is too much incoming network traffic for a single VM to handle, a load balancer can distribute the load to many VMs',
        isCorrect: true
      },
      {
        answerText: 'A load balancer can log traffic that passes through it',
        isCorrect: true
      },
      {
        answerText: 'When a Virtual disk is running out of space on a Virtual Machine the incoming data can be directed to another Virtual Machine to manage the load',
        isCorrect: false
      },
      {
        answerText: 'To ensure only healthy servers process requests',
        isCorrect: true
      },
      {
        answerText: 'A load balancer ensures that the load is evenly distributed between 2-5 Virtual Machines',
        isCorrect: false
      }
    ]
  },
  {
    id: 32,
    questionText: 'What is the best scenario for using Azure ExpressRoute?',
    answerOptions: [
      {
        answerText: 'Connecting your multi-cloud infrastructure between providers for added speed and security',
        isCorrect: false
      },
      {
        answerText: 'Connecting your on-premises datacenter with Azure, where the connection needs to be secure and cost-effective',
        isCorrect: false
      },
      {
        answerText: 'Connecting your on-premises datacenter with Azure, where the connection needs to be secure and fast',
        isCorrect: true
      },
      {
        answerText: 'ExpressRoute can only be used to and from Azure, and not to other cloud providers',
        isCorrect: false
      },

    ]
  },
  {
    id: 33,
    questionText: 'In which scenario would you use an Application Gateway? choose 2',
    answerOptions: [
      {
        answerText: 'For incoming traffic, to make routing decisions based on additional attributes of an HTTP request, such as URI path or host headers',
        isCorrect: true
      },
      {
        answerText: 'For multi-site hosting',
        isCorrect: false
      },
      {
        answerText: 'To manage the IP addresses for an Azure Subscription and to ensure only secure traffic is allowed',
        isCorrect: true
      },
      {
        answerText: 'To send encrypted traffic between an Azure Virtual Network and an on-premises location over the public Internet',
        isCorrect: false
      },
      {
        answerText: 'To make sure the connection from a Virtual Network to the Internet is secure',
        isCorrect: false
      }
    ]
  },
  {
    id: 34,
    questionText: 'Why would you use a Content Delivery Network? choose 2',
    answerOptions: [
      {
        answerText: 'To ensure requests made from users are securely handled and served',
        isCorrect: false
      },
      {
        answerText: 'To provide better performance and improved user experience for end users',
        isCorrect: true
      },
      {
        answerText: 'A CDN ensures maximum uptime for an application that is hosted in more than one datacenter',
        isCorrect: false
      },
      {
        answerText: 'To better handle instantaneous high loads, such as the start of a product launch event',
        isCorrect: true
      },
      {
        answerText: 'For incoming traffic, to make routing decisions based on additional attributes of an HTTP request, such as URI path or host headers',
        isCorrect: false
      }
    ]
  },
  {
    id: 35,
    questionText: 'What is an address space on a Virtual Network?',
    answerOptions: [
      {
        answerText: 'A definition of what types of resources can connect to either a private or public network hosted on Azure',
        isCorrect: false
      },
      {
        answerText: 'A portion of the complete address space for a given Azure subscription can be assigned to a Virtual Network',
        isCorrect: false
      },
      {
        answerText: 'An address space is a range of IP addresses that can be assigned to resources attached to the Virtual Network',
        isCorrect: true
      },
      {
        answerText: 'A reserved number of public IP addresses that you can use to connect a Virtual Network to the public Internet',
        isCorrect: false
      }
    ]
  },
  {
    id: 36,
    questionText: 'What is the primary use for disk storage?',
    answerOptions: [
      {
        answerText: 'Attach to a load balancer to increase performance and throughput',
        isCorrect: false
      },
      {
        answerText: 'Store files larger than 2GB.',
        isCorrect: false
      },
      {
        answerText: 'Backup facility for Virtual Machines',
        isCorrect: false
      },
      {
        answerText: 'Archive large amounts of data',
        isCorrect: false
      },
      {
        answerText: 'To attach to a Virtual Machine to act as a Virtual hard drive',
        isCorrect: true
      }
    ]
  },
  {
    id: 37,
    questionText: 'Which types of blobs are supported by Azure storage? choose 3',
    answerOptions: [
      {
        answerText: 'Page blob',
        isCorrect: true
      },
      {
        answerText: 'Standard',
        isCorrect: false
      },
      {
        answerText: 'Append',
        isCorrect: true
      },
      {
        answerText: 'Block',
        isCorrect: true
      },
      {
        answerText: 'File',
        isCorrect: false
      },
      {
        answerText: 'Fast',
        isCorrect: false
      }
    ]
  },
  {
    id: 38,
    questionText: 'What significance does the name for your Azure storage account have?',
    answerOptions: [
      {
        answerText: 'The name you give the storage account becomes the main web address for accessing the files in it. It must be unique within your Azure subscription',
        isCorrect: false
      },
      {
        answerText: 'No significance. You can name a storage account what you want',
        isCorrect: false
      },
      {
        answerText: 'Each storage account name is linked to a set of users that can access it',
        isCorrect: false
      },
      {
        answerText: 'The name you give the storage account becomes the main web address for accessing the files in it. It must be unique across all of Azure',
        isCorrect: true
      },

    ]
  },
  {
    id: 39,
    questionText: 'What type of storage is archive storage?',
    answerOptions: [
      {
        answerText: 'File storage',
        isCorrect: false
      },
      {
        answerText: 'Disk storage',
        isCorrect: false
      },
      {
        answerText: 'Archive storage',
        isCorrect: false
      },
      {
        answerText: 'Blob Storage',
        isCorrect: true
      },
    ]
  },
  {
    id: 40,
    questionText: 'What can you store in a blob container inside Azure Storage?',
    answerOptions: [
      {
        answerText: 'Binary files that comply with the Azure data types defined for the storage type',
        isCorrect: false
      },
      {
        answerText: 'Only known binary formats such as images, video and text documents',
        isCorrect: true
      },
      {
        answerText: 'Any kind of binary file that is less than 4096KB in size',
        isCorrect: false
      }
    ]
  },
  {
    id: 41,
    questionText: 'What is a suitable use case for the Azure Files storage service?',
    answerOptions: [
      {
        answerText: 'Temporary file storage for a web application',
        isCorrect: false
      },
      {
        answerText: 'Store files larger than 2 GB',
        isCorrect: false
      },
      {
        answerText: 'Archive large amounts of data',
        isCorrect: false
      },
      {
        answerText: 'Replace or supplement on-premises file servers',
        isCorrect: true
      },
    ]
  },
  {
    id: 42,
    questionText: 'What are the valid online (continuous sync) source databases for the Azure Database Migration Service when migrating to Azure DB for PostgreSQL? (Choose 2)',
    answerOptions: [
      {
        answerText: 'Oracle',
        isCorrect: false
      },
      {
        answerText: 'PostgreSQL',
        isCorrect: true
      },
      {
        answerText: 'RDS PostgreSQL',
        isCorrect: true
      }
    ]
  },
  {
    id: 43,
    questionText: 'What is a definition of Authentication?',
    answerOptions: [
      {
        answerText: 'Being able to log into the Azure Portal from anywhere',
        isCorrect: false
      },
      {
        answerText: 'Determination of access to a system based on authorization',
        isCorrect: false
      },
      {
        answerText: 'The ability of a system to determine your location when accessing it',
        isCorrect: false
      },
      {
        answerText: 'Confirmation of your identity for a system',
        isCorrect: true
      },
    ]
  },
  {
    id: 44,
    questionText:
      'Which are authentication methods used to verify a user with multi-factor authentication? (choose 3)',
    answerOptions: [
      {
        answerText: 'Something you feel',
        isCorrect: false
      },
      {
        answerText: 'Something you have',
        isCorrect: true
      },
      {
        answerText: 'Something you say',
        isCorrect: false
      },
      {
        answerText: 'Something you can recall quickly',
        isCorrect: false
      },
      {
        answerText: 'Something you know',
        isCorrect: true
      },
      {
        answerText: 'Something you are',
        isCorrect: true
      }
    ]
  },
  {
    id: 45,
    questionText: 'What is a definition of Authorization? ',
    answerOptions: [
      {
        answerText: 'Being able to log into the Azure Portal from anywhere',
        isCorrect: false
      },
      {
        answerText: 'Determination of access to a system based on assigned roles/permissions',
        isCorrect: true
      },
      {
        answerText: 'The ability of a system to determine your location when accessing it',
        isCorrect: false
      },
      {
        answerText: 'Confirmation of your identity for a system',
        isCorrect: false
      },
    ]
  },
  {
    id: 46,
    questionText: 'Which are Internet of Things services on Azure?',
    answerOptions: [
      {
        answerText: "IOT Management Studio",
        isCorrect: false
      },
      {
        answerText: 'IoT Hub',
        isCorrect: true
      },
      {
        answerText: 'IoT Services',
        isCorrect: false
      },
      {
        answerText: 'IoT Virtual Box',
        isCorrect: false
      },
      {
        answerText: 'IoT Central',
        isCorrect: true
      },
      {
        answerText: 'IoT App Services',
        isCorrect: false
      }
    ]
  },
  {
    id: 47,
    questionText: 'Which of the following are tools in Azure DevOps?',
    answerOptions: [
      {
        answerText: 'Azure Boards',
        isCorrect: true
      },
      {
        answerText: 'Azure Operations',
        isCorrect: false
      },
      {
        answerText: 'Azure Deployment',
        isCorrect: false
      },
      {
        answerText: 'Azure Automation',
        isCorrect: true
      },
      {
        answerText: 'Amazon Artifacts',
        isCorrect: true
      },
      {
        answerText: 'Amazon Pipelines',
        isCorrect: false
      }
    ]
  },
  {
    id: 48,
    questionText: 'When would you use Azure Logic Apps to solve a problem? choose 2',
    answerOptions: [
      {
        answerText: 'To connect systems both inside and outside of the Azure platform',
        isCorrect: true
      },
      {
        answerText: 'When you have to use more than one Azure subscription',
        isCorrect: false
      },
      {
        answerText: 'For fundamental compute actions that can be run millions of times per second if needed',
        isCorrect: false
      },
      {
        answerText: 'To schedule, automate, and orchestrate tasks and processes',
        isCorrect: true
      },
      {
        answerText: 'To perform single tasks that run once every time they are invoked',
        isCorrect: false
      }
    ]
  },
  {
    id: 49,
    questionText:
      'What is the best definition of Azure DevOps?',
    answerOptions: [
      {
        answerText: 'A specific section of the Azure portal where you can manage operational parts of your infrastructure				',
        isCorrect: true
      },
      {
        answerText: 'A way to write better code and find bugs faster',
        isCorrect: false
      },
      {
        answerText: 'A suite of five different tools to create more robust software, faster',
        isCorrect: true
      },
      {
        answerText: 'A platform to manage Azure resources meant for development, such as App Services, Azure Functions, and Visual Studio Online.',
        isCorrect: false
      }
    ]
  },
  {
    id: 50,
    questionText:
      'Which of the following are types of locks in Azure?',
    answerOptions: [
      {
        answerText: 'Delete',
        isCorrect: true
      },
      {
        answerText: 'Closed',
        isCorrect: false
      },
      {
        answerText: 'Create-only',
        isCorrect: false
      },
      {
        answerText: 'Open',
        isCorrect: false
      },
      {
        answerText: 'Read only',
        isCorrect: true
      },
    ]
  },
  {
    id: 51,
    questionText: 'What are the Microsoft services that can tell you more about trust in the Azure platform?',
    answerOptions: [
      {
        answerText: 'Trust Center',
        isCorrect: true
      },
      {
        answerText: 'Service Trust Portal',
        isCorrect: true
      },
      {
        answerText: "There isn't a single service, as trust is part of every service on Azure",
        isCorrect: false
      },
      {
        answerText: ' Azure privacy portal',
        isCorrect: false
      },
      {
        answerText: 'Azure User Safety Portal',
        isCorrect: false
      }
    ]
  },
  {
    id: 52,
    questionText: 'Which of the following are valid use cases to use Azure Service Health in your architecture?		',
    answerOptions: [
      {
        answerText: ' To set up a testing environment to make sure you have a valid architecture',
        isCorrect: false
      },
      {
        answerText: ' To set up custom alerts to notify you of any outages, planned or otherwise',
        isCorrect: true
      },
      {
        answerText: " To find performance improvements in your App Service apps",
        isCorrect: false
      },
      {
        answerText: ' To track incidents with your services in real time and get a report afterwards',
        isCorrect: true
      },
      {
        answerText: 'To receive data from third-party applications running on other cloud platforms',
        isCorrect: false
      }
    ]
  },
  {
    id: 53,
    questionText: 'What is special about the China region in Azure?',
    answerOptions: [
      {
        answerText: ' You have to be a Chinese citizen to use the China region',
        isCorrect: false
      },
      {
        answerText: 'You are guaranteed to be compliant with all Chinese data and IT regulations',
        isCorrect: true
      },
      {
        answerText: ' All customer data is guaranteed to be geographically within China',
        isCorrect: true
      },
      {
        answerText: 'It is priced purely in Chinese yuan and not American dollars',
        isCorrect: false
      },
      {
        answerText: 'All Azure services available in the China region are physically located inside China',
        isCorrect: true
      }
    ]
  },
  {
    id: 54,
    questionText:
      'What is a security policy in Security Center?			',
    answerOptions: [
      {
        answerText: 'The individual user settings for using the Security Center				',
        isCorrect: false
      },
      {
        answerText: ' A security policy outlines the necessary security measures for a compliant service in Azure			',
        isCorrect: false
      },
      {
        answerText: ' A security policy is a set of rules that Azure can use to evaluate if your configuration of a service is valid.        ',
        isCorrect: true
      },

    ]
  },
  {
    id: 55,
    questionText:
      'Which services can feed data into Azure Monitor?      ',
    answerOptions: [
      {
        answerText: 'Only Azure services that are available on a premium subscription',
        isCorrect: false
      },
      {
        answerText: ' Only on-premises services that connect to an Azure service',
        isCorrect: false
      },
      {
        answerText: 'Both Azure services and on-premises services ',
        isCorrect: true
      },
      {
        answerText: 'All services that are hosted on Azure itself',
        isCorrect: false
      }

    ]
  },
  {
    id: 56,
    questionText: ' Which of the following are features of role-based access control?',
    answerOptions: [
      {
        answerText: 'Multi-factor authentication',
        isCorrect: false
      },
      {
        answerText: 'Defining which actions users can take on a resource',
        isCorrect: true
      },
      {
        answerText: 'Defining which users have access to specific Azure resources',
        isCorrect: true
      },
      {
        answerText: 'Tiered privacy protection for users within your Azure tenant',
        isCorrect: false
      }
    ]
  },
  {
    id: 57,
    questionText:
      'What is the purpose of "models" in machine learning and artificial intelligence?',
    answerOptions: [
      {
        answerText: 'Defining the version of your machine learning application',
        isCorrect: true
      },
      {
        answerText: 'The size and capacity of the machine learning service',
        isCorrect: false
      },
      {
        answerText: 'The framework for integrating other Azure services with your particular machine learning instance				.',
        isCorrect: false
      },
      {
        answerText: 'The definition of what you want your machine learning implementation to learn.	',
        isCorrect: true
      }
    ]
  },
  {
    id: 58,
    questionText: 'How do you submit a support ticket?',
    answerOptions: [
      {
        answerText: ' Through the Azure Support Portal, which comes with any paid support plan',
        isCorrect: false
      },
      {
        answerText: 'Through the email address support@azure.com, making sure you add your account ID in the subject line',
        isCorrect: false
      },
      {
        answerText: 'Through the Azure portal',
        isCorrect: true
      },
      {
        answerText: 'Through the phone number for your region and support level, as noted in the Azure portal',
        isCorrect: false
      }
    ]
  },
  {
    id: 59,
    questionText:
      ' How do you sign up for a service-level agreement with Azure?',
    answerOptions: [
      {
        answerText: 'Call the regional support number as provided in the Azure portal to discuss the desired level of the agreement',
        isCorrect: false
      },
      {
        answerText: 'Call the regional support number as provided in the Azure portal to discuss the desired level of the agreement',
        isCorrect: false
      },
      {
        answerText: 'Service-level agreements are included with every Azure paid service automatically',
        isCorrect: true
      },
      {
        answerText: 'In the Azure portal, go to the service in question and click on the Service Level Agreement section',
        isCorrect: false
      },
    ]
  },
  {
    id: 60,
    questionText: 'Which companies must comply with General Data Protection Regulation (GDPR) requirements?',
    answerOptions: [
      {
        answerText: 'Only companies that are headquartered within the European Union must adhere to GDPR.',
        isCorrect: false
      },
      {
        answerText: ' Only companies in Europe must adhere to GDPR.',
        isCorrect: false
      },
      {
        answerText: ' Companies of any country must adhere to GDPR if their users and customers are located inthe European Union.',
        isCorrect: true
      },
      {
        answerText: ' Any company in the world that has an online presence must adhere to GDPR.',
        isCorrect: false
      }
    ]
  },
];

export default questions;

export const languageOptions = [
	{
		id: 63,
		name: 'JavaScript',
		label: 'JavaScript',
		value: 'javascript'
	},
	{
		id: 70,
		name: 'Python',
		label: 'Python',
		value: 'python'
	},
	{
		id: 45,
		name: 'Assembly',
		label: 'Assembly',
		value: 'assembly'
	},
	{
		id: 46,
		name: 'Bash',
		label: 'Bash',
		value: 'bash'
	},
	{
		id: 47,
		name: 'Basic',
		label: 'Basic',
		value: 'basic'
	},
	{
		id: 75,
		name: 'C',
		label: 'C',
		value: 'c'
	},
	{
		id: 76,
		name: 'C++',
		label: 'C++',
		value: 'cpp'
	},
	{
		id: 51,
		name: 'C#',
		label: 'C#',
		value: 'csharp'
	},
	{
		id: 77,
		name: 'COBOL',
		label: 'COBOL',
		value: 'cobol'
	},
	{
		id: 57,
		name: 'Elixir',
		label: 'Elixir',
		value: 'elixir'
	},
	{
		id: 87,
		name: 'F#',
		label: 'F#',
		value: 'fsharp'
	},
	{
		id: 59,
		name: 'Fortran',
		label: 'Fortran',
		value: 'fortran'
	},
	{
		id: 60,
		name: 'Go',
		label: 'Go',
		value: 'go'
	},
	{
		id: 61,
		name: 'Haskell',
		label: 'Haskell',
		value: 'haskell'
	},
	{
		id: 62,
		name: 'Java',
		label: 'Java',
		value: 'java'
	},

	{
		id: 78,
		name: 'Kotlin',
		label: 'Kotlin',
		value: 'kotlin'
	},
	{
		id: 67,
		name: 'Pascal',
		label: 'Pascal',
		value: 'pascal'
	},
	{
		id: 68,
		name: 'PHP',
		label: 'PHP',
		value: 'php'
	},
	{
		id: 80,
		name: 'R',
		label: 'R',
		value: 'r'
	},
	{
		id: 72,
		name: 'Ruby',
		label: 'Ruby',
		value: 'ruby'
	},
	{
		id: 73,
		name: 'Rust',
		label: 'Rust',
		value: 'rust'
	},
	{
		id: 81,
		name: 'Scala',
		label: 'Scala',
		value: 'scala'
	},
	{
		id: 82,
		name: 'SQL',
		label: 'SQL',
		value: 'sql'
	},
	{
		id: 83,
		name: 'Swift',
		label: 'Swift',
		value: 'swift'
	},
	{
		id: 74,
		name: 'TypeScript',
		label: 'TypeScript',
		value: 'typescript'
	}
];

const questions = [
	{
		id: 1,
		questionText: `Given the following query: \n

        SELECT P.PRODNR, P.PRODNAME
        FROM PRODUCT P
        EXCEPT
        SELECT POL.PRODNR
        FROM PO_LINE POL

        The query retreives:
        `,
		answerOptions: [
			{
				answerText: 'The query will not execute because both queries do not select the same columns',
				isCorrect: true
			},
			{
				answerText: 'The number and name of all the products that are ordered',
				isCorrect: false
			},
			{
				answerText: 'The number and name of all the products with no outstanding order',
				isCorrect: false
			},
			{
				answerText: 'The query will not execute because both queries do not select the same rows',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText: `We want to retreive all unique supplier numbers and statuses of suppliers who have at least one outstanding purchase order. Which query is correct?`,
		answerOptions: [
			{
				answerText: `SELECT DISTINCT R.SUPNR, R.SUPSTATUS
                FROM SUPPLIER R, PURCHASE_ORDER O
                WHERE (R.SUPNR = O.PONR)`,
				isCorrect: false
			},
			{
				answerText: `SELECT R.SUPNR, R.SUPSTATUS
                FROM PURCHASE_ORDER R`,
				isCorrect: false
			},
			{
				answerText: `SELECT DISTINCT R.SUPNR, R.SUPSTATUS
                FROM SUPPLIER R, PURCHASE_ORDER O
                WHERE (R.SUPNR = O.SUPNR)`,
				isCorrect: true
			},
			{
				answerText: `SELECT DISTINCT R.SUPNR, R.SUPSTATUS
                FROM SUPPLIER R, PURCHASE_ORDER O`,
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: `We're interested in wine stores. Therefore, we want to retreive the SUPNR and SUPNAME of eah store which contains 'wine' in its store name. Which of the following queries can we use?`,
		answerOptions: [
			{
				answerText: 'SELECT SUPNR, SUPNAME \n FROM SUPPLIER \n WHERE SUPNAME LIKE "%WINE%',
				isCorrect: true
			},
			{
				answerText: 'SELECT SUPNR, SUPNAME \n FROM SUPPLIER \n WHERE SUPNAME LIKE "WINE',
				isCorrect: false
			},
			{
				answerText: 'SELECT SUPNR, SUPNAME \n FROM SUPPLIER \n WHERE SUPNAME = "WINE',
				isCorrect: false
			},
			{
				answerText: 'SELECT SUPNR, SUPNAME \n FROM SUPPLIER \n WHERE SUPNAME IS "WINE',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText:
			'Consider a data model for the Olympics storing information about countries and athletes. There is a 1-N relationship type between country and athlete and an athlete always has to belong to exactly 1 country. A relational data model containing only 1 table leads to:',
		answerOptions: [
			{
				answerText: 'Unnecessary replication of data about countries.',
				isCorrect: true
			},
			{
				answerText: 'Unnecessary replication of data.',
				isCorrect: false
			},
			{
				answerText: 'Unnecessary replication of data about athletes.',
				isCorrect: false
			},
			{
				answerText: 'Unnecessary replication of data about athletes and countries.',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText:
			'Complete this sentence: In three-layer architecture, between the external layer and the conceptual/logical layer, there is _____',
		answerOptions: [
			{
				answerText: 'Logical data independence',
				isCorrect: true
			},
			{
				answerText: 'Physical data independence',
				isCorrect: false
			},
			{
				answerText: 'No independence, they are basically the same thing',
				isCorrect: false
			},
			{
				answerText: 'The internal layer',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText: 'Which statement is correct?',
		answerOptions: [
			{
				answerText:
					'SQL is a database language that allows different applications to access different subsets of the data necessary for each application',
				isCorrect: false
			},
			{
				answerText:
					'SQL is a database language to manage DBMS without having to write a substantial amount of prgramming code',
				isCorrect: false
			},
			{
				answerText: 'SQL is a database language that focuses on how to access and retreive the data',
				isCorrect: true
			}
		]
	},
	{
		id: 7,
		questionText: 'Which statement is correct?',
		answerOptions: [
			{
				answerText:
					'SQL is a database language to manage DBMS without having to write a substantial amount of prgramming code.',
				isCorrect: true
			},
			{
				answerText: 'The database state represents the data in the database when the database is first created',
				isCorrect: false
			},
			{
				answerText: 'The response time is the time needed to buld the conceptual data model',
				isCorrect: false
			},
			{
				answerText:
					'The database state specifies the various data items, their charateristics and relationships, and is specified during the database design',
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText: `"An employee of a department can never earn more than the manager of the department." This is an example of a:`,
		answerOptions: [
			{
				answerText: 'Syntactical integrity rule',
				isCorrect: false
			},
			{
				answerText: 'Semantical integrity rule',
				isCorrect: true
			}
		]
	},
	{
		id: 9,
		questionText:
			'What concept specifies the various data items, their charateristics and relationships, constraints, storage details, etc, and is specified during the database design?',
		answerOptions: [
			{
				answerText: 'Database state',
				isCorrect: false
			},
			{
				answerText: 'Database model',
				isCorrect: true
			},
			{
				answerText: 'None of these concepts',
				isCorrect: false
			},
			{
				answerText: 'Catalog',
				isCorrect: false
			}
		]
	},
	{
		id: 10,
		questionText: 'Which statement is correct',
		answerOptions: [
			{
				answerText: 'The throughput rate is the number of transactions a DBMS can process per unit of time',
				isCorrect: true
			},
			{
				answerText:
					'The file-based approach uses metadata to define the data. These definitions are then stored in a catalog',
				isCorrect: false
			},
			{
				answerText: 'The external layer of a database contains the logical and conceptual data  model',
				isCorrect: false
			},
			{
				answerText: 'The logical data model is implementaion independent',
				isCorrect: false
			}
		]
	}
];

export default questions;

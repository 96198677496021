import React, { useState, Fragment } from 'react';
import './confirmEmail.css';
import Sms from '../../assets/images/icons/sms-edit.png';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ConfirmEmail = ({ setUserAuthenticated }) => {
	const [ sending, setSending ] = useState(false);
	const userEmail = sessionStorage.getItem('username');

	const handleRequestNewEmail = async () => {
		setSending(true);

		const config = {
			method: 'post',
			url: 'https://28u546ort6.execute-api.us-east-1.amazonaws.com/Prod/user/confirm/signup/resend',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: {
				username: userEmail
			}
		};
		await axios(config)
			.then((response) => {
				setSending(false);

				toast.success('New confirmation link has been sent to your email', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: true,
					theme: 'colored'
				});
			})
			.catch((error) => {
				setSending(false);
				const errorMessage = error.response.data.errorMessage.includes('User is already confirmed')
					? 'Your email address is already confirmed'
					: error.response.data.errorMessage.includes('Attempt limit exceeded')
						? 'Attempt limit exceeded, please try after some time.'
						: 'Network Error';

				toast.error(errorMessage, {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: true,
					theme: 'colored'
				});
			});
	};

	return (
		<Fragment>
			<ToastContainer />

			<div className="confirm-email-parent">
				<section className="confirm-email">
					<section className="confirm-email-header">
						<img src={Sms} alt="font " />
						<h1>Email Confirmation</h1>
					</section>

					<section className="confirm-email-body">
						<p>
							Thank you for registering! A personal link has been sent to your email address. The link
							below will allow you to confirm your email address and log in. Didn't receive the email with
							the link to confirm your account?{' '}
							<span className="confirm-email-button" onClick={handleRequestNewEmail}>
								{!sending ? 'Request new confirmation email' : 'Sending new email'}
							</span>
						</p>
						<br />
						<a href="/login">Back to iLearnCloud</a>
					</section>
				</section>
			</div>
		</Fragment>
	);
};

export default ConfirmEmail;

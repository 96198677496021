import CourseThumbnailBigDataWithSpark from '../../../assets/images/course_thumbnails/course_thumbnail_bigdatawithspark.png';
import CourseThumbnailThree from "../../../assets/images/course_thumbnails/course_thumbnail_3.png";
import CourseThumbnailFour from "../../../assets/images/course_thumbnails/course_thumbnail_4.png";
import CourseThumbnailSix from "../../../assets/images/course_thumbnails/course_thumbnail_6.png";
import CourseThumbnailSeven from "../../../assets/images/course_thumbnails/course_thumbnail_7.png";
import CourseThumbnailEight from "../../../assets/images/course_thumbnails/course_thumbnail_8.png";
import CourseThumbnailNine from "../../../assets/images/course_thumbnails/course_thumbnail_9.png";
import CourseThumbnailLeveragingCloud from "../../../assets/images/course_thumbnails/course_thumbnail_leveragingcloud.png";
import CourseThumbnailBigDataHadoop from "../../../assets/images/course_thumbnails/course_thumbnail_bigdatahadoop.png";
import CourseThumbnailDevops from "../../../assets/images/course_thumbnails/course_thumbnail_devops.png";
import CourseThumbnailShellScript from "../../../assets/images/course_thumbnails/course_thumbnail_shellscript.png";
import CourseThumbnailCoreJava from "../../../assets/images/course_thumbnails/course_thumbnail_corejava.png";
import CourseThumbnailVersionControl from "../../../assets/images/course_thumbnails/course_thumbnail_versioncontrol.png";
import CourseThumbnailCIJenkins from "../../../assets/images/course_thumbnails/course_thumbnail_cijenkins.png";
import CourseThumbnailEdgeComputing from "../../../assets/images/course_thumbnails/course_thumbnail_edgecomputing.png";
import CourseThumbnailAwsCertified from "../../../assets/images/course_thumbnails/course_thumbnail_awscertifiedcloud.png";
import CourseThumbnailMachineLearning from "../../../assets/images/course_thumbnails/course_thumbnail_machinelearning.png";
import CourseThumbnailKubernetes from "../../../assets/images/course_thumbnails/course_thumbnail_kubernetes.png";
import CourseThumbnailMicrosoftAzure from "../../../assets/images/course_thumbnails/course_thumbnail_microsoftazure.png";
import MicrosoftSharepointThumbnail from "../../../assets/images/course_thumbnails/microsoft-sharepoint-thumbnail.png";
import AWSSolutionsArchitectThumbnail from "../../../assets/images/course_thumbnails/AWS Certified Solutions Architect Associate.webp"
import AIPoweredAWSComprehendThumbnail from "../../../assets/images/course_thumbnails/AI-Powered Natural Language Processing with AWS Comprehend.webp"

export const Courses = [
  {
    id: 23,
    image: AWSSolutionsArchitectThumbnail,
    courseTitle: 'AWS Certified Solutions Architect Associate',
    link: '/aws-certified-solutions-architect-associate-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/e24ea0aa-c549-4437-9ede-b3dfb697fa1c/MP4/0-a-Lecture-Introduction-to-AWS.mp4',
  },
  {
    id: 22,
    image: AIPoweredAWSComprehendThumbnail,
    courseTitle: 'AI-Powered Natural Language Processing with AWS Comprehend',
    link: '/ai-powered-natural-language-processing-with-aws-comprehend-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/242c7314-c0e7-4175-a30f-96ed93138bff/MP4/1-Intro-Comprehend-Overview-Section-1-2.mp4',
  },
  {
    id: 21,
    image: MicrosoftSharepointThumbnail,
    courseTitle: 'Microsoft Sharepoint',
    link: '/microsoft-sharepoint-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/0b71ee2a-bdfe-499a-917b-b71ddde72357/MP4/1-Instructor-introduction-video.mp4',
  },
  {
    id: 20,
    image: CourseThumbnailMicrosoftAzure,
    courseTitle: 'Microsoft Azure Fundamentals',
    link: '/microsoft-azure-fundamentals-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/2ed7fc5f-8a19-45fa-ae00-2d8ef794a046/MP4/1-Introductory-Video.mp4',
  },
  {
    id: 1,
    image: CourseThumbnailAwsCertified,
    courseTitle: 'AWS Certified Cloud Practitioner',
    link: '/aws-certified-cloud-practitioner-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/61e862c4-f086-409d-abfe-97e741aec628/MP4/1-Module-1-Inroduction-To-Cloud-Computing.mp4',
  },
  {
    id: 2,
    image: CourseThumbnailEdgeComputing,
    courseTitle:
      'Edge Computing: Driving the Next Evolution of Internet and Technology',
    link: '/edge-computing-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/1bd46519-7692-414a-b17f-c209d228d7a2/MP4/1-Introduction-to-cloud-and-edge-computing.mp4',
  },
  {
    id: 19,
    image: CourseThumbnailThree,
    courseTitle: 'Automated Software Testing Using Selenium',
    link: '/automated-software-testing-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/1add863b-441b-4c2d-b386-2fb37c74b01e/MP4/1-Introduction.mp4',
  },
  {
    id: 3,
    image: CourseThumbnailBigDataWithSpark,
    courseTitle: 'Big Data With Spark',
    link: '/big-data-with-spark-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/4f7caa7c-eb92-4682-b9f8-f2ed21b4aee9/MP4/1-C1-Scala-1.mp4',
  },
  {
    id: 4,
    image: CourseThumbnailShellScript,
    courseTitle: 'Unix Shell Scripting',
    link: '/unix-shell-scripting-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/df7b00c3-5cb1-488c-8cf5-6115cfdf3f7b/MP4/1-Shell-Introduction-1.mp4',
  },
  {
    id: 5,
    image: CourseThumbnailSix,
    courseTitle: 'Relational Databases and PostgreSQL',
    link: '/databases-and-postgreSQL-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/4de4903f-618a-4fcb-bdf5-9307d81d9758/MP4/1-Introduction-to-PostgreSQL-1.mp4',
  },
  {
    id: 6,
    image: CourseThumbnailDevops,
    courseTitle: 'Devops With Ansible',
    link: '/devops-with-ansible-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/a4eb9925-8a10-41aa-8011-0624327de8fd/MP4/1-Class20-ansible.mp4',
  },
  {
    id: 7,
    image: CourseThumbnailEight,
    courseTitle: 'Networking CCNA',
    link: '/networking-ccna-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/2cdfbc0c-89ee-4b1a-b279-745e9d6fd68c/MP4/1-New-CCNAx-200-25-Routing-and-Switching-Overview.mp4',
  },
  {
    id: 8,
    image: CourseThumbnailSeven,
    courseTitle: 'Non Relational Databases - NoSQL And MongoDB',
    link: '/no-sql-and-mongoDB-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/193d8f0f-c67e-4e2b-ba4d-df77619c878b/MP4/1-Introduction-to-NoSQL-Databases.mp4',
  },
  {
    id: 9,
    image: CourseThumbnailNine,
    courseTitle: 'Python For Data Science',
    link: '/python-for-data-science-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/42db674b-6e31-480d-8532-abc46b844133/MP4/1-Introduction-to-Python-in-Data-Science.mp4',
  },
  {
    id: 10,
    image: CourseThumbnailFour,
    courseTitle: 'Data Science and ML with R',
    link: '/data-science-and-ml-with-r-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/d615fae6-25db-43e8-a9ac-12ce3668d8c9/MP4/1-Introduction.mp4',
  },
  {
    id: 11,
    image: CourseThumbnailCoreJava,
    courseTitle: 'Java Programming',
    link: '/core-java-essentials-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/03d595a7-611a-4a84-ad94-bd284f6fc524/MP4/1-introduction-to-java.mp4',
  },
  {
    id: 12,
    image: CourseThumbnailVersionControl,
    courseTitle: 'Introduction to Version Control With Git/GitHub',
    link: '/intro-to-version-control-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/e5a7fce1-afa7-4ea8-857d-ee4263e2aaed/MP4/1-Introduction-to-Version-control-system.mp4',
  },
  {
    id: 13,
    image: CourseThumbnailCIJenkins,
    courseTitle: 'Introduction to Continous Integration CI Jenkins',
    link: '/intro-to-continous-integration-ci-jenkins-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/6684e3f6-d919-470e-a88f-5189303c9f51/MP4/1-Introduction-to-Continuous-Integration-and-configuring-Jenkins.mp4',
  },
  {
    id: 14,
    image: CourseThumbnailBigDataHadoop,
    courseTitle: 'Big Data Beginner Hadoop Ecosystem',
    link: '/big-data-beginner-hadoop-ecosystem-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/f21f9d5c-07db-4413-b900-3bbc1eed402d/MP4/1-A1-Hadoop-intro-1.mp4',
  },
  {
    id: 15,
    image: CourseThumbnailBigDataHadoop,
    courseTitle: 'Big Data Advanced Hadoop Ecosystem',
    link: '/big-data-advanced-hadoop-ecosystem-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/c71863ff-b372-4390-a953-dc336b8ffe00/MP4/1-B1-NoSQL-1.mp4',
  },
  {
    id: 16,
    image: CourseThumbnailLeveragingCloud,
    courseTitle: 'Leveraging Cloud Solutions For Business Growth',
    link: '/leveraging-cloud-solutions-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/57a900c8-b8b7-4883-b0bb-5a40937d48d9/MP4/1-Cloud-adoption-and-business-usecases-Patricia.mp4',
  },
  {
    id: 17,
    image: CourseThumbnailMachineLearning,
    courseTitle: 'Machine Learning',
    link: '/machine-learning-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/3b1b49a8-1f9a-4d61-a01d-1595c9b0c30e/MP4/1-Introduction-to-AI-1.mp4',
  },
  {
    id: 18,
    image: CourseThumbnailKubernetes,
    courseTitle: 'Kubernetes',
    link: '/kubernetes-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/21052419-8488-439a-bbdd-7773e6aadabd/MP4/1-Background-and-top-features.mp4',
  },
];

import React, { useMemo } from 'react';
import './Settings.css';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import { changePassword } from '../../../services/auth-service';

const Settings = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ criteriaMode: 'all' });

  const name = useMemo(() => localStorage.getItem('name'), []);
  const email = useMemo(() => localStorage.getItem('userEmail'), []);

  const handleConfirmPassword = async (data) => {
    const { password, newpassword, retypepassword } = data;

    if (newpassword === retypepassword) {
      const payload = {
        access_token: localStorage.getItem('access_token'),
        previous_password: password,
        proposed_password: newpassword,
      };
      await changePassword(payload)
        .then((response) => {
          toast.success('Password successfully changed', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            theme: 'colored',
          });
        })
        .catch((error) => {
          const TotalerrorMessage = error.response.data.errorMessage.split(':');
          const errorMessage = TotalerrorMessage[2];
          toast.error(errorMessage.slice(0, errorMessage.length - 3), {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            theme: 'colored',
          });
        });
    } else {
      toast.error('New password does not match', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        theme: 'colored',
      });
    }
  };

  return (
    <div className="settings">
      <ToastContainer />

      <div className="profile-parent">
        <h5>Personal Info</h5>
        <p>Update your photo and personal details here.</p>

        <hr />

        <div className="profile-details">
          <section>
            <p>Name</p>

            <ul>
              <li>{name.split(' ')[0]}</li>
              <li>{name.split(' ')[1]}</li>
            </ul>
          </section>

          <hr />

          <section>
            <p>Email Address:</p>

            <ul>
              <li>{email}</li>
            </ul>
          </section>
        </div>

        <hr />

        <div className="form-parent">
          <p>Change Password</p>

          <form
            action="post"
            method="post"
            onSubmit={handleSubmit(handleConfirmPassword)}
          >
            <label htmlFor="password">
              <input
                id="password"
                type="password"
                name="password"
                placeholder="Current password"
                {...register('password', {
                  required: 'Please enter your password',
                  minLength: {
                    value: 8,
                    message: 'Your password must exceed 8 characters',
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](?=.*?[#?!.,@$%^&*-]).{8,}$/,
                    message: 'Wrong password combination',
                  },
                })}
              />
              {errors.password && (
                <p className="input-field-error">{errors.password.message}</p>
              )}
            </label>

            <label htmlFor="newpassword">
              <input
                type="password"
                id="newpassword"
                name="newpassword"
                placeholder="New password"
                {...register('newpassword', {
                  required: 'Please enter your new password',
                  minLength: {
                    value: 8,
                    message: 'Your new password must exceed 8 characters',
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](?=.*?[#?!.,@$%^&*-]).{8,}$/,
                    message: 'Wrong password combination',
                  },
                })}
              />
              {errors.newpassword && (
                <p className="input-field-error">
                  {errors.newpassword.message}
                </p>
              )}
            </label>

            <label htmlFor="retypepassword">
              <input
                type="password"
                id="retypepassword"
                name="retypepassword"
                placeholder="Retype new password"
                {...register('retypepassword', {
                  required: 'Please retype your new password',
                  minLength: {
                    value: 8,
                    message: 'Your new password must exceed 8 characters',
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](?=.*?[#?!.,@$%^&*-]).{8,}$/,
                    message: 'Wrong password combination',
                  },
                })}
              />
              {errors.retypepassword && (
                <p className="input-field-error">
                  {errors.retypepassword.message}
                </p>
              )}
            </label>

            <button type="submit">Confirm new password</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;

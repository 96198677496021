const questions = [
	{
		id: 1,
		questionText: `Which of those doesn't have an index based structure?`,
		answerOptions: [
			{
				answerText: 'List',
				isCorrect: false
			},
			{
				answerText: 'Set',
				isCorrect: true
			},
			{
				answerText: 'Map',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText: 'java.util.Collections is a:',
		answerOptions: [
			{
				answerText: 'Class',
				isCorrect: true
			},
			{
				answerText: 'Interface',
				isCorrect: false
			},
			{
				answerText: 'Object',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 3,
		questionText: 'Methods such as reverse, shuffle are offered in:',
		answerOptions: [
			{
				answerText: 'Object',
				isCorrect: false
			},
			{
				answerText: 'Collection',
				isCorrect: false
			},
			{
				answerText: 'Collections',
				isCorrect: true
			},
			{
				answerText: 'Apache Commons Collections',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText: 'Which of those allows duplicate elements?',
		answerOptions: [
			{
				answerText: 'Set',
				isCorrect: false
			},
			{
				answerText: 'List',
				isCorrect: true
			},
			{
				answerText: 'All',
				isCorrect: false
			},
			{
				answerText: 'All of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText: `What will happen when you compile and run the following code?
        \n 
        public Class Test { \n
            public static void main(String args) { \n
                int - = 5, j = 2; \n
                System.out.ptintIn( i % j ); \n
            } \n
        }`,
		answerOptions: [
			{
				answerText: '1',
				isCorrect: false
			},
			{
				answerText: '2',
				isCorrect: true
			},
			{
				answerText: 'Compilation error',
				isCorrect: false
			},
			{
				answerText: 'Run time error',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText: `What will happen when you compile and run the following code?
        \n 
        public Class Test { \n
            public static void main(String[] args) { \n

                float f = 10.2; \n
                diuble d = 10.2; \n'

                if ( f == d) \n
                System.out.printIn("Same) \n
                else \n
                System.out.printIn("Not same) \n
            } \n
        }`,
		answerOptions: [
			{
				answerText: 'Same',
				isCorrect: false
			},
			{
				answerText: 'Not same',
				isCorrect: false
			},
			{
				answerText: 'Compilation error',
				isCorrect: true
			}
		]
	},
	{
		id: 7,
		questionText: 'Which interface should be implemented for sorting on basis of many criterias',
		answerOptions: [
			{
				answerText: 'Comparator',
				isCorrect: true
			},
			{
				answerText: 'Comparable',
				isCorrect: false
			},
			{
				answerText: 'Serializable',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText: `What will happen when you compile and run the following code?
        \n 
        public Class Test { \n
            public static void main(String[] args) { \n
                doube d1 = Double.Nan \n
                doube d2 = d1 \n
                
                if(d1 == d2) \n
                System.out.printIn("Equal) \n
                else \n
                System.out.printIn("Not Equal)
            } \n
        }`,
		answerOptions: [
			{
				answerText: 'Equal',
				isCorrect: true
			},
			{
				answerText: 'Not Equal',
				isCorrect: false
			},
			{
				answerText: 'The code will not compile',
				isCorrect: false
			},
			{
				answerText: 'The code will compile but will throw run time error',
				isCorrect: false
			}
		]
	},
	{
		id: 9,
		questionText: `What will happen when you compile and run the following code?
        \n 
        public Class Test { \n
            public static void main(String[] args) { \n
                int i = 10 \n
                int j = 25 \n
                System.out.printIn(i + ' ' + j) \n
            } \n
        }`,
		answerOptions: [
			{
				answerText: '10 25',
				isCorrect: true
			},
			{
				answerText: '35',
				isCorrect: false
			},
			{
				answerText: '67',
				isCorrect: false
			},
			{
				answerText: 'The code will not compile',
				isCorrect: false
			}
		]
	},
	{
		id: 10,
		questionText: `What will happen when you compile and run the following code?
        \n 
        public Class Test { \n
            public static void main(String[] args) { \n
                int i = 10 \n
                int j = 25 \n
                i += ++j
                
                System.out.printIn(i) \n

            } \n
        }`,
		answerOptions: [
			{
				answerText: '13',
				isCorrect: true
			},
			{
				answerText: '23',
				isCorrect: false
			},
			{
				answerText: '22',
				isCorrect: false
			},
			{
				answerText: '12',
				isCorrect: false
			}
		]
	},
	{
		id: 11,
		questionText: `What will happen when you compile and run the following code?
        \n 
        public Class Test { \n
            public static void main(String[] args) { \n
                int i = 010 \n
                System.out.printIn(i) \n

            } \n
        }`,
		answerOptions: [
			{
				answerText: 'Compilation error',
				isCorrect: false
			},
			{
				answerText: 'Runtime error',
				isCorrect: false
			},
			{
				answerText: '8',
				isCorrect: false
			},
			{
				answerText: '10',
				isCorrect: true
			}
		]
	},
	{
		id: 12,
		questionText: `What will happen when you compile and run the following code?
        \n 
        public Class Test { \n
            public static void main(String[] args) { \n
                byte b1 = 5, b2 = 2 \n
                byte b = b1 % b2 \n
                System.out.printIn(b) \n

            } \n
        }`,
		answerOptions: [
			{
				answerText: '0',
				isCorrect: false
			},
			{
				answerText: '1',
				isCorrect: false
			},
			{
				answerText: '2',
				isCorrect: false
			},
			{
				answerText: 'Compilation error',
				isCorrect: true
			}
		]
	},
	{
		id: 13,
		questionText: `What will happen when you compile and run the following code?
        \n 
        public Class Test { \n
            public static void main(String[] args) { \n
                boolean b1 = true \n
                boolean b2 = true \n

                if( b1 == b2) {
                    System.out.printIn('==') \n
                }

                if( b1.equals(b2)) {
                    System.out.printIn('equals') \n
                }

            } \n
        }`,
		answerOptions: [
			{
				answerText: '==equals',
				isCorrect: false
			},
			{
				answerText: 'equals',
				isCorrect: true
			},
			{
				answerText: 'No output',
				isCorrect: false
			},
			{
				answerText: 'Compilation error',
				isCorrect: false
			}
		]
	},
	{
		id: 14,
		questionText: `What will happen when you compile and run the following code?
        \n 
        public Class Test { \n
            public static void main(String[] args) { \n
                int array[] = new int[1] \n
                array[0] = 100 \n

                System.out.printIn(array.length()) \n
            } \n
        }`,
		answerOptions: [
			{
				answerText: '100',
				isCorrect: true
			},
			{
				answerText: '1',
				isCorrect: false
			},
			{
				answerText: 'Compilation error',
				isCorrect: false
			},
			{
				answerText: 'Run time error',
				isCorrect: false
			}
		]
	}
];

export default questions;

import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './Modal.css';

const Modal = ({ response, open, compile, onClose }) => {
	const handleCompilation = (e) => {
		compile(e);
		onClose && onClose(e);
	};

	// returns nothing if props.open is false
	if (!open) {
		return null;
	}

	return (
		<div className="modal-parent">
			<div className="modal-success-body">
				<CheckCircleIcon id="iconFont" />

				<div>
					{response.includes('Failed, Challenge Has Already been Taken') ? (
						<React.Fragment>
							<h1>Awesome!</h1>
							<p>You have already answered this question</p>
						</React.Fragment>
					) : (
						<React.Fragment>
							<h1>Good Job!</h1>
							<p>Nothing to worry about, everything is going great!</p>
						</React.Fragment>
					)}

					<button onClick={handleCompilation}>Next Challenge</button>
				</div>
			</div>
		</div>
	);
};

export default Modal;

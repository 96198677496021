import React, { useState, useEffect } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/dracula';
import Editor from 'react-simple-code-editor';

// editor default styles
const styles = {
  root: {
    boxSizing: 'border-box',
    fontFamily: '"Inter", sans-serif',
    width: '100%',
    ...theme.plain,
  },
};

const CodeEditorWindow = ({ onChange, language, code, next }) => {
  const [value, setValue] = useState(code);

  useEffect(() => {
    setValue(next);
  }, [next]);

  const handleEditorChange = (value) => {
    setValue(value);
    onChange('code', value);
  };

  const highlight = (code) => (
    <Highlight
      {...defaultProps}
      code={code}
      theme={theme}
      language={
        language === `"Python"`
          ? 'python'
          : language === `"JavaScript"`
          ? 'javascript'
          : 'jsx'
      }
    >
      {({ tokens, getLineProps, getTokenProps }) => (
        <div style={{ width: '91vw', height: '50vh' }}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </div>
      )}
    </Highlight>
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        background: '#667085',
        padding: '25px 20px',
        borderRadius: '8px',
      }}
    >
      <Editor
        value={value}
        onValueChange={handleEditorChange}
        highlight={highlight}
        style={styles.root}
      />
    </div>
  );
};
export default CodeEditorWindow;

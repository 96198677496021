import React, { useEffect, useState } from 'react';
import './Leaderboard.css';
import axios from 'axios';

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const language = localStorage.getItem('editorLanguage');
    const today = new Date();
    const firstDay = today.getDate() - today.getDay();
    const startWeek = new Date(today.setDate(firstDay));
    const endWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );

    const epochStartWeek = Date.parse(startWeek);
    const epochEndtWeek = Date.parse(endWeek);

    const getRank = async () => {
      let url = 'https://gyhdko9hgh.execute-api.eu-west-1.amazonaws.com/prod';
      let formData = {
        challenge_category: language,
        method: 'leaderboard_category',
        start_date: epochStartWeek,
        end_date: epochEndtWeek,
      };

      await axios
        .post(url, formData)
        .then((response) => {
          let objResponse = JSON.parse(response.data.body);
          setLeaderboard(objResponse);
          setLoading(false);
        })
        .catch((error) => console.log(error));
    };

    getRank();
  }, []);

  return (
    <div className="landing-parent score-board">
      <h1 className="score-header">Coding Challenge Leaderboard</h1>
      <p className="score-sub-header">
        Category: {leaderboard.ChallengeCategory}
      </p>

      {leaderboard?.Items?.length > 0 ? (
        <table>
          <thead>
            <tr>
              <td>Name</td>
              <td>Score</td>
            </tr>
          </thead>

          {!loading ? (
            leaderboard.Items.map((board) => (
              <tbody>
                <tr key={board.email}>
                  <td>{board.username}</td>
                  <td> {board.totalscore} </td>
                </tr>
              </tbody>
            ))
          ) : (
            <React.Fragment>
              <td>Loading ranks...</td>
              <td>Loading Score...</td>
            </React.Fragment>
          )}
        </table>
      ) : (
        <h5 style={{ marginTop: 40 }}>No Leaderboard at this time</h5>
      )}
    </div>
  );
};

export default Leaderboard;

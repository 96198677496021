import React, { useState, useRef, useLayoutEffect } from 'react';
import './Nav.css';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast, ToastContainer } from 'react-toastify';
import LogOutModal from './LogOutModal/LogOutModal';
import { getUserDetails } from '../../../services/auth-service';

import { FiSettings } from 'react-icons/fi';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import logoImage from '../../../assets/images/company_logo/company logo dark.png';

const Nav = () => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [username, setUsername] = useState('');
  const [logOutModal, setLogOutModal] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(false);

  useLayoutEffect(() => {
    const getUser = async () => {
      try {
        const currentAccessToken = localStorage.getItem('access_token');
        const userResponse = await getUserDetails({
          access_token: currentAccessToken,
        });
        currentAccessToken &&
          setUsername(userResponse.data.UserAttributes[2].Value);
      } catch (error) {
        navigate('/login');
      }
    };
    getUser();
  }, [navigate]);

  // Logout Function
  const logOut = () => {
    localStorage.clear();

    toast.success('You have logged out successfully', {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      theme: 'colored',
    });

    setTimeout(() => {
      navigate('/login');
    }, 700);
  };

  return (
    <nav>
      <ToastContainer />

      <section className="desktop-navbar">
        <ul>
          <li className="icon-logo">
            <img
              src={logoImage}
              alt="logo"
            />
          </li>
          <NavLink
            to="/dashboard"
            className={({ isActive }) => (isActive ? 'navbar-is-active' : '')}
          >
            <li className="mobile-nav-list">Home</li>
          </NavLink>
          <NavLink
            to="/courses"
            className={({ isActive }) => (isActive ? 'navbar-is-active' : '')}
          >
            <li className="mobile-nav-list">Your Courses</li>
          </NavLink>
          <NavLink
            to="/codingChallenge"
            className={({ isActive }) => (isActive ? 'navbar-is-active' : '')}
          >
            <li className="mobile-nav-list">Coding Challenge</li>
          </NavLink>
          <NavLink
            to="/innovationChallenge"
            className={({ isActive }) => (isActive ? 'navbar-is-active' : '')}
          >
            <li className="mobile-nav-list">Innovation Challenge</li>
          </NavLink>
        </ul>

        <ul>
          <li>{username}</li>
          <NavLink to="/settings">
            <li>
              <FiSettings className="navlinks-icon" />
            </li>
          </NavLink>
          <li onClick={() => setLogOutModal(true)}>
            <RiLogoutCircleRLine className="navlinks-icon" />
          </li>
        </ul>
      </section>

      {/* below is the hamburger toggle menu bar on small screens */}
      <section className="mobile-navbar">
        <div className="mobile-nav-body">
          <li className="icon-logo">
            <img
              src={logoImage}
              alt="logo"
            />
          </li>

          <div>
            <li onClick={() => setLogOutModal(true)}>Log Out</li>

            <div
              className={`mobile-hamburger-btn ${
                mobileDropdown ? 'close' : undefined
              }`}
              onClick={() => setMobileDropdown(!mobileDropdown)}
            />
          </div>
        </div>

        <ul
          ref={dropdownRef}
          className={`mobile-nav-links ${mobileDropdown ? 'open' : undefined}`}
        >
          <NavLink
            style={{ color: '#f9fbff' }}
            to="/dashboard"
            onClick={() => setMobileDropdown(!mobileDropdown)}
          >
            <li>Home</li>
          </NavLink>
          <NavLink
            style={{ color: '#f9fbff' }}
            to="/courses"
            onClick={() => setMobileDropdown(!mobileDropdown)}
          >
            <li>Your Courses</li>
          </NavLink>
          <NavLink
            style={{ color: '#f9fbff' }}
            to="/codingChallenge"
            onClick={() => setMobileDropdown(!mobileDropdown)}
          >
            <li>Coding Challenge</li>
          </NavLink>
          <NavLink
            style={{ color: '#f9fbff' }}
            to="/innovationChallenge"
            onClick={() => setMobileDropdown(!mobileDropdown)}
          >
            <li>Innovation Challenge</li>
          </NavLink>
          <NavLink
            style={{ color: '#f9fbff' }}
            to="/settings"
            onClick={() => setMobileDropdown(!mobileDropdown)}
          >
            <li>Settings</li>
          </NavLink>
        </ul>
      </section>

      {logOutModal && (
        <LogOutModal
          logOut={logOut}
          setLogOutModal={setLogOutModal}
        />
      )}
    </nav>
  );
};

export default Nav;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Nav from './nav';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import loginImage from '../../assets/images/signup_bg_image/signup image.png';

function NewPassword() {
  const [newpwd, setNewpwd] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
  });

  const handleOnSubmit = async (data) => {
    var config = {
      method: 'post',
      url: 'https://28u546ort6.execute-api.us-east-1.amazonaws.com/Prod/user/confirm/forgotpassword',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        username: sessionStorage.getItem('username'),
        code: data.code,
        password: data.password,
      },
    };

    await axios(config)
      .then(function (response) {
        toast.success('Password has been changed successfully', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });
        setNewpwd(true);
      })
      .catch(function (error) {
        const TotalerrorMessage = error.response.data.errorMessage.split(':');
        console.log(TotalerrorMessage[2]);
        const errorMessage = TotalerrorMessage[2];
        toast.error(errorMessage.slice(0, errorMessage.length - 2), {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });
      });
  };

  return (
    <>
      <ToastContainer />

      <Nav />

      <section className="login-parent">
        <div className="login-form">
          {newpwd ? (
            <div className="success-message">
              <h1>
                Password changed successfully, You can now proceed to{' '}
                <a href="/login">Log in</a>
              </h1>
            </div>
          ) : (
            <form
              action="POST"
              method="post"
            >
              <h1>Reset Password</h1>
              <p>
                Enter the OTP sent to your email address and the new password to
                reset your password.
              </p>

              <label htmlFor="code">
                OTP Code
                <input
                  type="code"
                  id="code"
                  name="code"
                  placeholder="Enter OTP"
                  {...register('code', {
                    required: 'Please enter the code received',
                  })}
                />
              </label>

              <label htmlFor="password">
                New Password
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="••••••••"
                  {...register('password', {
                    required: 'Enter new password',
                    minLength: {
                      value: 8,
                      message: 'Your password must exceed 8 characters',
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](?=.*?[#?!.,@$%^&*-]).{8,}$/,
                      message:
                        'Your password must include at least one uppercase letter, one number and one special character',
                    },
                  })}
                />
                {errors.password ? (
                  <p className="error-message">{errors.password.message}</p>
                ) : (
                  <p className="password-validation">
                    Must be at least 8 characters
                  </p>
                )}
              </label>

              <button
                type="submit"
                onClick={handleSubmit(handleOnSubmit)}
              >
                Continue
              </button>
            </form>
          )}
        </div>

        <div className="login-image-parent">
          <img
            src={loginImage}
            alt="iLearn Cloud"
          />
        </div>
      </section>
    </>
  );
}

export default NewPassword;

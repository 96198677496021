const questions = [
	{
		id: 1,
		questionText:
			'Missing values in this csv file has been represented by an exclamation mark ("!") and a question mark ("?"). WHich of the codes below will read the above csv file correctly into R?',
		answerOptions: [
			{
				answerText: 'csv("Dataframe.csv")',
				isCorrect: false
			},
			{
				answerText: 'csv("Dataframe.csv",header=FALSE,sep=;na.strings=c("?"))',
				isCorrect: false
			},
			{
				answerText: 'csv2("Dataframe.csv",header=FALSE,sep=;na.strings=c("?","!"))',
				isCorrect: true
			},
			{
				answerText: 'dataframe("Dataframe.csv")',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText: 'Advantages of Data Science are',
		answerOptions: [
			{
				answerText: 'Data Science is versatile',
				isCorrect: false
			},
			{
				answerText: 'Abundamce of Positions',
				isCorrect: false
			},
			{
				answerText: 'A highly Paid Career',
				isCorrect: false
			},
			{
				answerText: 'All of the above',
				isCorrect: true
			}
		]
	},
	{
		id: 3,
		questionText:
			'A data set has been read in R and stored in a variable "dataframe". Which of the below codes will produce a summary(mean, mode, median) of the entire dataset in a single line of code?',
		answerOptions: [
			{
				answerText: 'summary(dataframe)',
				isCorrect: false
			},
			{
				answerText: 'stats(dataframe)',
				isCorrect: false
			},
			{
				answerText: 'summarize(dataframe)',
				isCorrect: false
			},
			{
				answerText: 'summarise(dataframe)',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: true
			}
		]
	},
	{
		id: 4,
		questionText:
			'A majority of work in R uses systems internal memory and with large datasets, situations may arise when the R worksoace cannot hold all the R objects in memory. So removing the unused objects is one of the solution. Which of the following command will remove an R object/variable named "santa" from the workspace',
		answerOptions: [
			{
				answerText: 'remove(santa)',
				isCorrect: false
			},
			{
				answerText: 'rm(santa)',
				isCorrect: false
			},
			{
				answerText: 'Both',
				isCorrect: true
			},
			{
				answerText: 'None',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText:
			'Suppose there are 2 dataframes "A" and "B". A has 34 rows and B has 46 rows. What will be the number of rows in the resultant dataframe after running the following command? \n merge(A.B,all.x=TRUE)',
		answerOptions: [
			{
				answerText: '46',
				isCorrect: false
			},
			{
				answerText: '12',
				isCorrect: false
			},
			{
				answerText: '34',
				isCorrect: true
			},
			{
				answerText: '80',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText:
			'The very first thing a Data Scientist generally does after loading dataset is find out the numnber of rows and columns that dataset has. In technical terms, it is called knowing the dimensions of the dataset. This is done to get an idea about the scale of data that he is dealing with and subsequently choosing the right techniques and tools.\n \n Which of the following command will not help us to view the dimensions of our dataset',
		answerOptions: [
			{
				answerText: 'dim()',
				isCorrect: false
			},
			{
				answerText: 'str()',
				isCorrect: false
			},
			{
				answerText: 'View()',
				isCorrect: true
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 7,
		questionText: 'K-nearest neighbors algorithm is based on _______ learning',
		answerOptions: [
			{
				answerText: 'Supervised',
				isCorrect: false
			},
			{
				answerText: 'Unsupervised',
				isCorrect: true
			},
			{
				answerText: 'Association',
				isCorrect: false
			},
			{
				answerText: 'Correlation',
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText: 'CNN is mostly used for which type of data?',
		answerOptions: [
			{
				answerText: 'Structured Data',
				isCorrect: false
			},
			{
				answerText: 'Unstructured Data',
				isCorrect: true
			},
			{
				answerText: 'Both Structured and Unstructured',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 9,
		questionText: 'What is the work of a Data Architect?',
		answerOptions: [
			{
				answerText: `Utilize large data sets to gather information that meets their company's needs`,
				isCorrect: false
			},
			{
				answerText: 'Work with businesses to determne the best usage of the information yielded from data',
				isCorrect: false
			},
			{
				answerText: 'Build data solutions that are optimized for performance and design applications',
				isCorrect: true
			},
			{
				answerText: 'All of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 10,
		questionText: 'A perfect negative correlation is signified by ______',
		answerOptions: [
			{
				answerText: '1',
				isCorrect: false
			},
			{
				answerText: '2',
				isCorrect: false
			},
			{
				answerText: '-1',
				isCorrect: false
			},
			{
				answerText: '0',
				isCorrect: true
			}
		]
	},
	{
		id: 11,
		questionText: 'Which of the following is the common goal of statistical modeling?',
		answerOptions: [
			{
				answerText: 'Inference',
				isCorrect: true
			},
			{
				answerText: 'Subsetting',
				isCorrect: false
			},
			{
				answerText: 'Summarizing',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 12,
		questionText: 'Which of the following is a branch of statistics?',
		answerOptions: [
			{
				answerText: 'Descriptive statistics',
				isCorrect: false
			},
			{
				answerText: 'Industry statistics',
				isCorrect: false
			},
			{
				answerText: 'Inferential statistics',
				isCorrect: false
			},
			{
				answerText: 'Both A and C',
				isCorrect: true
			}
		]
	},
	{
		id: 13,
		questionText: 'Advantages of Data Science are?',
		answerOptions: [
			{
				answerText: 'Data Science is Versatile',
				isCorrect: false
			},
			{
				answerText: 'Abundace of Positions',
				isCorrect: false
			},
			{
				answerText: 'A Highly Paid Career',
				isCorrect: false
			},
			{
				answerText: 'All of the above',
				isCorrect: true
			}
		]
	}
];

export default questions;

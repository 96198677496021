const questions = [
  {
    id: 1,
    questionText: 'Which statements are TRUE about Azure Key Vault?',
    answerOptions: [
      {
        answerText: 'Access to secrets and passwords can be granted or denied very fast and as needed',
        isCorrect: true
      },
      {
        answerText: 'Azure Key Vault removes the need for passwords in your services',
        isCorrect: false
      },
      {
        answerText: 'Azure Key Vault defines security policies for Azure services',
        isCorrect: true
      },
      {
        answerText: 'You can share a password with a third party to use, without ever revealing the password itself',
        isCorrect: true,
      },
      {
        answerText: ' A third-party app can pass access to a secret to another third-party app, if authorized in the Azure portal',
        isCorrect: false
      }
    ]
  },
  {
    id: 2,
    questionText:
      'What is a distributed denial-of-service attack?',
    answerOptions: [
      {
        answerText: ' An attack carried out from multiple Azure datacenters',
        isCorrect: false
      },
      {
        answerText: 'An attack where lots of computers target a single server or website with the aim of making it stop',
        isCorrect: true
      },
      {
        answerText: 'An attack meant to target multiple services and bring them to a stop',
        isCorrect: false
      },
      {
        answerText: 'An attack that is partly generated by cloud services and partly by on-premises servers',
        isCorrect: false
      }
    ]
  },
  {
    id: 3,
    questionText: "If you don't want to share the hardware your VMs run on, how can you manage that in Azure?",
    answerOptions: [
      {
        answerText: "Use Azure Dedicated Host",
        isCorrect: true
      },
      {
        answerText: 'Use Azure Security Center to create a private zone for your VM cluster',
        isCorrect: false
      },
      {
        answerText: 'Secure the network connection to your VM using a network security group',
        isCorrect: false
      },
      {
        answerText: 'Use Premium tier virtual machines',
        isCorrect: false
      },

    ]
  },
  {
    id: 4,
    questionText:
      'There is a potential threat to your Azure infrastructure from an outside attacker. Which service is best for detecting the threat and taking action?',
    answerOptions: [
      {
        answerText: 'Key Vault',
        isCorrect: false
      },
      {
        answerText: 'Defense in Depth',
        isCorrect: false
      },
      {
        answerText: 'Azure Sentinel',
        isCorrect: true
      },
      {
        answerText: 'Azure Security Center',
        isCorrect: false
      },
    ]
  },
  {
    id: 5,
    questionText:
      ' What does Microsoft Defender for Identity (formerly Azure Advanced Threat Protection, also known as Azure ATP) do?',
    answerOptions: [
      {
        answerText: ' Helps you monitor user behavior in your on-premises and cloud environments',
        isCorrect: true
      },
      {
        answerText: "Helps an organization to classify and (optionally) protect its documents and emails by applying labels",
        isCorrect: false
      },
      {
        answerText: 'Protects your privacy when sharing access to Azure resources with third parties',
        isCorrect: false
      },
      {
        answerText: 'Finds vulnerabilities in the data passed to an Azure service in your subscription',
        isCorrect: false
      },
      {
        answerText: 'Potential discounts through gamification of using best practices ',
        isCorrect: false
      }
    ]
  },
  {
    id: 6,
    questionText:
      ' How do you sign up for a service-level agreement with Azure?',
    answerOptions: [
      {
        answerText: 'Call the regional support number as provided in the Azure portal to discuss the desired level of the agreement',
        isCorrect: false
      },
      {
        answerText: 'Call the regional support number as provided in the Azure portal to discuss the desired level of the agreement',
        isCorrect: false
      },
      {
        answerText: 'Service-level agreements are included with every Azure paid service automatically',
        isCorrect: true
      },
      {
        answerText: 'In the Azure portal, go to the service in question and click on the Service Level Agreement section',
        isCorrect: false
      },
    ]
  },
  {
    id: 7,
    questionText: 'How do you submit a support ticket?',
    answerOptions: [
      {
        answerText: ' Through the Azure Support Portal, which comes with any paid support plan',
        isCorrect: false
      },
      {
        answerText: 'Through the email address support@azure.com, making sure you add your account ID in the subject line',
        isCorrect: false
      },
      {
        answerText: 'Through the Azure portal',
        isCorrect: true
      },
      {
        answerText: 'Through the phone number for your region and support level, as noted in the Azure portal',
        isCorrect: false
      }
    ]
  },

];

export default questions;

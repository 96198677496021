export const customStyles = {
  control: (styles) => ({
    ...styles,
    width: '22rem',
    borderRadius: '8px',
    color: '#667085',
    fontSize: '16px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    border: '1px solid ##D0D5DD',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '5px',
    ':hover': {
      border: '2px solid ##D0D5DD',
      color: '#fff',
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      color: '#667085',
      fontSize: '16px',
      width: '100%',
      background: '#fff',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      padding: '10px 14px',
      ':hover': {
        backgroundColor: '#6499e9',
        borderWidth: '2px',
        borderColor: '2px solid #6499e9',
        color: '#fff',
        cursor: 'pointer',
      },
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      maxWidth: '100%',
      fontSize: 24,
      marginTop: 6,
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#667085',
      fontSize: '16px',
    };
  },
};

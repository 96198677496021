import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import './CourseContent.css';

// import exam questions
import unixExam from './Questions/UnixQuestions';
import dataScienceExam from './Questions/DataScience';
import devopsExam from './Questions/DevopsQuestions';
import gitExam from './Questions/GitandGithubQuestions';
import bigDataExam from './Questions/BigDataQuestions';
import awsExam from './Questions/AwsCertifiedQuestions';
import networkingExam from './Questions/NetworkingQuestions';
import javaExam from './Questions/JavaProgrammingQuestions';
import MsSharepointExam from './Questions/MicrosoftSharepoint';
import relationalDBExam from './Questions/RelationalDBQuestions';
import pythonExam from './Questions/PythonforDataScienceQuestions';
import continousIntegrationExam from './Questions/ContinousIntegration';
import nonRelationalDBExam from './Questions/NonRelationalDBQuestions';

//importing MS Azure questions
import Azure1 from './Questions/Microsoft Azure/Module1Question';
import Azure2 from './Questions/Microsoft Azure/Module2Questions';
import Azure3 from './Questions/Microsoft Azure/Module3Questions';
import Azure4 from './Questions/Microsoft Azure/Module4Questions';
import Azure5 from './Questions/Microsoft Azure/Module5Questions';
import Azure6 from './Questions/Microsoft Azure/Module6Questions';
import AzureTotalModule from './Questions/Microsoft Azure/TotalModuleQuiz';
// import Azure7 from './Questions/Microsoft Azurw/Module7';

const CourseContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { from, moduleName } = location.state;

  const [course, setCourse] = useState('');
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [state, setState] = useState({
    index: null,
    isActive: false,
  });

  useEffect(() => {
    if (from.includes('big-data-with-spark')) {
      setQuestions(bigDataExam);
      setCourse('Big Data with Spark');
    } else if (from.includes('unix-shell-scripting')) {
      setQuestions(unixExam);
      setCourse('Unix Shell Scripting');
    } else if (from.includes('aws-certified-cloud-practitioner')) {
      setQuestions(awsExam);
      setCourse('AWS Certified Cloud Practitioner');
    } else if (from.includes('data-science-and-ml-with-r')) {
      setQuestions(dataScienceExam);
      setCourse('Data Science and Machine Learning with R');
    } else if (from.includes('devops-with-ansible')) {
      setQuestions(devopsExam);
      setCourse('Devops with Ansible');
    } else if (from.includes('intro-to-version-control')) {
      setQuestions(gitExam);
      setCourse('Intro to Version Control');
    } else if (from.includes('core-java-essentials')) {
      setQuestions(javaExam);
      setCourse('Core Java Essentials');
    } else if (from.includes('networking-ccna')) {
      setQuestions(networkingExam);
      setCourse('Networking CCNA');
    } else if (from.includes('databases-and-postgreSQL')) {
      setQuestions(relationalDBExam);
      setCourse('Databases and PostgreSQL');
    } else if (from.includes('intro-to-continous-integration-ci-jenkins')) {
      setQuestions(continousIntegrationExam);
      setCourse('Introduction to Continous Integration CI Jenkins');
    } else if (from.includes('no-sql-and-mongoDB')) {
      setQuestions(nonRelationalDBExam);
      setCourse('No SQL and MongoDB');
    } else if (from.includes('python-for-data-science')) {
      setQuestions(pythonExam);
      setCourse('Python for Data Science');
    } else if (from.includes('edge-computing')) {
      setCourse(
        'Edge Computing: Driving the Next Evolution of Internet and Technology'
      );
    } else if (from.includes('leveraging-cloud-solution')) {
      setCourse('Leveraging Cloud Solutions For Business Growth');
    } else if (from.includes('big-data-beginner-hadoop-ecosystem')) {
      setCourse('Big Data Beginner Hadoop Ecosystem');
    } else if (from.includes('big-data-advanced-hadoop-ecosystem')) {
      setCourse('Big Data Advanced Hadoop Ecosystem');
    } else if (from.includes('kubernetes')) {
      setCourse('Kubernetes');
    } else if (from.includes('machine-learning')) {
      setQuestions([]);
      setCourse('Machine Learning');
    } else if (from.includes('microsoft-azure-fundamentals1')) {
      setQuestions(Azure1);
      setCourse(`Microsoft Azure Fundamentals: ${moduleName}`);
    } else if (from.includes('microsoft-azure-fundamentals2')) {
      setQuestions(Azure1);
      setCourse(`Microsoft Azure Fundamentals: ${moduleName}`);
    } else if (from.includes('microsoft-azure-fundamentals3')) {
      setQuestions(Azure2);
      setCourse(`Microsoft Azure Fundamentals: ${moduleName}`);
    } else if (from.includes('microsoft-azure-fundamentals4')) {
      setQuestions(Azure3);
      setCourse(`Microsoft Azure Fundamentalst: ${moduleName}`);
    } else if (from.includes('microsoft-azure-fundamentals5')) {
      setQuestions(Azure4);
      setCourse(`Microsoft Azure Fundamentals: ${moduleName}`);
    } else if (from.includes('microsoft-azure-fundamentals6')) {
      setQuestions(Azure5);
      setCourse(`Microsoft Azure Fundamentals: ${moduleName}`);
    } else if (from.includes('microsoft-azure-fundamentals7')) {
      setQuestions(Azure6);
      setCourse(`Microsoft Azure Fundamentals: ${moduleName}`);
    } else if (from.includes('microsoft-azure-fundamentals')) {
      setQuestions(AzureTotalModule);
      setCourse('Microsoft Azure Final Exam');
    } else if (from.includes('microsoft-sharepoint')) {
      setQuestions(MsSharepointExam);
      setCourse('Microsoft Sharepoint Exam');
    }
  }, [from, moduleName]);

  if (questions) {
    sessionStorage.setItem('questionsLength', JSON.stringify(questions.length));
  } else {
    sessionStorage.setItem('questionsLength', '');
  }

  const handleClickAnswer = ({ target }, { isCorrect }, index) => {
    const { innerText } = target;

    setState({
      index: index,
      isActive: !state.isActive,
    });

    // let formData be the current question with its corresponding answer
    const formData = {
      question: questions[currentQuestion].questionText,
      answer: innerText,
    };

    if (isCorrect) {
      const lastSavedQuestion =
        JSON.parse(sessionStorage.getItem('questions')) || [];
      const lastSavedAnswer =
        JSON.parse(sessionStorage.getItem('answers')) || [];

      if (lastSavedQuestion.length === 0 && lastSavedAnswer.length === 0) {
        lastSavedQuestion.push(formData.question) &&
          lastSavedAnswer.push(formData.answer);
        sessionStorage.setItem('questions', JSON.stringify(lastSavedQuestion));
        sessionStorage.setItem('answers', JSON.stringify(lastSavedAnswer));
      } else if (
        lastSavedQuestion.includes(formData.question) &&
        lastSavedAnswer.includes(formData.answer)
      ) {
        return lastSavedQuestion;
      } else {
        lastSavedQuestion.push(formData.question) &&
          lastSavedAnswer.push(formData.answer);
        sessionStorage.setItem('questions', JSON.stringify(lastSavedQuestion));
        sessionStorage.setItem('answers', JSON.stringify(lastSavedAnswer));
      }
    }
  };

  const handlePreviousButton = (e) => {
    e.preventDefault();
    const previousQuestion = currentQuestion - 1;
    setCurrentQuestion(previousQuestion);
    setState({
      isActive: !state.isActive,
    });
  };

  const handleNextButton = (e) => {
    e.preventDefault();
    const nextQuestion = currentQuestion + 1;
    setCurrentQuestion(nextQuestion);
    setState({
      isActive: !state.isActive,
    });
  };

  return (
    <div className="quiz-body">
      <header className="quiz-header-caption">
        <h1>{course}</h1>
      </header>

      <h3 id="center-header">Final Exam</h3>

      <section className="quiz">
        {questions.length > 0 ? (
          <section className="quiz-section">
            <h3>
              Question {currentQuestion + 1}/{questions.length}
            </h3>
            <form>
              <p>
                {questions[currentQuestion]
                  ? questions[currentQuestion].questionText
                  : ''}
              </p>

              {questions[currentQuestion]
                ? questions[currentQuestion].answerOptions.map(
                    (answerOption, index) => (
                      <label
                        key={index + 1}
                        style={{
                          backgroundColor:
                            index === state.index ? '#6499e9' : '',
                          color: index === state.index ? '#fff' : '',
                        }}
                        onClick={(e) =>
                          handleClickAnswer(e, answerOption, index)
                        }
                      >
                        {answerOption.answerText}
                      </label>
                    )
                  )
                : ' '}

              <div>
                {currentQuestion <= 0 ? null : (
                  <button onClick={handlePreviousButton}>Previous</button>
                )}
                {currentQuestion >= questions.length - 1 ? (
                  <button
                    style={{ marginLeft: '20px' }}
                    onClick={() =>
                      navigate('/examResult', {
                        state: {
                          from: from,
                          course: course,
                          moduleName: moduleName,
                        },
                      })
                    }
                  >
                    See Results
                  </button>
                ) : (
                  <button onClick={handleNextButton}>Next</button>
                )}
              </div>
            </form>
          </section>
        ) : (
          <section className="quiz-section">
            <h3>There is no Exam at this time!</h3>

            <button className="home-button">
              <NavLink
                to="/dashboard"
                style={{ color: '#fff' }}
              >
                Take another course
              </NavLink>
            </button>
          </section>
        )}
      </section>
    </div>
  );
};

export default CourseContent;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Board.css';

const Modal = ({ open, onClose }) => {
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    const language = localStorage.getItem('editorLanguage');
    const today = new Date();
    const firstDay = today.getDate() - today.getDay();
    const startWeek = new Date(today.setDate(firstDay));
    const endWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );

    const epochStartWeek = Date.parse(startWeek);
    const epochEndtWeek = Date.parse(endWeek);

    let url = 'https://gyhdko9hgh.execute-api.eu-west-1.amazonaws.com/prod';
    let formData = {
      challenge_category: language,
      method: 'leaderboard_category',
      start_date: epochStartWeek,
      end_date: epochEndtWeek,
    };

    axios
      .post(url, formData)
      .then((response) => {
        let objResponse = JSON.parse(response.data.body);
        setLeaderboard(objResponse);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleClose = (e) => {
    onClose && onClose(e);
  };

  // returns nothing if props.open is false
  if (!open) {
    return null;
  }

  return (
    <div className="modal-parent">
      <div className="board">
        <div>
          <p className="score-sub-header">
            Category: {leaderboard.ChallengeCategory}
          </p>
          <button onClick={handleClose}>Close</button>
        </div>

        {leaderboard?.Items.length > 0 ? (
          <table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Score</td>
              </tr>
            </thead>

            {leaderboard.Items.map((board) => (
              <tbody key={board.email}>
                <tr>
                  <td>{board.username}</td>
                  <td> {board.totalscore} </td>
                </tr>
              </tbody>
            ))}
          </table>
        ) : (
          <h4 style={{ textAlign: 'center', marginTop: 40 }}>
            Leaderboard will be updated monthly
          </h4>
        )}
      </div>
    </div>
  );
};

export default Modal;

import React, { useEffect, useState, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import './Landing.css';

import JavaScriptQuestions from '../Questions/JavaScriptQuestions';
import PythonQuestions from '../Questions/PythonQuestions';
import { defineTheme } from '../lib/defineTheme';
import CodeEditorWindow from './CodeEditorWindow';
import Modal from './Modals/Modal';
import ErrorModal from './Modals/ErrorModal';
import Board from './Modals/Board';
import axios from 'axios';

const Landing = () => {
  const name = useMemo(() => localStorage.getItem('name'), []);
  const email = useMemo(() => localStorage.getItem('userEmail'), []);
  const [data, setData] = useState('');
  const [code, setCode] = useState([]);
  const [answer, setAnswer] = useState('');
  const [language, setLanguage] = useState('');
  const [theme, setTheme] = useState('cobalt');
  const [processing, setProcessing] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openLeaderModal, setOpenLeaderModal] = useState(false);

  useEffect(() => {
    const getLanguage = localStorage.getItem('editorLanguage');
    setLanguage(getLanguage);

    if (getLanguage === `"JavaScript"`) {
      setCode(JavaScriptQuestions);
    } else if (getLanguage === `"Python"`) {
      setCode(PythonQuestions);
    } else {
      setCode('');
    }
  }, []);

  // on any key press or any type on the editor, the data is gotten and set to answer
  const onChange = (action, data) => {
    switch (action) {
      case 'code': {
        setAnswer(data);
        break;
      }
      default: {
        toast.info('case not handled!', action, data);
      }
    }
  };

  // handle function to open modal
  // modal could be success or failure depending on the usecase
  const handleOpenModal = (e) => {
    e.preventDefault();
    setProcessing(true);
    let expectedAnswer = code[currentQuestion].answerFunction;

    if (answer.replace(/\s/g, '') === expectedAnswer.replace(/\s/g, '')) {
      setProcessing(false);
      let url = 'https://gyhdko9hgh.execute-api.eu-west-1.amazonaws.com/prod';
      let score = 2;

      const dataForm = {
        challenge_category: language,
        challenge_name: code[currentQuestion].caption,
        username: name,
        email: email,
        score: score,
        method: 'submit',
      };

      axios
        .post(url, dataForm)
        .then((res) => {
          setData(res.data.body);
        })
        .catch((error) => console.log(error));

      setOpenModal(!openModal);
    } else {
      setProcessing(false);
      setOpenErrorModal(!openErrorModal);
    }
  };

  // function to go to the next challenge after successful challenge
  const handleCompile = (e) => {
    e.preventDefault();
    const nextQuestion = currentQuestion + 1;
    setCurrentQuestion(nextQuestion);
  };

  // function to go back to previous challenge
  const handlePreviousChallenge = (e) => {
    e.preventDefault();
    const nextQuestion = currentQuestion - 1;
    setCurrentQuestion(nextQuestion);
  };

  const onCheckLeaderBoard = () => {
    setOpenLeaderModal(!openLeaderModal);
  };

  const handleCloseModal = () => {
    setOpenLeaderModal(!openLeaderModal);
  };

  // on load, default theme is the oceanic next theme
  useEffect(() => {
    defineTheme('oceanic-next').then((_) =>
      setTheme({ value: 'oceanic-next', label: 'Oceanic Next' })
    );
  }, []);

  return (
    <div className="coding-challenge">
      <ToastContainer
        rtl={false}
        draggable
        closeOnClick
        pauseOnHover
        autoClose={2000}
        position="top-right"
        pauseOnFocusLoss
        newestOnTop={false}
        hideProgressBar={false}
      />
      {code.length > 0 ? (
        <React.Fragment>
          <div className="header-welcome">
            <div>
              <h3>Welcome,</h3>
              <h1>
                {name}, <span>to The Coding Challenge</span>
              </h1>
            </div>

            <div className="lang-theme-dropdowns">
              <button
                onClick={onCheckLeaderBoard}
                className="leader-button"
              >
                See Leaderboard
              </button>
            </div>
          </div>

          <div className="code-playground">
            {code[currentQuestion] ? (
              <>
                {code[currentQuestion] ? (
                  <h4 id="coding-question-board">
                    <span style={{ fontSize: 20 }}>
                      {currentQuestion + 1}/{code.length}:{' '}
                    </span>
                    {code[currentQuestion] ? code[currentQuestion].caption : ''}
                  </h4>
                ) : (
                  <h4 id="coding-question-board">{''}</h4>
                )}

                <CodeEditorWindow
                  theme={theme.valueOf}
                  onChange={onChange}
                  language={language}
                  next={
                    code[currentQuestion]
                      ? code[currentQuestion].questionText
                      : ''
                  }
                  code={
                    code[currentQuestion]
                      ? code[currentQuestion].questionText
                      : ''
                  }
                />

                <div className="button-div">
                  <button onClick={handleOpenModal}>
                    {processing ? 'Compiling...' : 'Compile my code'}
                  </button>

                  <div>
                    <button
                      onClick={handlePreviousChallenge}
                      className={
                        currentQuestion === 0 ? 'remove-btn-prev' : null
                      }
                    >
                      Previous
                    </button>
                    <button onClick={handleCompile}>Skip</button>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ margin: '50px 0' }}>
                <center>
                  <h2>Hurray!!!...You have completed the coding challenge</h2>
                  <p>Let's see where you rank in this week's leaderboard</p>
                  <Link to="/leaderboard">
                    <button
                      style={{
                        borderWidth: 0,
                        borderRadius: 5,
                        color: '#344054',
                        fontSize: 16,
                        fontWeight: '500',
                        padding: '5px 20px',
                      }}
                    >
                      Proceed
                    </button>
                  </Link>
                </center>
              </div>
            )}
          </div>

          <Board
            open={openLeaderModal}
            onClose={handleCloseModal}
          />
          <ErrorModal
            open={openErrorModal}
            onClose={handleOpenModal}
          />
          <Modal
            response={data}
            open={openModal}
            compile={handleCompile}
            onClose={handleOpenModal}
          />
        </React.Fragment>
      ) : (
        <>
          <h1 style={{ marginTop: 60, fontSize: 24, fontFamily: 'Inter' }}>
            No coding challenge for {language} language at this time.
          </h1>
        </>
      )}
    </div>
  );
};
export default Landing;

const questions = [
	{
		id: 1,
		questionText: 'Which of the following are tools in Azure DevOps?',
		answerOptions: [
			{
				answerText: 'Azure Boards',
				isCorrect: true
			},
			{
				answerText: 'Azure Operations',
				isCorrect: false
			},
			{
				answerText: 'Azure Deployment',
				isCorrect: false
			},
			{
				answerText: 'Azure Automation',
				isCorrect: true
			},
			{
				answerText: 'Amazon Artifacts',
				isCorrect: true
			},
			{
				answerText: 'Amazon Pipelines',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText:
			'What is the purpose of "models" in machine learning and artificial intelligence?',
		answerOptions: [
			{
				answerText: 'Defining the version of your machine learning application',
				isCorrect: true
			},
			{
				answerText: 'The size and capacity of the machine learning service',
				isCorrect: false
			},
			{
				answerText: 'The framework for integrating other Azure services with your particular machine learning instance				.',
				isCorrect: false
			},
			{
				answerText: 'The definition of what you want your machine learning implementation to learn.	',
				isCorrect: true
			}
		]
	},
	{
		id: 3,
		questionText: 'Which are Internet of Things services on Azure?',
		answerOptions: [
			{
				answerText: "IOT Management Studio",
				isCorrect: false
			},
			{
				answerText: 'IoT Hub',
				isCorrect: true
			},
			{
				answerText: 'IoT Services',
				isCorrect: true
			},
			{
				answerText: 'IoT Virtual Box',
				isCorrect: false
			},
			{
				answerText: 'IoT Central',
				isCorrect: true
			},
			{
				answerText: 'IoT App Services',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText:
			'What is the best definition of Azure DevOps?',
		answerOptions: [
			{
				answerText: 'A specific section of the Azure portal where you can manage operational parts of your infrastructure				',
				isCorrect: true
			},
			{
				answerText: 'A way to write better code and find bugs faster',
				isCorrect: false
			},
			{
				answerText: 'A suite of five different tools to create more robust software, faster',
				isCorrect: true
			},
			{
				answerText: 'A platform to manage Azure resources meant for development, such as App Services, Azure Functions, and Visual Studio Online.',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText:
			'When would you use Azure Logic Apps to solve a problem?',
		answerOptions: [
			{
				answerText: 'To schedule, automate, and orchestrate tasks and processes',
				isCorrect: true
			},
			{
				answerText: 'To perform single tasks that run once every time they are invoked.',
				isCorrect: false
			},
			{
				answerText: 'To connect systems both inside and outside of the Azure platform',
				isCorrect: true
			},
			{
				answerText: 'When you have to use more than one Azure subscription',
				isCorrect: false
			},
			{
				answerText: 'For fundamental compute actions that can be run millions of times per second if needed',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText:
			'What are some of the likely outcomes from using Azure Data Lake Analytics to analyze big data?			',
		answerOptions: [
			{
				answerText: 'More secure access to company infrastructure				',
				isCorrect: false
			},
			{
				answerText: 'Cheaper and more accessible cloud computing products',
				isCorrect: false
			},
			{
				answerText: 'A cost reduction on data storage',
				isCorrect: true
			},
			{
				answerText: 'Creating products better aligned to customer needs',
				isCorrect: true
			},
			{
				answerText: 'Better decision-making from immediate analysis',
				isCorrect: true
			},
			{
				answerText: 'A decoupling of business decisions from the development of new products',
				isCorrect: false
			}
		]
	},
];

export default questions;

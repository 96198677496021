import React, { useState, useEffect } from 'react';
import './Home.css';
import './Dashboard.css';
import ReactPlayer from 'react-player';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { BsFillPlayFill } from 'react-icons/bs';
import { Courses } from '../courses/Courses';
import { useLocation, Link } from 'react-router-dom';
import { getVideo } from '../../../services/video-service';
import { getUserDetails } from '../../../services/auth-service';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Subscription from './subscription/Subscription';

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // states
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [videoIndex, setVideoIndex] = useState(0);
  const [searchField, setSearchField] = useState('');
  const [isUserEnrolled, setIsUserEnrolled] = useState(false);

  useEffect(() => {
    // remove Link state i.e set state.subscribed to empty object
    window.history.replaceState({}, document.title);

    const getUser = async () => {
      try {
        // get user access token
        const currentAccessToken = localStorage.getItem('access_token');
        const userResponse = await getUserDetails({
          access_token: currentAccessToken,
        });
        if (!currentAccessToken) {
          navigate('/login');
        } else {
          setUsername(userResponse.data.UserAttributes[2].Value);
          localStorage.setItem(
            'name',
            userResponse.data.UserAttributes[2].Value
          );
          localStorage.setItem(
            'userEmail',
            userResponse.data.UserAttributes[3].Value
          );
        }

        // Get Video Details to check if user has subscibed to courses
        const videoResponse = await getVideo();
        let videoDetails = JSON.parse(videoResponse.data.body).result;
        setLoading(false);
        if (videoDetails === null) {
          localStorage.setItem('isUserEnrolled', false);
          setIsUserEnrolled(false);
        } else {
          localStorage.setItem('isUserEnrolled', true);
          setIsUserEnrolled(true);
        }
      } catch (error) {
        navigate('/login');
      }
    };
    getUser();

    return () => getUser();
  }, [navigate]);

  //function to handle change in search bar
  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const handlePlayImgVideo = (e) => {
    setVideoIndex(e);
  };

  //filter the courses for the searchfield to identify
  const filteredCourses = Courses.filter((course) =>
    course.courseTitle.toLowerCase().includes(searchField.toLowerCase())
  );

  return (
    <React.Fragment>
      {loading ? (
        <div className="loader-overlay">
          <PropagateLoader
            color="#516BEB"
            size={30}
          />
        </div>
      ) : (
        <div className="dashboard-div">
          {isUserEnrolled ? (
            <div className="dashboard-home">
              {/* All the available courses  */}
              <div className="allcourses">
                <section className="profile-header">
                  <h1>Subscribed Courses</h1>

                  <div>
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={handleChange}
                    />
                    <FiSearch id="font" />
                  </div>
                </section>

                <div className="allcourses-content">
                  {searchField
                    ? filteredCourses.map((course, i) => (
                        <div
                          key={i}
                          className="course"
                        >
                          <div className="course-thumbnail">
                            {course.id === videoIndex ? (
                              <img
                                src={course.image}
                                alt="img thumbnail"
                                style={{ display: 'none' }}
                              />
                            ) : (
                              <React.Fragment>
                                <img
                                  src={course.image}
                                  alt="course img"
                                />
                                <div
                                  className="play-icon-body"
                                  onClick={() => handlePlayImgVideo(course.id)}
                                >
                                  <BsFillPlayFill className="play-icon" />
                                </div>
                              </React.Fragment>
                            )}
                            {course.id === videoIndex ? (
                              <ReactPlayer
                                controls={true}
                                className="react-player"
                                url={course.vid}
                                width="100%"
                                height="100%"
                                config={{
                                  file: {
                                    attributes: {
                                      controlsList: 'nodownload',
                                      onContextMenu: (e) => e.preventDefault(),
                                    },
                                  },
                                }}
                              />
                            ) : null}{' '}
                          </div>

                          <Link
                            to={course.link}
                            state={{
                              from: location.pathname,
                              isUserEnrolled,
                            }}
                          >
                            <p>{course.courseTitle}</p>
                          </Link>
                        </div>
                      ))
                    : Courses.map((course, i) => (
                        <div
                          key={course.id}
                          className="course"
                        >
                          <div className="course-thumbnail">
                            {course.id === videoIndex ? (
                              <img
                                src={course.image}
                                alt="img thumbnail"
                                style={{ display: 'none' }}
                              />
                            ) : (
                              <React.Fragment>
                                <img
                                  src={course.image}
                                  alt="course img"
                                />
                                <div
                                  className="play-icon-body"
                                  onClick={() => handlePlayImgVideo(course.id)}
                                >
                                  <BsFillPlayFill className="play-icon" />
                                </div>
                              </React.Fragment>
                            )}
                            {course.id === videoIndex ? (
                              <ReactPlayer
                                controls={true}
                                className="react-player"
                                url={course.vid}
                                width="100%"
                                height="100%"
                                config={{
                                  file: {
                                    attributes: {
                                      controlsList: 'nodownload',
                                      onContextMenu: (e) => e.preventDefault(),
                                    },
                                  },
                                }}
                              />
                            ) : null}
                          </div>

                          <Link
                            to={course.link}
                            state={{
                              from: location.pathname,
                              isUserEnrolled,
                            }}
                          >
                            <p>{course.courseTitle}</p>
                          </Link>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          ) : (
            <Subscription username={username} />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Dashboard;

import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import './InnovationChallenge.css';
import { useForm, Controller } from 'react-hook-form';

// import react toastify
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// import react-phone-number
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';

const InnovationChallenge = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [applied, setApplied] = useState(null);
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm();

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: 'white' }}
      spin
    />
  );

  const applyAnswers = [
    {
      id: 1,
      answer: 'Yes',
    },
    {
      id: 2,
      answer: 'No',
    },
  ];

  // Register Submit Function
  const onSubmit = (data) => {
    setLoading(true);
    const dataForm = {
      individualteamname: data.name,
      mobilenumber: data.mobilenumber,
      emailaddress: data.email,
      appliedbefore: applied,
      descriptionidea: data.descriptionidea,
    };

    const url =
      'https://ph2zsmhv8k.execute-api.eu-west-1.amazonaws.com/Prod/innovationchallengesubmission';
    axios
      .post(url, dataForm)
      .then((res) => {
        setLoading(false);
        toast.success('Sign up successful!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });
        navigate('/dashboard');
      })
      .catch(function (error) {
        setLoading(false);
        toast.error(error, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });
      });
  };

  return (
    <Fragment>
      <ToastContainer />

      <section className="innovation-container">
        <div>
          <div>
            <div>
              <h2 className="header-tag">
                Let's get started CloudPlexo - ilearncloud $5,000 Equity
                Innovation Challenge
              </h2>

              <br />

              <form>
                <label htmlFor="name">
                  Individual/ Team Name<span style={{ color: 'red' }}>*</span>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    autoComplete="off"
                    className={errors.name && 'invalid'}
                    {...register('name', {
                      required: 'Please enter your name',
                      minLength: {
                        value: 2,
                        message: 'Your name must exceed 1 characters',
                      },
                    })}
                  />
                  {errors.name ? (
                    <p className="error">{errors.name.message}</p>
                  ) : null}
                </label>

                <label htmlFor="mobilenumber">
                  Mobile<span style={{ color: 'red' }}>*</span>
                  <Controller
                    control={control}
                    {...register('mobilenumber', {
                      required: 'Enter a phone number',
                      minLength: {
                        value: 10,
                        message: 'Enter a valid phone number',
                      },
                    })}
                    render={({ field: { innerRef, ...field } }) => (
                      <PhoneInput
                        {...field}
                        inputClass='mobileInput'
                        inputStyle={{
                          width: '100%',
                          height: '4vh',
                          border: '2px solid #516beb',
                        }}
                        control={control}
                        country={'ng'}
                      />
                    )}
                  />
                  {errors.mobilenumber && (
                    <p className="error">{errors.mobilenumber.message}</p>
                  )}
                </label>

                <label htmlFor="email">
                  Email Address<span style={{ color: 'red' }}>*</span>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    autoComplete="off"
                    className={errors.email && 'invalid'}
                    {...register('email', {
                      required: 'Please enter your email address',
                      pattern: {
                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        message: 'Please enter a valid email address',
                      },
                    })}
                  />
                  {errors.email && (
                    <p className="error">{errors.email.message}</p>
                  )}
                </label>

                <p className="box-check">
                  Have you applied to the CloudPlexo -ilearncloud Innovation
                  Challenge previously?
                </p>
                <div className="box-check-button">
                  {applyAnswers.map(({ id, answer }) => (
                    <Fragment key={id}>
                      <p
                        onClick={() => setApplied(answer)}
                        className={answer === applied ? 'active-button' : null}
                      >
                        {answer}
                      </p>
                    </Fragment>
                  ))}
                </div>

                <label htmlFor="descriptionidea">
                  Tell us about your idea in the box below
                  <span style={{ color: 'red' }}>*</span>
                  <textarea
                    id="descriptionidea"
                    name="descriptionidea"
                    cols="30"
                    rows="10"
                    {...register('descriptionidea', {
                      required: 'Please describe your idea',
                      minLength: {
                        value: 20,
                        message: 'Description must exceed 20 characters',
                      },
                    })}
                  />
                  {errors.descriptionidea ? (
                    <p className="error">{errors.descriptionidea.message}</p>
                  ) : null}
                </label>
                <br />

                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  {loading ? <Spin indicator={antIcon} /> : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default InnovationChallenge;

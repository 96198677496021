const questions = [
	{
		id: 1,
		questionText: 'Which of the following is not a NoSQL database',
		answerOptions: [
			{
				answerText: 'SQL Server',
				isCorrect: true
			},
			{
				answerText: 'MongoDB',
				isCorrect: false
			},
			{
				answerText: 'Cassandra',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 2,
		questionText: 'Point out the correct statement',
		answerOptions: [
			{
				answerText:
					'Documents can contain many different key-value pairs, or key-array pairs, or even nested documents',
				isCorrect: false
			},
			{
				answerText:
					'MongoDB has official drivers for a variety of popular programmi ng languages and development environments',
				isCorrect: false
			},
			{
				answerText:
					'When compared to relational databases, NoSQL database are more scalable and provide superior performance',
				isCorrect: false
			},
			{
				answerText: 'All of the mentioned',
				isCorrect: true
			}
		]
	},
	{
		id: 3,
		questionText: 'Which of the following is a NoSQL Database Type?',
		answerOptions: [
			{
				answerText: 'SQL',
				isCorrect: false
			},
			{
				answerText: 'Document databases',
				isCorrect: true
			},
			{
				answerText: 'JSON',
				isCorrect: false
			},
			{
				answerText: 'All of the mentioned',
				isCorrect: false
			}
		]
	},
	{
		id: 4,
		questionText: 'Which of the following is a wide-column store?',
		answerOptions: [
			{
				answerText: 'Cassandra',
				isCorrect: true
			},
			{
				answerText: 'Riak',
				isCorrect: false
			},
			{
				answerText: 'MongoDB',
				isCorrect: false
			},
			{
				answerText: 'All of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 5,
		questionText: 'Point out the wrong statement',
		answerOptions: [
			{
				answerText: 'Non Relational databases require that schemas be defined before you can add data',
				isCorrect: true
			},
			{
				answerText: 'NoSQL databases are built to allow the insertion of data without a predefined schema',
				isCorrect: false
			},
			{
				answerText: 'NewSQL databases are built to allow the insertion of data without a predefined schema',
				isCorrect: false
			},
			{
				answerText: 'All of the mentioned',
				isCorrect: false
			}
		]
	},
	{
		id: 6,
		questionText: `"Sharding" a database across many server instances can be achieved with__________`,
		answerOptions: [
			{
				answerText: 'LAN',
				isCorrect: false
			},
			{
				answerText: 'SAN',
				isCorrect: true
			},
			{
				answerText: 'MAN',
				isCorrect: false
			},
			{
				answerText: 'All of the mentioned',
				isCorrect: false
			}
		]
	},
	{
		id: 7,
		questionText:
			'Most NoSQL databases support automatic _______ meaning that you get high availability and disaster recovery',
		answerOptions: [
			{
				answerText: 'processing',
				isCorrect: false
			},
			{
				answerText: 'scalability',
				isCorrect: false
			},
			{
				answerText: 'replication',
				isCorrect: true
			},
			{
				answerText: 'All of the mentioned',
				isCorrect: false
			}
		]
	},
	{
		id: 8,
		questionText: 'Which of the following are the simplest NoSQL databases?',
		answerOptions: [
			{
				answerText: 'Key-value',
				isCorrect: true
			},
			{
				answerText: 'Wide-column',
				isCorrect: false
			},
			{
				answerText: 'Document',
				isCorrect: false
			},
			{
				answerText: 'All of the mentioned',
				isCorrect: false
			}
		]
	},
	{
		id: 9,
		questionText: '_______ stores are used to store information about networks, such as social connections',
		answerOptions: [
			{
				answerText: 'Key-value',
				isCorrect: false
			},
			{
				answerText: 'Wide-column',
				isCorrect: false
			},
			{
				answerText: 'Document',
				isCorrect: false
			},
			{
				answerText: 'Graph',
				isCorrect: true
			}
		]
	},
	{
		id: 10,
		questionText: 'NoSQL databases is used mainly for handling large volumes of _________ data',
		answerOptions: [
			{
				answerText: 'unstructured',
				isCorrect: true
			},
			{
				answerText: 'structured',
				isCorrect: false
			},
			{
				answerText: 'semi-structured',
				isCorrect: false
			},
			{
				answerText: 'all of the mentioned',
				isCorrect: false
			}
		]
	},
	{
		id: 11,
		questionText: 'Which one is best to explain about MongoDB processes?',
		answerOptions: [
			{
				answerText: 'mongod.exe is a shell process and mongo.exe is an actual database process',
				isCorrect: false
			},
			{
				answerText: 'mongo.exe is a shell process and mongod.exe is an actual database process',
				isCorrect: true
			},
			{
				answerText: 'To run the database, we need to process the mongoDB server - mongos.exe',
				isCorrect: false
			},
			{
				answerText: 'To import database backup dump, we can use mongodump.exe',
				isCorrect: false
			}
		]
	},
	{
		id: 12,
		questionText: 'With which storage engine does MongoDB uses memory mapped files to store data?',
		answerOptions: [
			{
				answerText: 'MMAPv1',
				isCorrect: true
			},
			{
				answerText: 'MMAPv2',
				isCorrect: false
			},
			{
				answerText: 'WiredTiger',
				isCorrect: false
			},
			{
				answerText: 'None of the above',
				isCorrect: false
			}
		]
	},
	{
		id: 13,
		questionText: 'What will be the output - \n db.posts.find({ likes: { $gt: 200 }, likes: { $lt: 400 }})',
		answerOptions: [
			{
				answerText: 'Posts with likes greater than 200 but less than 400',
				isCorrect: false
			},
			{
				answerText: 'Posts with likes less than 400',
				isCorrect: true
			},
			{
				answerText: 'Will return syntax error',
				isCorrect: false
			},
			{
				answerText: 'Posts with likes greater than or equal to 200 but less than or equal to 400',
				isCorrect: false
			},
			{
				answerText: 'Posts with likes greater than or equal to 200 but less than or equal to 400',
				isCorrect: false
			}
		]
	},
	{
		id: 14,
		questionText: 'Which statement is correct:',
		answerOptions: [
			{
				answerText:
					'For read-heavy applications, deploy sharding and add one or more shards to a sharded cluster to distribute load among mongod instances',
				isCorrect: false
			},
			{
				answerText:
					'For read-heavy applications, increase the size of your replica set and distribute read operations to secondary members',
				isCorrect: true
			}
		]
	},
	{
		id: 15,
		questionText: `Which of the following can provide an insight of MongoDB's memory usage?`,
		answerOptions: [
			{
				answerText: 'locks.deadlockCoun',
				isCorrect: false
			},
			{
				answerText: 'globalstats',
				isCorrect: false
			},
			{
				answerText: 'serverStatus',
				isCorrect: true
			}
		]
	},
	{
		id: 16,
		questionText:
			'What is the output of the following query: \n db.posts.aggregate([{ $group: { _id: "$author", x: { $sum: $likes }}}])',
		answerOptions: [
			{
				answerText: 'Sum of all likes on all posts by an author, grouped by author',
				isCorrect: true
			},
			{
				answerText: 'A number of posts by an author',
				isCorrect: false
			},
			{
				answerText: 'Sum of likes on all the posts by all the authors',
				isCorrect: false
			},
			{
				answerText: 'Average of likes on all the posts of an author, grouped by author',
				isCorrect: false
			}
		]
	},
	{
		id: 17,
		questionText: 'Which field provides the amount of resident memory in use?',
		answerOptions: [
			{
				answerText: 'mem.resident',
				isCorrect: true
			},
			{
				answerText: 'memory.resident',
				isCorrect: false
			},
			{
				answerText: 'memory.president',
				isCorrect: false
			}
		]
	},
	{
		id: 18,
		questionText: 'Which operator can reverse the effects of a double unwind operation?',
		answerOptions: [
			{
				answerText: '$wind',
				isCorrect: false
			},
			{
				answerText: '$wind.$wind',
				isCorrect: false
			},
			{
				answerText: '$push',
				isCorrect: true
			},
			{
				answerText: `Can't be reversed`,
				isCorrect: false
			}
		]
	},
	{
		id: 19,
		questionText: 'The maximum size of a MongoDB document is.',
		answerOptions: [
			{
				answerText: '2MB',
				isCorrect: false
			},
			{
				answerText: '12MB',
				isCorrect: false
			},
			{
				answerText: 'There is no maximum size. It depends on RAM',
				isCorrect: false
			},
			{
				answerText: '16MB',
				isCorrect: true
			}
		]
	}
];

export default questions;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './CourseContent.css';

// import exam questions
import unixExam from './Questions/UnixQuestions';
import dataScienceExam from './Questions/DataScience';
import devopsExam from './Questions/DevopsQuestions';
import gitExam from './Questions/GitandGithubQuestions';
import bigDataExam from './Questions/BigDataQuestions';
import awsExam from './Questions/AwsCertifiedQuestions';
import javaExam from './Questions/JavaProgrammingQuestions';
import networkingExam from './Questions/NetworkingQuestions';
import relationalDBExam from './Questions/RelationalDBQuestions';
import continousIntegrationExam from './Questions/ContinousIntegration';
import nonRelationalDBExam from './Questions/NonRelationalDBQuestions';

const CorrectAnswers = () => {
  const location = useLocation();
  const { from } = location.state;

  const [course, setCourse] = useState('');
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (from.includes('big-data-with-spark')) {
      setQuestions(bigDataExam);
      setCourse('Big Data with Spark');
    } else if (from.includes('unix-shell-scripting')) {
      setQuestions(unixExam);
      setCourse('Unix Shell Scripting');
    } else if (from.includes('aws-certified-cloud-practitioner')) {
      setQuestions(awsExam);
      setCourse('AWS Certified Cloud Practitioner');
    } else if (from.includes('data-science-and-ml-with-r')) {
      setQuestions(dataScienceExam);
      setCourse('Data Science and Machine Learning with R');
    } else if (from.includes('devops-with-ansible')) {
      setQuestions(devopsExam);
      setCourse('Devops with Ansible');
    } else if (from.includes('intro-to-version-control')) {
      setQuestions(gitExam);
      setCourse('Intro to Version Control');
    } else if (from.includes('core-java-essentials')) {
      setQuestions(javaExam);
      setCourse('Core Java Essentials');
    } else if (from.includes('networking-ccna')) {
      setQuestions(networkingExam);
      setCourse('Networking CCNA');
    } else if (from.includes('databases-and-postgreSQL')) {
      setQuestions(relationalDBExam);
      setCourse('Databases and PostgreSQL');
    } else if (from.includes('intro-to-continous-integration-ci-jenkins')) {
      setQuestions(continousIntegrationExam);
      setCourse('Intro to Continous Integration CI Jenkins');
    } else if (from.includes('no-sql-and-mongoDB')) {
      setQuestions(nonRelationalDBExam);
      setCourse('No SQL and MongoDB');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (questions) {
      sessionStorage.setItem(
        'questionsLength',
        JSON.stringify(questions.length)
      );
    } else {
      sessionStorage.setItem('questionsLength', '');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="quiz-body">
      <header className="quiz-header-caption">
        <h1>{course}</h1>
      </header>

      <h3 id="center-header">Correct Results</h3>

      <section className="quiz">
        <section>
          {questions?.map((question) => (
            <section
              className="quiz-section"
              key={question.id}
            >
              <h3>
                Question {question.id}/{questions.length}
              </h3>
              <form>
                <p>{question.questionText}</p>

                {question.answerOptions.map((answerOption, index) => (
                  <label
                    style={
                      answerOption.isCorrect
                        ? { backgroundColor: '#6499e9', color: '#fff' }
                        : null
                    }
                    key={index}
                  >
                    {answerOption.answerText}
                  </label>
                ))}
              </form>
            </section>
          ))}
        </section>
      </section>
    </div>
  );
};

export default CorrectAnswers;

import React, { Component } from 'react';
import classes from './nav.module.css';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/company_logo/company logo light.jpg';
class nav extends Component {
  mobileDropdown = document.getElementById('mobile-nav-links-id');

  state = {
    mobileDropdown: false,
  };

  drop = () => {
    this.setState({
      mobileDropdown: !this.state.mobileDropdown,
    });
  };

  render() {
    const { mobileDropdown } = this.state;
    return (
      <nav>
        {/* below is the hamburger toggle menu bar on small screens */}
        <div className={classes['mobile-navbar']}>
          <div className={classes['mobile-nav-body']}>
            <div className={classes['icon-logo']}>
              <img
                src={logo}
                alt="logo"
              />
            </div>

            <div
              className={
                classes['mobile-hamburger-btn'] +
                ' ' +
                (mobileDropdown ? classes['close'] : classes[' '])
              }
              onClick={this.drop}
            />
          </div>

          <ul
            className={
              classes['mobile-nav-links'] +
              ' ' +
              (mobileDropdown ? classes['open'] : classes[''])
            }
            id="mobile-nav-links-id"
          >
            <a
              href="https://ilearncloud.io"
              className={classes['mobile-nav-list']}
            >
              Home
            </a>
            <a
              href="https://ilearncloud.io/business"
              className={classes['mobile-nav-list']}
            >
              Business
            </a>
            <a
              href="https://ilearncloud.io/pricing"
              className={classes['mobile-nav-list']}
            >
              Pricing
            </a>
            <NavLink
              to="/signup"
              className={classes['mobile-nav-list']}
            >
              Register
            </NavLink>
            <a
              href="https://ilearncloud.io/faq"
              className={classes['mobile-nav-list']}
            >
              FAQ
            </a>
            <a
              href="https://ilearncloud.io/contact"
              className={classes['mobile-nav-list']}
            >
              Contact
            </a>
          </ul>
        </div>

        {/* below is the navbar on desktop screens */}
        <div className={classes['desktop-navbar']}>
          <div className={classes['icon-logo']}>
            <img
              src={logo}
              alt="logo"
              className={classes['desktop-navbar-img']}
            />
          </div>

          <div className={classes['desktop-nav-links']}>
            <a
              href="https://ilearncloud.io"
              className={classes['desktop-nav-list']}
            >
              Home
            </a>
            <a
              href="https://ilearncloud.io/business"
              className={classes['desktop-nav-list']}
            >
              Business
            </a>
            <a
              href="https://ilearncloud.io/pricing"
              className={classes['desktop-nav-list']}
            >
              Pricing
            </a>
            <NavLink
              to="/signup"
              className={classes['desktop-nav-list']}
            >
              Register
            </NavLink>
            <a
              href="https://ilearncloud.io/faq"
              className={classes['desktop-nav-list']}
            >
              FAQ
            </a>{' '}
            <a
              href="https://ilearncloud.io/contact"
              className={classes['desktop-nav-list']}
            >
              Contact Us
            </a>
            <NavLink
              to="/login"
              className={classes['login-link']}
            >
              Log In
            </NavLink>
          </div>
        </div>
      </nav>
    );
  }
}

export default nav;

import React from 'react';
import { useLocation } from 'react-router-dom';
import './Certificate.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PiDownloadLight } from 'react-icons/pi';

// import images
import trainingLead from '../../../../assets/signature/training.png';
import ceoSignature from '../../../../assets/signature/signature.png';
import logo from '../../../../assets/images/company_logo/company logo light.jpg';

const Certificate = () => {
  const location = useLocation();
  const { course } = location.state;
  const name = localStorage.getItem('name');

  const date = new Date().toDateString().split(' ').slice(1).join(', ');

  const downloadCertificate = (e) => {
    e.preventDefault();
    const certifcateBody = document.querySelector('#certificate');

    html2canvas(certifcateBody).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [6.5, 4.2],
      });
      pdf.addImage(imgData, 'JPEG', 0, 0, 6.5, 4.2);
      pdf.save('ilearn_cloud_certificate.pdf');
    });
  };

  return (
    <section className="certificate-home">
      <div className="certificate-header">
        <h2>Congratuations</h2>
        <h1>{name}</h1>
        <p>
          Congratulations on completing an iLearnCloud course! Showcase your
          achievement on LinkedIn using your Course Certificate.
        </p>
      </div>

      <div
        className="certificate"
        id="certificate"
      >
        <section className="header">
          <img
            src={logo}
            alt="iLearn Cloud logo"
          />
        </section>

        <section className="certificate-body">
          <h3>CERTIFICATE OF COMPLETION</h3>
          <h5>this is to certify that</h5>
          <h4>{name}</h4>
          <p>Has sucessfully completed</p>
          <p>
            <span>{course}</span>
          </p>
          <p>
            Authorised by ilearn Cloud, <br />
            on {date}
          </p>
        </section>

        <section className="certificate-footer">
          <div>
            <img
              src={ceoSignature}
              alt="ilearn cloud ceo signature"
            />
            <hr />
            <p>iLearn Cloud CEO</p>
          </div>

          <div>
            <img
              src={trainingLead}
              alt="head of training signature"
            />
            <hr />
            <p>Head of Training</p>
          </div>
        </section>
      </div>

      <div className="certificate-download-btn">
        <button onClick={downloadCertificate}>
          Download Certificate <PiDownloadLight id="btn-icon" />{' '}
        </button>
      </div>
    </section>
  );
};

export default Certificate;

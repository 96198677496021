import React, { useEffect, useState, useContext } from 'react';
import './VideoPlayer.css';
import Toggle from 'react-toggle';
import './react-toggle-button.css';
import ReactPlayer from 'react-player';
import { Link, useLocation } from 'react-router-dom';
import { getVideo } from '../../../../services/video-service';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { ThemeContext } from '../../../themeContext/ThemeContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const VideoPlayer = ({ singleVideo }) => {
  const location = useLocation();
  const [video, setVideo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dropdown, setDropdown] = useState(true);
  const [activeClass, setActiveClass] = useState(0);
  const [currentVideo, setCurrentVideo] = useState('');
  const [videoContent, setVideoContent] = useState(true);
  const [dropdownIndex, setDropdownIndex] = useState(0);
  const [videoInstructors, setVideoInstructors] = useState(false);
  const [videoResources, setVideoResources] = useState(false);
  const { currentTheme, changeTheme } = useContext(ThemeContext);

  let media = 'https://mediadrive.cloudplexo.com/';

  useEffect(() => {
    const getVideoUser = async () => {
      await getVideo()
        .then(function (response) {
          const responseData = JSON.parse(response.data.body);
          let videoArray = responseData.result.data;
          setVideo(videoArray);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    getVideoUser();
  }, []);

  // get only videos that are associated with the video category
  let unixShellVideos = video.filter((video) =>
    video.category.includes(singleVideo?.videoCategory)
  );

  // if video has sub-categories, use first sorting technique
  let filteredVideos = singleVideo?.doesVideoHaveSub
    ? unixShellVideos.map((video) => {
        return {
          order: video.category.split('/')[1].split('-')[0],
          path: media.concat(video.path),
          name: video.name,
          category: video.category.split('/')[1],
          number: video.number,
        };
      })
    : unixShellVideos.map((video) => {
        return {
          order: video.number,
          path: media.concat(video.path),
          name: video.name,
        };
      });

  let sortedVideos = filteredVideos.sort((a, b) => a.order - b.order);

  // Filtered the videos according to their subcategory name into an object of key value pair with the key as the subcategory name and the value as the an array consisting of objects in that category
  const filteredVideoSection =
    singleVideo?.doesVideoHaveSub &&
    sortedVideos.reduce((initial, obj) => {
      let key = obj.category;
      if (!initial[key]) {
        initial[key] = [];
      }
      initial[key].push(obj);
      return initial;
    }, {});

  const subSectionSort =
    singleVideo?.doesVideoHaveSub &&
    Object.fromEntries(
      Object.entries(filteredVideoSection).map((x) => [
        x[0],
        x[1].sort((a, b) => a.number - b.number),
      ])
    );

  // Array consisting of the subcategory Names
  const videoSectionNames =
    singleVideo?.doesVideoHaveSub &&
    Object.keys(filteredVideoSection).map((section) => section);

  // Here, I'm filtering the video arrays to get the first video by order, then, I'm filtering to get video path of the video. This is so that I can play the first video as the user loads the page.
  let firstVideo = singleVideo?.doesVideoHaveSub
    ? filteredVideos
        .filter((video) => video.order === '1')
        .filter((video) => video.name.startsWith('1-'))
        .map((video) => video.path)
        .toString()
    : sortedVideos
        .filter((video) => video.order === '1')
        .map((video) => video.path)
        .toString();

  // this function handles video player. On click, set video to the current video path and add blue border to it to denote activeClass
  const handlePlayVideo = ({ path }, i) => {
    setCurrentVideo(path);
    setActiveClass(i);
  };

  // course content functions
  const showCourseContent = () => {
    setVideoContent(true);
    setVideoInstructors(false);
    setVideoResources(false);
  };
  const showResources = () => {
    setVideoContent(false);
    setVideoInstructors(false);
    setVideoResources(true);
  };
  const showInstructors = () => {
    setVideoContent(false);
    setVideoInstructors(true);
    setVideoResources(false);
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="loader-overlay">
          <PropagateLoader
            color="#516BEB"
            size={30}
          />
        </div>
      ) : (
        <div className="video-player">
          <div className="videoplayer-container">
            <div className="course-content">
              <div className="course-content-header">
                <h3
                  onClick={showCourseContent}
                  className={
                    videoContent
                      ? 'course-content-header-text-active'
                      : 'course-content-header-text'
                  }
                >
                  Course Content
                </h3>
                <h3
                  className={
                    videoResources
                      ? 'course-content-header-text-active'
                      : 'course-content-header-text'
                  }
                  onClick={showResources}
                >
                  Resources
                </h3>
                <h3
                  className={
                    videoInstructors
                      ? 'course-content-header-text-active'
                      : 'course-content-header-text'
                  }
                  onClick={showInstructors}
                >
                  Instructors
                </h3>
              </div>

              {videoContent ? (
                <div className="video-name-scrollview">
                  {singleVideo?.doesVideoHaveSub ? (
                    <>
                      {videoSectionNames.map((section, index) => (
                        <div
                          key={index}
                          className={
                            dropdown && dropdownIndex === index
                              ? 'dropdown-div-active'
                              : 'dropdown-div'
                          }
                        >
                          {/* Main Section */}
                          <p>
                            {section.replace(/-/g, ' ')}
                            <KeyboardArrowDownIcon
                              id="font"
                              onClick={() => {
                                setDropdownIndex(index);
                                setDropdown(!dropdown);
                              }}
                            />
                          </p>

                          {/* Dropdown */}
                          <div>
                            {dropdown &&
                              dropdownIndex === index &&
                              subSectionSort[section].map((video, i) => (
                                <div
                                  key={video.name}
                                  onClick={() => handlePlayVideo(video, i)}
                                  className={
                                    activeClass === i
                                      ? 'video-name-active'
                                      : 'video-name'
                                  }
                                >
                                  <p>{video.name.replace(/-/g, ' ')}</p>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {singleVideo?.videoCategory ===
                      'Microsoft-Azure-Fundamentals' ? (
                        <>
                          {sortedVideos.map((video, idx) => (
                            <React.Fragment key={idx}>
                              <div
                                onClick={() => handlePlayVideo(video, idx)}
                                className={
                                  activeClass === idx
                                    ? 'video-name-active'
                                    : 'video-name'
                                }
                              >
                                <p>
                                  {idx + 1}
                                  {video.name
                                    .replace(/[^a-zA-Z ]/g, ' ')
                                    .replace('Module', '')}
                                </p>
                              </div>
                              {idx > 0 ? (
                                <Link
                                  to="/exam"
                                  state={{
                                    from: location.pathname + (idx + 1),
                                    moduleName: video.name
                                      .replace(/[^a-zA-Z ]/g, ' ')
                                      .replace('Module', ''),
                                  }}
                                >
                                  <div className="video-name">
                                    <p>Module {idx + 1} Test</p>
                                  </div>
                                </Link>
                              ) : (
                                <div />
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <>
                          {sortedVideos.map((video, i) => (
                            <div
                              key={i}
                              onClick={() => handlePlayVideo(video, i)}
                              className={
                                activeClass === i
                                  ? 'video-name-active'
                                  : 'video-name'
                              }
                            >
                              <p>
                                {i + 1}. {video.name.replace(/[0-9-+]/g, ' ')}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}

                  <Link
                    to="/exam"
                    state={{ from: location.pathname }}
                  >
                    <div className="video-name">
                      <p>Final Exam</p>
                    </div>
                  </Link>
                </div>
              ) : videoResources ? (
                <div className="video-name-scrollview">
                  <div className={'video-name'}>
                    {singleVideo?.videoCategory ===
                    'AWS-Certified-Solutions-Architect-Associate' ? (
                      <>
                        <p>Available Resources</p>

                        <div className="video-name-resource">
                          <p>Supplementary Learning Materials</p>
                          <a
                            href="https://mediadrive.cloudplexo.com/inputs/AWS-Certified-Solutions-Architect-Associate/Supplementary-Learning-Materials.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download
                          </a>
                        </div>

                        <div className="video-name-resource">
                          <p>Quiz-and-Final-Exam</p>

                          <a
                            href="https://mediadrive.cloudplexo.com/inputs/AWS-Certified-Solutions-Architect-Associate/Quiz-and-Final-Exam.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download
                          </a>
                        </div>
                      </>
                    ) : (
                      <p>Resources unavailable</p>
                    )}
                  </div>
                </div>
              ) : videoInstructors ? (
                <div className="video-name-scrollview">
                  {singleVideo?.instructor.length > 0 ? (
                    singleVideo?.instructor.map((instructor, i) => (
                      <div
                        key={i}
                        className="video-name video-name-instructor"
                      >
                        <img
                          src={instructor.image}
                          alt="img"
                        />
                        <div>
                          <h6>{instructor.name}</h6>
                          <p>{instructor.occupation}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={'video-name'}>
                      <p>Instructor's profile unavailable</p>
                    </div>
                  )}
                </div>
              ) : null}
            </div>

            <div className="videoplayer-aside">
              <h5>{singleVideo?.videoName}</h5>
              <ReactPlayer
                url={currentVideo || firstVideo}
                playing={true}
                controls={true}
                width="100%"
                height="100%"
                className="videoplayer-aside-video"
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                      onContextMenu: (e) => e.preventDefault(),
                    },
                  },
                }}
              />

              {/* toggle button that changes the theme of the video player */}
              <label className="theme-toggle">
                <span>{currentTheme ? 'Light Theme' : 'Dark Theme'}</span>
                <Toggle
                  icons={false}
                  checked={currentTheme}
                  onChange={() => {
                    changeTheme(!currentTheme);
                  }}
                />
              </label>
            </div>
          </div>
          <div className="video-course-description">
            <h6>Course Description</h6>
            <hr />

            <p
              dangerouslySetInnerHTML={{
                __html: singleVideo?.videoDescription,
              }}
            ></p>

            {singleVideo?.videoLists.length > 0 && <p>Content</p>}

            <ol>
              {singleVideo?.videoLists.map((module, index) => (
                <li key={module + index}>{module}</li>
              ))}
            </ol>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default VideoPlayer;
